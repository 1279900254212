import React, {useEffect, useState} from 'react';
import locationImg                  from '../../assets/images/personal-security/point icon.svg'
import squareImg                    from '../../assets/images/personal-security/Group 18.svg'
import {ps}                         from '../../utils/personal-security';
import FileImg                      from "../../components/file-img";

function DaNationalStandards({lang}) {
  const [data, setData] = useState(ps.personalSecurity.lang.ru.national)

  useEffect(() => {
    window.scrollTo(0, 0);
    if (lang === 'ru') {
      setData(ps.personalSecurity.lang.ru.national)
    }
    if (lang === 'eng') {
      setData(ps.personalSecurity.lang.eng.national)
    }
    if (lang === 'kaz') {
      setData(ps.personalSecurity.lang.kaz.national)
    }
  }, [lang])
  return (
    <div className={'da-container da-nationality'}>
      <div className="text-body">
        <span className={'side-title left'}>{data.sideTitleLeft}</span>
        <span className={'side-title right'}>{data.sideTitleRight}</span>
        <h1>{data.pageTitle1}</h1>
        <h2>{data.pageTitle2}</h2>
        <div className={'box left-text first-text'}>
          <p>
            <img className={'locationImg'} src={locationImg} alt=""/>
            {data.one}
          </p>
          <FileImg number={11}  link={"https://www.soros.kz/wp-content/uploads/2020/04/Personal_data_report.pdf"}/>
        </div>
        <div className={'box right-text second-text'}>
          <FileImg number={12}  link={"http://adilet.zan.kz/rus/docs/Z1300000094"}/>
          <p>{data.two}</p>
        </div>
        <div className={'box left-text third-text'}>
          <p>{data.three}</p>
          <FileImg number={13} link={"https://www.inform.kz/ru/zaschitu-personal-nyh-dannyh-usilyat-v-kazahstane_a3660626"} />
        </div>
        <div className={'box right-text fourth-text'}>
          <FileImg number={14} link={"https://digital.report/zakonodatelstvo-kazahstana-personalnye-dannye-i-ih-zashchita/"} />
          <p>{data.four}
          </p>
        </div>
        <div className={'box left-text fifth-text'}>
          <img className={'squareImg'} src={squareImg} alt=""/>
          <p>{data.five}</p>
          <FileImg number={15} link={"https://liter.kz/dlya-zashhity-personalnyh-dannyh-grazhd/"} />
        </div>
        <div className={'box right-text sixth-text'}>
          <p>{data.six}
          </p>
        </div>
      </div>
    </div>
  );
}

export default DaNationalStandards;
