import React, {useEffect, useState} from 'react';
import {Redirect, Route, Switch}    from 'react-router-dom';
import DREntrance                   from './da-entrance';
import DRInternationalStandards     from './da-international-standarts';
import DRNationalStandards          from './da-national-standarts';
import Da2020                       from './da-2020';
import DRRecommendations            from './da-recommendations';
import {ps}                         from '../../utils/personal-security';
import GoBack                       from '../../components/goBack';
import FiveNavs                     from '../../components/five-navs';
import TopBanner                    from '../../components/top-banner';
import ThemeButton                  from '../../components/theme-button';

function DigitalRights({lang, navData, toggleTheme}) {
    return (
        <div className={'container'}>
            <GoBack lang={lang}/>
            <TopBanner title={navData.digitalRights}/>
            <Switch>
                <Route exact path={'/digital-rights/'} render={props => <DREntrance lang={lang} {...props} />}/>
                <Route exact path={'/digital-rights/international'}
                       render={props => <DRInternationalStandards lang={lang}{...props} />}/>
                <Route exact path={'/digital-rights/national'}
                       render={props => <DRNationalStandards lang={lang} {...props} />}/>
                <Route exact path={'/digital-rights/2020'} render={props => <Da2020 lang={lang} {...props} />}/>
                <Route exact path={'/digital-rights/recommendations'}
                       render={props => <DRRecommendations lang={lang} {...props} />}/>
                <Redirect to={'/not-found'}/>
            </Switch>
            <FiveNavs lang={lang} path={'digital-rights'}/>
            <ThemeButton toggleTheme={toggleTheme}/>
        </div>
    );
}

export default DigitalRights;
