export const ps = {
  personalSecurity: {
    lang: {
      ru:  {
        entrance:        {
          sideTitleLeft:  "цифровая",
          sideTitleRight: "приватность",

          pageTitle1: "ВВЕДЕНИЕ",

          one:   "С 2006 года 28 января ежегодно отмечается всемирный день приватности или защиты персональных данных . Главной задачей этой образовательной инициативы является продвижение лучших практик защиты приватности и персональных данных, в частности в интернете и социальных сетях, а также в повышении уровня осведомленности о рисках, связанных с незаконными сбором и обработкой их личных данных . Если говорить простыми словами – объяснять людям, какие личные данные собираются, обрабатываются и почему, а главное – какими правами они обладают в отношении этой обработки. Вместе с тем, помимо образовательного компонента речь также идет о разработке технологических решений и инструментов для поощрения индивидуального контроля над персональными данными, соблюдения правил и законов о приватности и конфиденциальности, а также диалога и сотрудничества между государством, бизнесом, академическим и экспертным сообществом, гражданским обществом и другими заинтересованными этими вопросами игроками.",
          two:   "Защита права на неприкосновенность частной жизни (приватности) в целом и данных в частности сильно различается по всему миру. В отчете Freedom House-2018 говорится, что 15 стран рассмотрели законы о защите данных, а в 35 государствах уже приняты такие законы . В частности, отмечается, что законы о защите персональных данных, которые были предложены или приняты в Аргентине, Бразилии и Индонезии, очень напоминают европейский Общий регламент по защите данных ЕС (Регламент ЕС 2016/679) или т.н. GDPR, вступивший в силу в мае 2018 года. Вместе с тем вопросы защиты персональных данных в недемократических государствах, –в частности, в России, Китае и Казахстане, –также остро стоят на повестке дня в условиях масштабной цифровизации всех сфер деятельности.",
          three: "Для понимания ситуации важно определиться с терминологическим аппаратом, который на русском языке зачастую неверно трактуется или переводится с английского, когда речь идет о приватности, конфиденциальности и персональных данных.",
          four:  "Чаще всего в русскоязычном поле идет подмен понятий, когда приватность и конфиденциальность используют в качестве синонимов в повседневной жизни. С юридической точки зрения эти понятия принципиально отличаются. На самом деле конфиденциальность – это личная информация, которой обмениваются с адвокатом, врачом или другими лицами, и которая, как правило, не может быть передана третьим лицам без явного согласия клиента. Приватность\n" +
                   "относится к свободе от вторжения в личные дела и личную информацию (право на неприкосновенность частной жизни). В то время как конфиденциальность является этической обязанностью, приватность является правом, основанным на общем праве. Цифровая приватность на практике означает право на хранение, передачу, предоставление информации кому-либо посредством интернета. Конфиденциальность, по сути, защищает от злоупотреблений полномочиями со стороны власти, даже если при этом человек ничего плохого не делает.",
          five:  "Персональные данные – это информация, относящаяся к идентифицированному или идентифицируемому лицу. Персональные данные включают в себя медицинские записи, расследования и судебные разбирательства по уголовному правосудию, финансовые институты и их транзакции, биологические признаки (генетический материал), геолокацию, веб-серфинг и пользовательские настройки с использованием файлов cookie и т. д.",
          six:   "Идентифицируемое лицо  –  это лицо, которое может быть идентифицировано прямо или косвенно, в частности, посредством ссылки на такой идентификатор. Это может быть имя, идентификационный номер, данные о местоположении, сетевой идентификатор одного или нескольких факторов, характерных для физической, физиологической, генетической, психической, экономической, культурной или социальной идентичности этого физического лица, в том числе IP-адрес или идентификатор cookie, или абсолютно другие факторы . Если возможно идентифицировать человека непосредственно из обрабатываемой информации, то она может являться персональными данными. Персональные данные могут также включать специальные категории персональных данных – данные о судимости и преступлениях. Поскольку они считаются более чувствительными, их обработка возможна  в более ограниченных обстоятельствах.",
          seven: "Если защита персональных данных – это защита от несанкционированного доступа, то конфиденциальность данных – это авторизованный доступ: кто его имеет и кто его определяет. Иными словами, защита данных – это, по сути, техническая задача, в то время как конфиденциальность данных является правовой (юридической). Признается тот факт, что технологии не смогут обеспечить конфиденциальность личных данных, в результате чего разрабатываются правовые, образовательные и прочие инициативы, чтобы минимизировать негативные последствия нарушений стандартов и принципов международного права в области прав человека. Большинство протоколов защиты конфиденциальности по-прежнему уязвимы для уполномоченных лиц, которые могут получить доступ к данным. Бремя этих уполномоченных лиц, прежде всего, связано с законом о конфиденциальности, а не с технологиями.\n",
        },
        international:   {
          sideTitleLeft:  "МЕЖДУНАРОДНЫЕ",
          sideTitleRight: "СТАНДАРТЫ",

          pageTitle1: "МЕЖДУНАРОДНЫЕ",
          pageTitle2: "СТАНДАРТЫ",

          one:   "Ст.12 Всеобщей декларации прав человека гласит: «Никто не может подвергаться произвольному вмешательству в его личную и семейную жизнь, произвольным посягательствам на неприкосновенность его жилища, тайну его корреспонденции или на его честь и репутацию. Каждый человек имеет право на защиту закона от такого вмешательства или таких посягательств» ",
          two:   "В декабре 2013 года Генеральная Ассамблея ООН приняла резолюцию 68/167, в которой выражалась обеспокоенность по поводу негативных последствий и воздействия, которые цифровая «слежка и прослушка» оказывают на права человека. Призывая соблюдать право на цифровую приватность, ООН подтвердила тот факт, что права, которыми люди обладают в реальной жизни, также должны быть защищены и в онлайн-среде. Международное право в области прав человека обеспечивает универсальные рамки, на основе которых должно оцениваться любое вмешательство в права человека на неприкосновенность частной жизни . Генеральная Ассамблея призвала все государства пересмотреть свои процедуры, практику и законодательство, касающиеся наблюдения за сообщениями, перехвата и сбора персональных данных, и подчеркнула необходимость обеспечения государствами полного и эффективного выполнения своих обязательств по международному праву в области прав человека .\n",
          three: "Впоследствии был подготовлен и представлен доклад о праве на неприкосновенность частной жизни в эпоху цифровых технологий, в котором подтверждается необходимость уважать и защищать это право . Резолюция 69/166 ГА ООН  запустила процесс создания специальной процедуры, когда Совет по правам человека в своей резолюции 28/16 объявил о появлении первого Специального докладчика по вопросу о праве на неприкосновенность частной жизни, в том числе в контексте новых цифровых технологий.",
          four:  "Международный пакт о гражданских и политических правах  также предусматривает, что никто не должен подвергаться произвольному или незаконному вмешательству в его частную жизнь, семью, жилище или переписку, а также незаконным посягательствам на его/ее честь и репутацию, и «каждый имеет право на защиту закона от такого вмешательства или нападок».",
          five:  "Европейская конвенция о правах человека  и Хартия основных прав ЕС  содержат аналогичные положения. Хотя право на неприкосновенность частной жизни не является абсолютным, любой случай вмешательства должен подвергаться тщательной и критической оценке его необходимости, законности и соразмерности.",
          six:   "Защита персональных данных в разных частях планеты обретает новые оттенки, поскольку в большей степени связана с управлением и контролем над интернетом, и, как следствие, масштабным ростом авторитаризма. Неограниченный сбор личных данных ограничивает право человека на одиночество, без которого мир, процветание и свобода личности – плоды демократического мира – невозможно сохранить или использовать.",
          seven: "Согласно результатам исследования, проведенного британской компанией Comparitech, все без исключения страны мира очень далеки от обозначенных задач по соблюдению приватности и защиты персональных данных. По разработанным критериям от 1 до 5 баллов, из 47 государств, принявших участие в проекте, только пять получили оценку выше трех баллов. Если одни страны более ответственны и прозрачны, то другие принимают постоянные систематические меры безопасности, в частности цифровой слежки за собственными гражданами.",
          eight:  "Если говорить о защите персональных данных, важно обратить внимание на GDPR (Общий регламент по защите данных), который устанавливает семь ключевых принципов в отношении личной информации:\n" +
                   "●\tЗаконность, справедливость и прозрачность обработки данных;\n" +
                   "●\tОграничение цели сбора и использования данных;\n" +
                   "●\tМинимизация данных (сбор только необходимых данных);\n" +
                   "●\tТочность (неточные данные должны быть удалены или исправлены);\n" +
                   "●\tОграничение хранения (срок хранения данных);\n" +
                   "●\tЧестность и конфиденциальность (защита от несанкционированной или незаконной обработки, уничтожения или повреждения данных);\n" +
                   "●\tПодотчетность (обязанность соблюдать GDPR и способность продемонстрировать соответствие регламенту).\n",
          nine:   "Эти принципы лежат в основе любого подхода к обработке персональных данных в Европе, более того, они обладают экстерриториальным принципом. Это означает, что все организации, собирающие, обрабатывающие и хранящие персональные данные физических лиц, которые сотрудничают с Евросоюзом, призваны защищать цифровые права граждан и должны соответствовать этим требованиям . GDPR требует, чтобы владельцы персональных данных получали более осмысленное согласие, повышали прозрачность того, какие данные собираются и почему, и предоставляли пользователям возможность загружать, передавать или удалять свою информацию.\n",
        },
        national:        {
          sideTitleLeft:  "НАЦИОНАЛЬНЫЕ",
          sideTitleRight: "СТАНДАРТЫ",

          pageTitle1: "НАЦИОНАЛЬНЫЕ",
          pageTitle2: "СТАНДАРТЫ",

          one:   "21 мая 2013 года в Казахстане был принят Закон «О персональных данных и их защите» . Согласно Закону, «персональные данные – сведения, относящиеся к определенному или определяемому на их основании субъекту персональных данных, зафиксированные на электронном, бумажном и (или) ином материальном носителе». Главной целью этого закона является «обеспечение защиты прав и свобод человека и гражданина при сборе и обработке его персональных данных» (ст.2), при этом о хранении речи пока не идет. Кроме того, Закон предоставляет гарантии защиты персональных данных – комплекс мер, в том числе правовых, организационных и технических, в целях реализации прав на неприкосновенность частной жизни, личную и семейную тайну; обеспечения их целостности и сохранности; соблюдения их конфиденциальности; реализации права на доступ к ним и предотвращения незаконного их сбора и обработки. При этом особенности защиты персональных данных в электронной форме для государственных систем определены в Законе «Об информатизации».",
          two:   "25 июня 2020 года Президент РК Касым-Жомарт Токаев подписал Закон «О внесении изменений и дополнений в некоторые законодательные акты Республики Казахстан по вопросам регулирования цифровых технологий», который вносит изменения и дополнения в 7 кодексов и 28 законов . Главным нововведением стало создание уполномоченного органа в сфере защиты персональных данных (по аналогии с Data Protection Agency) в лице Комитета информационной безопасности (КИБ) Министерства цифрового развития, инноваций и аэрокосмической промышленности РК (КИБ получил статус Агентства по защите персональных данных).",
          three: "За нарушение законодательства о персональных данных и их защите предусмотрена ответственность в рамках административного и уголовного кодексов РК (ст.147 и 211 УК РК «Нарушение неприкосновенности частной жизни и законодательства Республики Казахстан о персональных данных и их защите» и «Неправомерное распространение электронных информационных ресурсов ограниченного доступа», ст. 205 «Неправомерный доступ к информации, в информационную систему или сеть телекоммуникаций», ст. 208 «Неправомерное завладение информацией», ст.79, ст. 641 КОАП «Нарушение законодательства Республики Казахстан о персональных данных и их защите» и «Нарушение законодательства Республики Казахстан о персональных данных и их защите») с наказанием от штрафов до лишения свободы.",
          four:  "Учреждение уполномоченного органа в сфере защиты персональных данных стало первым шагом на пути ожидаемого движения в сторону соответствия требованиям GDPR. Министерство цифрового развития, инноваций и аэрокосмической промышленности РК отмечает, что система защиты персональных данных в Казахстане будет дополнена в соответствии с европейским GDPR, хотя в то же время система сочетает в себе элементы российской и китайской концепций – «суверенный интернет», «великий файрволл», цензура и пр. Важно обратить внимание на то, что функции Агентства по защите персональных данных в основном зациклены на технологических решениях и соблюдении законодательства в этой сфере. Введение сервиса по защите персональных данных, который намерен уведомлять граждан о действиях с его персональными данными, не в полной мере отражает весь комплекс мер, который необходим для обеспечения всех принципов работы с персональными данными.",
          five:  "В частности, речь идет о том, как и кому профильный комитет будет предоставлять разъяснения по вопросам защиты данных, и насколько открытым будет этот орган. Мировая практика показывает, что уполномоченный орган должен быть независимым и самостоятельным, чтобы максимально прозрачно гарантировать соблюдение цифровых прав и защиту персональных данных. Более того, в текущих условиях, когда государственные органы пытаются априори обеспечить собственную безопасность, чтобы быть менее уязвимыми перед лицом новых угроз, очень часто они забывают о своих гражданах, которых критически важно обучать и повышать их осведомленность в этих вопросах. Как показывает исследование, граждане используют VPN-сервисы, чтобы обеспечить конфиденциальность своих данных, причем не ради защиты персональных данных от кибермошенников, а чтобы защититься от государственной слежки и прослушки.",
          six:   "Логично ожидать, что деятельность Агентства позволит уведомлять граждан о действиях с их данными. Однако это кажется маловероятным в нынешних условиях, когда доверие между государственными органами и гражданами отсутствует. Более того, принимая во внимание масштабную цифровизацию посредством внедрения видеомониторинга, систем распознавания лиц, биометрики с одной стороны, а также крупнейшие утечки данных в 2019 году из баз данных ЦИК и Генеральной прокуратуры – с другой, крайне важно понимать, какие гарантии защиты персональных данных может дать государство, и как оно собирается соблюдать взятые на себя международные обязательства по соблюдению прав и свобод человека в онлайн- и офлайн-среде.\n",
        },
        ps2020:          {
          pageTitle1: "СИТУАЦИЯ ЗА 2020 ГОД",
          pageTitle2: "В КАЗАХСТАНЕ",

          one:   "На данном этапе правовой статистики по защите персональных данных в РК крайне мало, а все случаи с утечкой данных заканчивались либо увольнением сотрудника, либо закрытием дела за отсутствием состава преступления. В этой ситуации правовой механизм необходимо развивать открыто и прозрачно. Важно развивать культуру защиты персональных данных, в том числе путем сообщения об утечках информации субъектам персональных данных . Это позволит выстроить доверительные отношения и воспринимать всерьез ответственность по сбору, хранению, обработке и удалению данных, чтобы минимизировать возможность утечки данных и, как следствие, защитить приватность их владельцев. Особую актуальность в этой связи обретают специально запущенные мобильные приложения SMARTAstana и Covid19live.kz, а также использование имеющихся возможностей для цифровой «слежки» посредством камер «Сергек», приложения Ashyq для борьбы с COVID-19 и контроля передвижения.",
          two:   "Когда речь идет о защите персональных данных, неясно, кто имеет доступ и контролирует безопасность сбора, обработки и хранения личной информации казахстанцев. Остается непонятным, как и будут ли государственные органы, особенно службы безопасности, уважать права граждан на защиту личных данных. В контексте внедрения Национальной системы видеонаблюдения вопрос заключается в том, как правительство будет балансировать между правом на неприкосновенность частной жизни и вмешательством в нее для поддержания общественного порядка и обеспечения национальной безопасности.",
          three: "Созданное профильное агентство (хоть и не независимое, как того хотелось в рекомендациях 2019-2020 годов ) активно включилось в работу по соблюдению законодательства в сфере защиты персональных данных. Так, за вторую половину 2020 года Министерством рассмотрено 40 жалоб по нарушению законодательства о персональных данных и их защите .\n",
          four:  "Чаще всего жалобы касались сбора данных без согласия, бездействия операторов при отзыве согласия на сбор и обработку персональных данных, использования интернет-ресурсами данных, опубликованных в общедоступных ресурсах государственных органов:\n" +
                   "•\tза ненадлежащее осуществление мер по защите персональных данных, содержащихся на электронных информационных ресурсах (пп. 1 ч. 1 ст. 641 КоАП РК), к административной ответственности привлечены два оператора связи, 1 страховая компания, 1 должностное лицо;\n" +
                   "•\tза незаконный сбор и обработку персональных данных (ч. 1 ст. 79 КоАП РК) к административной ответственности привлечены 1 физическое лицо (владелец интернет-ресурса) и 1 туристическая компания. ",
          five:  "Согласно информации, полученной от Министерства цифрового развития, инноваций и аэрокосмической промышленности, за этот же период следующие нарушения были зафиксированы: \n" +
                   "•\tбездействие операторов баз, содержащих персональные данные, при отзыве согласия физических лиц на сбор и обработку персональных данных;\n" +
                   "•\tсбор и обработка избыточных персональных данных;\n" +
                   "•\tотсутствие перечня персональных данных, необходимых и достаточных для выполнения осуществляемых оператором задач, а также лицо, ответственное за организацию обработки персональных данных;\n" +
                   "•\tнесоблюдение требования по своевременному обнаружению фактов несанкционированного доступа к персональным данным;\n" +
                   "•\tотсутствие средств криптографической защиты информации с параметрами, соответствующими требованиям СТ РК1073-2007 , при хранении и передаче персональных данных.",
          six:   "Логично ожидать, что в 2021 году национальное агентство по защите персональных данных продолжит свою работу по продвижению культуры защиты персональных данных среди государственных органов, представителей бизнеса и гражданского общества, в частности речь идет о соблюдении принципов открытости и гласности, получении согласия на удаление персональных данных, обезличивание данных и сроки их хранения.",
          seven: "Сейчас существуют некоторые проблемы с тем, что персональные данные (ФИО, ИИН, данные удостоверения личности, штрафы, административные дела, судебные решения и пр.) доступны в различных поисковых системах. При этом, согласно ответу Министерства цифрового развития, инноваций и аэрокосмической промышленности, «за публикацию вышеуказанных сведений и персональных данных не представляется возможным привлечь к административной ответственности…можно только заниматься поступающими жалобами».",
          eight:  "Наконец, невозможно обеспечить информационную и кибербезопасность, если львиная доля граждан не знает о важности защиты персональных данных и кибергигиене. Для создания устойчивой экосистемы правительству и гражданскому обществу крайне важно уделять внимание повышению уровня осведомленности и знаний граждан в сфере цифровых прав и защиты персональных данных.\n",

          smallDesc1: "Жалобы по нарушению законодательства о персональных данных в Казахстане за июнь-декабрь 2020",
          table:      {
            firstPart:  {
              left:  "регион",
              right: "количество жалоб",
            },
            secondPart: {
              title: "юридические лица",
              list:  [
                {
                  left:  "нур-султан",
                  right: "4",
                },
                {
                  left:  "алматы",
                  right: "1",
                },
                {
                  left:  "актобе",
                  right: "1",
                },
                {
                  left:  "караганда",
                  right: "1",
                },
              ],
            },
            thirdPart:  {
              title: "физические лица",
              list:  [
                {
                  left:  "нур-султан",
                  right: "9",
                },
                {
                  left:  "алматы",
                  right: "12",
                },
                {
                  left:  "караганда",
                  right: "2",
                },
                {
                  left:  "шымкент",
                  right: "1",
                },
                {
                  left:  "актобе",
                  right: "1",
                },
                {
                  left:  "актау",
                  right: "1",
                },
                {
                  left:  "акмолинская область",
                  right: "3",
                },
                {
                  left:  "североказахстанская область",
                  right: "2",
                },
                {
                  left:  "восточноказахстанская область",
                  right: "1",
                },
                {
                  left:  "атырауская область",
                  right: "1",
                },
              ],
            },
          },
        },
        recommendations: {
          sideTitleLeft:    "рекомендации",

          pageTitle1:       "рекомендации",

          one:              "В условиях COVID-19 разработка собственного регламента по защите персональных данных обретает в Казахстане еще большую актуальность и важность. Безусловно, пандемия внесла свои корректировки в реализацию намеченных планов по массовой цифровизации страны и продвижению идей по защите персональных данных. Создание уполномоченного органа стало первым шагом на пути к тому, чтобы мониторить ситуацию и положение дел, а также способствовать тому, чтобы законодательство в сфере защиты персональных данных соблюдалось всеми неукоснительно – как чиновниками, так и активными гражданами – в каждом конкретном случае без исключения.",
          two:              "Ст. 20 Закона «О персональных данных и их защите» говорит о том, что персональные данные подлежат защите, и ее гарантом выступает государство. Создание уполномоченного органа в сфере защиты персональных данных – первый шаг на этом пути. Однако наиболее важные аспекты, касающиеся конкретных функций, полномочий и процедур Агентства в целом и его работников в частности, остаются неурегулированными. В этой связи важно разработать и четко прописать все эти аспекты и передать функцию защиты персональных данных от Генеральной прокуратуры Агентству.",
          three:            "Несмотря на наличие специального органа, который будет заниматься вопросами защиты персональных данных, все государственные органы без исключения должны соответствовать выработанным техническим, этическим и юридическим параметрам по сбору, обработке, хранению и уничтожению данных. Важно, чтобы закон о персональных данных заработал. Однако карательная практика не позволит решить системные проблемы, а только будет откладывать решение наиболее важных проблем – в т. ч. обучение кадров, создание правовой и цифровой культуры во всем государственном аппарате – на более поздний срок. Впрочем, увольнение также не может оставаться способом наказания за нарушение законодательства, потому что оно определяет четкие механизмы привлечения к ответственности.",
          four:             "В условиях невозможности создания независимого и самостоятельного органа по защите\n" +
                              "персональных данных представляется логичным в краткосрочной перспективе запустить\n" +
                              "механизм общественного контроля, состоящего из представителей гражданского общества,\n" +
                              "бизнес-сообщества, юристов, исследователей и правозащитников. Подобный элемент\n" +
                              "позволит адекватно реагировать на нововведения в сфере защиты персональных данных,\n" +
                              "участвовать в их разработке и внедрении, а также заниматься мониторингом соблюдения\n" +
                              "прав, фиксацией нарушений, продвижением культуры защиты персональных данных и\n" +
                              " контролем того, чтобы объемы собираемых данных были пропорциональны преследуемой\n" +
                              " цели.",
          five:             "Учитывая масштабную цифровизацию в ходе реализации национальной программы «Цифровой Казахстан», деятельность уполномоченного органа по защите персональных данных и обязательства должна соответствовать международным стандартам в области прав человека. Необходимо разработать конкретные требования к сбору и обработке данных, прописать сроки хранения и уничтожения личной информации, в том числе для внедрения видеонаблюдения и использования искусственного интеллекта, с целью предотвращения и снижения рисков нарушения прав человека. Этический компонент защиты персональных данных должен стать основой цифровой культуры и цифрового общества по умолчанию.",
          six:              "Инициативы по внедрению технологии искусственного интеллекта, системы распознавания лиц и других технологических решений должны учитывать риски, связанные с предоставлением, сбором, анализом и хранением персональных данных. Прозрачность таких процессов позволит заметно минимизировать потенциальные уязвимости, адекватно реагировать на кризисные ситуации и усиливать киберустойчивость казахстанской системы.",
          seven:            "Профильному агентству необходимо разработать регламент по инцидентам утечки данных и неавторизованном доступе к персональным данным. Об этих случаях должны сообщать организации, в которых подобные кризисные ситуации происходят, а не кто-то другой. Это нормальный стандартный протокол, набор инструментов и практик не только присутствует в европейском GDPR (Общий регламент по защите персональных данных), но и во всех компаниях в мире. Внедрение прозрачных протоколов управления рисками позволит не только усилить компонент по защите систем и персональных данных, но и сократить имеющиеся серые зоны, которые могут использоваться в коррупционных целях и ради шпионажа. Эти практики должны массово внедряться во все государственные органы, работающие с базами данных, не говоря о частном бизнесе.",
          eight:             "Наряду с технологическими и юридическими мерами защитить персональные данные невозможно без образовательного компонента. Фокус на цифровые права и защиту персональных данных позволит гораздо быстрее развивать цифровую культуру, а кибергигиена должна стать неотъемлемой частью повседневной жизни каждого.\n",
        },
      },
      eng: {
        entrance:        {
          sideTitleLeft:  "digital ",
          sideTitleRight: "privacy",

          pageTitle1: "Introduction",

          one:   "Every year since 2006, January 28 is celebrated as World Privacy or Data Protection Day. The main objective of this educational initiative is to promote best practices in the protection of privacy and personal data, in particular on the Internet and social networks, as well as to raise awareness of the risks associated with the illegal collection and processing of their personal data. In simple terms - to explain to people what personal data is collected, processed and why, and most importantly - what rights they have with respect to this processing. At the same time, in addition to the educational component, it is also about developing technological solutions and tools to encourage individual control over personal data, compliance with privacy and confidentiality rules and laws, as well as dialogue and cooperation between the state, business, academic and expert community, civil society and other players interested in these issues",
          two:   "Protection of the right to privacy in general and data in particular varies widely around the world. The 2018 Freedom House report states that 15 countries have considered data protection laws, and 35 states have already adopted such laws. In particular, it notes that laws on personal data protection that have been proposed or passed in Argentina, Brazil and Indonesia are very similar to the European General Data Protection Regulation of the EU (EU Regulation 2016/679) or the so-called GDPR, which came into force in May 2018. At the same time, issues of personal data protection in non-democratic countries, such as Russia, China and Kazakhstan, are also on the agenda amid the massive digitalization of all spheres of activity",
          three: "To understand the situation it is important to define the terminology, which in Russian is often misinterpreted or translated from English when it comes to privacy, confidentiality and personal data. ",
          four:  "The most common substitution of concepts in the Russian-speaking field is when privacy and confidentiality are used as synonyms in everyday life. From the legal point of view, these concepts are fundamentally different. In fact, confidentiality is personal information that is exchanged with a lawyer, doctor, or other person and generally cannot be shared with third parties without the client's explicit consent. Privacy refers to freedom from intrusion into personal affairs and personal information (the right to privacy). While privacy is an ethical obligation, privacy is a right based on common law. Digital privacy in practice means the right to store, transmit, and share information with anyone via the Internet. Privacy essentially protects against the abuse of power by the authorities, even if one does nothing wrong in doing so.",
          five:  "Personal data – is information related to an identified or identifiable individual. Personal information includes medical records, criminal justice investigations and proceedings, financial institutions and their transactions, biological traits (genetic material), geolocation, web surfing and user settings using cookies, etc.",
          six:   "Identifiable Person – is a person who can be identified directly or indirectly, in particular by reference to such identifier. This may be a name, identification number, location data, network identifier of one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that individual, including an IP address or cookie identifier, or absolutely other factors . If it is possible to identify an individual directly from the information processed, it may constitute personal data. Personal data may also include special categories of personal data - criminal and criminal records. Since these are considered more sensitive, they can only be processed in more limited circumstances.",
          seven: "If the protection of personal data is the protection against unauthorized access, then data privacy is authorized access: who has it and who defines it. In other words, data protection is essentially a technical task, while data privacy is a legal one. It is recognized that technology will not be able to ensure the privacy of personal data, and as a result, legal, educational and other initiatives are being developed to minimize the negative consequences of violations of international human rights standards and principles. Most privacy protection protocols are still vulnerable to authorized persons who can access the data. The burdens of these authorized persons are primarily related to privacy law, not technology. ",
        },
        international:   {
          sideTitleLeft:  "international",
          sideTitleRight: "standards",

          pageTitle1: "International",
          pageTitle2: "Standards",

          one:   "Article 12 of the Universal Declaration of Human Rights states: \"No one shall be subjected to arbitrary interference with their privacy, family, home, correspondence, or honor and reputation. Everyone has the right to the protection of the law against such interference or intrusion\" .",
          two:   "In December 2013, the UN General Assembly adopted Resolution 68/167, expressing concern about the negative effects and impact that digital surveillance and eavesdropping have on human rights. In calling for respect for the right to digital privacy, the UN reaffirmed that the rights that people have in real life must also be protected in the online environment. International human rights law provides a universal framework against which any interference with human privacy rights must be judged . The General Assembly called upon all States to review their procedures, practices and legislation regarding the surveillance of communications and the interception and collection of personal data, and stressed the need for States to ensure full and effective implementation of their obligations under international human rights law .",
          three: "Subsequently, a report on the right to privacy in the digital age was drafted and presented, affirming the need to respect and protect this right . UNGA Resolution 69/166  set in motion the process of creating a special procedure when the Human Rights Council, in its Resolution 28/16, announced the first Special Rapporteur on the right to privacy, including in the context of new digital technologies .",
          four:  "The International Covenant on Civil and Political Rights  also provides that no one shall be subjected to arbitrary or unlawful interference with his privacy, family, home or correspondence, nor to unlawful attacks on his honor and reputation, and \"everyone has the right to the protection of the law against such interference or attacks\".",
          five:  "The European Convention on Human Rights  and the EU Charter of Fundamental Rights  contain similar provisions. Although the right to privacy is not absolute, any case of interference must be subjected to a careful and critical assessment of its necessity, legality and proportionality.",
          six:   "The protection of personal data in different parts of the world is taking on new shades as it relates more to the governance and control of the Internet, and the resulting large-scale rise of authoritarianism. Unrestricted collection of personal data limits an individual's right to solitude, without which peace, prosperity, and individual liberty – the fruits of democracy – can not be maintained or exploited.",
          seven: "According to a study of the British company Comparitech, without exception, all countries in the world are very far from the designated objectives on privacy compliance on an ongoing basis, and as a consequence, the protection of personal data. According to the criteria developed from 1 to 5 (points), out of 47 states that participated in the project, only 5 received a score higher than 3. While some countries are more responsible and transparent, others take ongoing systematic security measures, such as digital surveillance of their own citizens .",
          eight:  "If we talk about personal data protection, it is important to pay attention to the European standard in the field of personal data protection - GDPR (General Data Protection Regulation), which establishes seven key principles in relation to personal information:\n" +
                   "•\tLegitimacy, fairness and transparency of data processing;\n" +
                   "•\tLimiting the purpose of data collection and use;\n" +
                   "•\tData minimization (collecting only necessary data);\n" +
                   "•\tAccuracy (inaccurate data must be removed or corrected);\n" +
                   "•\tStorage limitation (data retention period);\n" +
                   "•\tIntegrity and confidentiality (protection against unauthorized or unlawful processing, destruction or damage to data);\n" +
                   "•\tAccountability (obligation to comply with GDPR and ability to demonstrate compliance with regulations).\n",
          ten:   "These principles underpin any approach to personal data processing in Europe, moreover, they have an extraterritorial principle. This means that all organizations that collect, process and store personal data of individuals that cooperate with the European Union are required to protect citizens' digital rights and must comply with these requirements. The GDPR requires personal data owners to obtain more meaningful consent, increase transparency about what data is collected and why, and give users the ability to upload, transfer or delete their information. ",
        },
        national:        {
          sideTitleLeft:    "National",
          sideTitleRight:   "standards",
          sideTitleMidLeft: "",

          pageTitle1:       "National",
          pageTitle2:       "standards",

          one:              "On May 21, 2013, Kazakhstan adopted the Law \"On Personal Data and its Protection\" . According to this Law, «personal data - information related to a certain person or subject of personal data defined by this data, recorded on digital, paper and (or) other tangible media\". The main purpose of this Law is to «ensure the protection of human and civil rights and freedoms during the collection and processing of personal data\" (Art. 2), at this point storage is not mentioned yet. In addition, the law provides safeguards for the protection of personal data - a set of measures, including legal, organizational and technical, in order to implement the rights to privacy, personal and family secrets, ensuring their integrity and security, confidentiality, the right of access to them and preventing their illegal collection and processing. At the same time, the specifics of the protection of personal data in electronic form for government systems are defined in the Law \"On Informatization.",
          two:              "On June 25, 2020, President Kassym-Jomart Tokayev signed the Law \"On Amendments and Additions to Some Legislative Acts of the Republic of Kazakhstan on the Regulation of Digital Technology\",  which amends and supplements 7 codes and 28 laws . The main innovation was the creation of an authorized body in the field of personal data protection (similar to the Data Protection Agency), represented by the Information Security Committee (ISC) of the Ministry of Digital Development, Innovation and Aerospace Industry of Kazakhstan (ISC received the status of the Agency for Personal Data Protection) .",
          three:            "There is responsibility under the administrative and criminal codes of the Republic of Kazakhstan for violation of legislation on personal data and its protection (articles 147 and 211 of the Criminal Code «Violation of privacy and the legislation of the Republic of Kazakhstan on personal data and its protection\" and «Illegal distribution of confidential electronic information resources\", art. 205 «Unauthorized access to information, information system or telecommunications network\", art. 208 «Unauthorized acquisition of information\", art. 79 and 641 of the Administrative Code \"Violation of the Legislation of the Republic of Kazakhstan on Personal Data and its Protection\" and \"Violation of the Legislation of the Republic of Kazakhstan on Informatization\") with penalties ranging from fines to imprisonment . ",
          four:             "Establishment of an authorized body in the field of personal data protection was the first step towards the expected movement of Kazakhstan towards GDPR compliance. The Ministry of Digital Development, Innovation and Aerospace Industry of Kazakhstan (MDDIAI) notes that the personal data protection system in Kazakhstan will be supplemented in accordance with the European GDPR. Although at the same time the system combines elements of Russian and Chinese concepts – \"sovereign Internet\", \"great firewall\", censorship and so on. It is important to note that the functions of the Agency for Personal Data Protection are mainly focused on technological solutions and compliance with legislation in this area. The introduction of a personal data protection service, which intends to notify citizens about the actions with their personal data, does not fully reflect the full range of measures that are needed to ensure all the principles of personal data processing .",
          five:             "In particular, it is about how and to whom the relevant committee will provide explanations on data protection issues, and how transparent this body will be. Global practice shows that the authorized body must be independent and autonomous in order to guarantee digital rights and personal data protection as transparently as possible. Moreover, in the current environment, where government agencies put a priority on ensuring their own security in order to be less vulnerable to new threats, they very often forget about their citizens, whom it is critical to educate and raise their awareness of these issues. Studies show that citizens use VPN-services to ensure the privacy of their data, not to protect personal data from cyber-fraudsters, but to protect themselves from government surveillance and wiretapping .",
          six:              "It is logical to expect that the activities of the Agency would allow citizens to be notified of activities involving their data. However, this seems unlikely in the current environment, where there is a lack of trust between government agencies and citizens. Moreover, given the large-scale digitization through the introduction of video monitoring, facial recognition systems, biometrics on the one hand, and the largest data leaks in 2019 from the databases of the CEC and Prosecutor General's Office on the other, it is crucial to understand what guarantees of personal data protection the state can provide, and how it is going to comply with its international obligations to respect human rights and freedoms in the online and offline environments.",
        },
        ps2020:          {
          pageTitle1:       "Situation in Kazakhstan",
          pageTitle2:       "as of 2020",

          one:              "At this stage, there are very few legal statistics on personal data protection in Kazakhstan, and all cases involving data leaks ended either with the dismissal of the employee or with the closing of the case for lack of corpus delicti. In this situation, the legal mechanism should be developed openly and transparently. It is important to develop a culture of personal data protection, including the notification of the subjects of personal data leaks. This will build trust and allow to seriously take the responsibility for the collection, storage, processing and deletion of data in order to minimize the possibility of data leakage and, consequently, to protect the privacy of data owners. Of particular relevance in this regard are the specially launched mobile applications SmartAstana and Covid19live.kz, as well as the use of existing features for digital \"surveillance\" through \"Sergek'' cameras, Ashyq application to combat COVID-19 and movement control.",
          two:              "In Kazakhstan, it is unclear who has access and controls over the security of collecting, processing and storing personal information of citizens, when it comes to the protection of personal data. It remains unclear how and whether government agencies, especially the security services, will respect the rights of citizens for the protection of personal data. In the context of implementing National CCTV, the question is how the government will balance the right to privacy with interference to maintain public order and national security .",
          three:            "The established profile agency (though not independent, as the 2019-2020 recommendations wanted it to be ) has been actively involved in the work of complying with legislation on the protection of personal data. Thus, in the second half of 2020, the Ministry reviewed 40 complaints about violations of legislation on personal data and its protection .",
          four:             "The most frequent complaints concerned the collection of data without consent, the inaction of operators in withdrawing consent for the collection and processing of personal data, the use by Internet resources of data published in the publicly available resources of government agencies:\n" +
                              "•\tfor improper implementation of measures to protect personal data contained in electronic information resources (paragraphs (1) of part 1 of Article 641 of the Code of Administrative Offences of the Republic of Kazakhstan. 1 of Article 641 of the Code of Administrative Offences of the Republic of Kazakhstan), two telecommunications operators, one insurance company and one official were brought to administrative responsibility;\n" +
                              "•\t1 individual (owner of an Internet resource) and 1 travel agency were brought to administrative responsibility for illegal collection and processing of personal data (part 1 of Article 79 of the Administrative Code of RK).\n",
          five:             "According to information from the Ministry of Digital Development, Innovation and Aerospace Industry, the following violations were recorded during the same period: \n" +
                              "•\tinaction of operators of databases containing personal data in revoking the consent of individuals to the collection and processing of personal data;\n" +
                              "•\tcollection and processing of excessive personal data;\n" +
                              "•\tlack of a list of personal data necessary and sufficient to perform the tasks performed by the operator, as well as the person responsible for the organization of processing of personal data;\n" +
                              "•\tnon-compliance with the requirement to timely detect the facts of unauthorized access to personal data;\n" +
                              "•\tack of cryptographic protection of information with parameters that meet the requirements of ST RK 1073-2007 , when storing and transferring personal data.\n",
          six:              "We would like to expect that in 2021 the national agency for personal data protection will continue its work to promote a culture of personal data protection among government agencies, business representatives and civil society, in particular it is about respecting the principles of openness and transparency, obtaining consent to delete personal data, depersonalization of data and storage periods.",
          seven:            "Now there are some problems with the fact that personal data (name, IIN, identity card data, fines, administrative cases, court decisions, etc.) are available in various search engines. At the same time, according to the response of the Ministry of Digital Development, Innovation and Aerospace Industry, \"it is not possible to impose administrative liability for the publication of the above information and personal data... it is possible only to deal with incoming complaints\" . ",
          eight:             "Finally, it is impossible to ensure information and cybersecurity if the majority of citizens are unaware of the importance of personal data protection and cyber hygiene. In order to create a sustainable ecosystem, it is crucial for government and civil society to pay attention to raising citizens' awareness and knowledge of digital rights and personal data protection.",
          smallDesc1: "Complaints on violation of personal data legislation in Kazakhstan for June-December 2020",
          table:      {
            firstPart:  {
              left:  "Region",
              right: "Number of complaints",
            },
            secondPart: {
              title: "Legal entities",
              list:  [
                {
                  left:  "Nur-Sultan",
                  right: "4",
                },
                {
                  left:  "Almaty",
                  right: "1",
                },
                {
                  left:  "Aktobe",
                  right: "1",
                },
                {
                  left:  "Karaganda",
                  right: "1",
                },
              ],
            },
            thirdPart:  {
              title: "Individuals",
              list:  [
                {
                  left:  "Nur-Sultan",
                  right: "9",
                },
                {
                  left:  "Almaty",
                  right: "12",
                },
                {
                  left:  "Karaganda",
                  right: "2",
                },
                {
                  left:  "Shymkent",
                  right: "1",
                },
                {
                  left:  "Aktobe",
                  right: "1",
                },
                {
                  left:  "Aktau",
                  right: "1",
                },
                {
                  left:  "Akmola region",
                  right: "3",
                },
                {
                  left:  "North Kazakhstan region",
                  right: "2",
                },
                {
                  left:  "East Kazakhstan region",
                  right: "1",
                },
                {
                  left:  "Atyrau region",
                  right: "1",
                },
              ],
            },
          },
        },
        recommendations: {
          sideTitleLeft:    "Recommendations",

          pageTitle1:       "Recommendations",

          one:              "In the context of COVID-19, the development of its own regulations on the protection of personal data is becoming even more relevant and important in Kazakhstan. Undoubtedly, the pandemic has made its own adjustments to the implementation of planned plans for the mass digitization of the country and the promotion of ideas on the protection of personal data. The creation of the authorized body was the first step in monitoring the situation and the state of affairs, as well as to ensure that the legislation on personal data protection is strictly observed by everyone - both officials and active citizens - in every single case without exception. ",
          two:              "Article 20 of the Law \"On Personal Data and Their Protection\" says that personal data must be protected and it’s protection is guaranteed by the state. The creation of an authorized body in the field of personal data protection is the first step in this direction. However, the most important aspects relating to specific functions, powers and procedures of the Agency in general and its employees in particular remain unresolved. In this regard, it is important to develop and clearly spell out all these aspects and transfer the function of personal data protection from the Prosecutor General's Office to the Agency.",
          three:            "Despite the existence of a special body, which will deal with the protection of personal data, all public authorities, without exception, must comply with the developed technical, ethical and legal parameters for the collection, processing, storage and destruction of data. At the same time, it is important for the law of personal data to work. However, punitive practices will not solve systemic problems, but will only postpone solving the most important problems - including personnel training, creating a legal and digital culture throughout the state apparatus - to a later period. However, dismissal also cannot remain a method of punishment for violation of the law, because it defines clear mechanisms of accountability.",
          four:             "Given the impossibility to create an independent and autonomous authority for personal data protection, it seems logical in the short term to launch a mechanism of public control, consisting of representatives of civil society, the business community, lawyers, researchers and human rights defenders. Such an element will allow to respond adequately to innovations in the field of personal data protection, to participate in their development and implementation, as well as to monitor compliance, to record violations, to promote a culture of personal data protection and to control that the amount of data collected is proportional to the purpose pursued.",
          five:             "Given the large-scale digitization in the course of implementation of the national program \"Digital Kazakhstan\", the activities of the authorized body on the protection of personal data and obligations must comply with international standards in the field of human rights. It is necessary to develop specific requirements for the collection and processing of data, to prescribe the terms of storage and destruction of personal information, including implementation of video surveillance and use of artificial intelligence, in order to prevent and reduce the risks of human rights violations. The ethical component of personal data protection should be the foundation of the digital culture and digital society by default.",
          six:              "Initiatives to introduce artificial intelligence technology, facial recognition and other technological solutions must consider the risks associated with the provision, collection, analysis and storage of personal data. Transparency of such processes would allow a noticeable minimization of potential vulnerabilities, an adequate response to crisis situations, and increase the cyber resilience of the Kazakhstani system.",
          seven:            "The relevant agency needs to develop regulations on incidents of data leakage and unauthorized access to personal data. These cases should be reported by the organizations in which such crises occur, not by someone else. This is a normal standard protocol, a set of tools and practices not only present in the European GDPR (General Data Protection Regulation), but also in all companies in the world. The introduction of transparent risk management protocols will not only strengthen the systems and personal data protection component, but also reduce existing gray areas that can be used for corruption and espionage purposes. These practices should be implemented on a massive scale in all government agencies working with databases, not to mention private businesses.",
          eight:             "Along with technological and legal measures, it is impossible to protect personal data without an educational component. A focus on digital rights and personal data protection will allow a much faster development of a digital culture, and cyber hygiene should become an integral part of everyone's daily life.",
        },
      },
      kaz: {
        entrance:        {
          sideTitleLeft:  "Цифрлы",
          sideTitleRight: "құпиялылық",

          pageTitle1: "Кіріспе",

          one:   "2006 жылдан бастап 28 қаңтарда жыл сайын дүниежүзілік құпиялылық немесе дербес деректерді қорғау күні атап өтіледі . Бұл білім беру бастамасының басты міндеті құпиялылық пен дербес деректерді қорғаудың, атап айтқанда интернетте және әлеуметтік желілерде ең үздік практикаларды ілгерілету, сондай-ақ олардың жеке деректерін заңсыз жинау мен өңдеуге байланысты тәуекелдер туралы хабардар болу деңгейін арттыру болып табылады . Қарапайым сөзбен айтқанда – адамдарға қандай жеке деректер жиналатынын, өңделетінін және не үшін екенін, ең бастысы-осы өңдеуге қатысты олардың қандай құқықтары бар екенін түсіндіру керек. Сонымен бірге, білім беру компонентінен басқа, біз жеке деректерді жеке бақылауды, жекешелеп пен құпиялылық туралы ережелер мен заңдарды сақтауды, мемлекет, бизнес, академиялық және сараптамалық қоғамдастық, азаматтық қоғам және басқа да мүдделі ойыншылар арасындағы диалог пен ынтымақтастықты ынталандыратын технологиялық шешімдер мен құралдарды әзірлеу туралы айтып отырмыз.",
          two:   "Жеке өмірге (жекешілдік) қол сұғылмаушылық құқығын және атап айтқанда деректерді қорғау бүкіл әлемде айтарлықтай ерекшеленеді. Freedom House-2018 есебінде 15 ел деректерді қорғау туралы заңдарды қарады, ал 35 мемлекет осындай заңдарды әлде қайда қабылдап қойған . Атап айтқанда, Аргентина, Бразилия және Индонезияда ұсынылған немесе қабылданған жеке деректерді қорғау туралы заңдар ЕО деректерін қорғаудың еуропалық жалпы регламентін (ЕО 2016/679 регламенті) немесе 2018 жылдың мамыр айында күшіне енген GDPR д.а еске түсіреді. Сонымен қатар, демократиялық емес мемлекеттерде, атап айтқанда Ресейде, Қытайда және Қазақстанда дербес деректерді қорғау мәселелері қызметтің барлық салаларын ауқымды цифрландыру жағдайында күн тәртібінде аса маңызды орында тұр.",
          three: "Жағдайды түсіну үшін құпиялылық және жеке мәліметтер туралы сөз болғанда орыс тілінде жиі дұрыс түсіндірілмейтін немесе ағылшын тілінен аударылатын терминологиялық аппаратты анықтау өте маңызды.",
          four:  "Көбінесе орыс тілдіндегі жолда құпиялылық күнделікті өмірде синоним ретінде қолданылған кезде ұғымдар алмастырылады. Заңды тұрғыдан алғанда, бұл ұғымдар түбегейлі ерекшеленеді. Шын мәнінде, құпиялылық – бұл адвокатпен, дәрігермен немесе басқалармен алмасатын және әдетте клиенттің нақты келісімінсіз үшінші тұлғаларға берілмейтін жеке ақпарат. Жекешілдік дегеніміз-жеке істерге және жеке ақпаратқа (жеке өмірге қол сұғылмау құқығы) қол сұғудан босату. Құпиялылық этикалық міндет болса да, жекешелеп-бұл жалпы заңға негізделген құқық. Тәжірибеде цифрлы жекешілдік дегеніміз – интернет арқылы біреуге ақпаратты сақтау, беру, ұсыну құқығы. Құпиялылық, шын мәнінде, өкім тарапынан билікті теріс пайдаланудан қорғайды, тіпті егер адам жаман ештеңе жасамаса да .",
          five:  "Дербес деректер – бұл сәйкестендірілген немесе сәйкестендірілетін тұлғаға қатысты ақпарат. Жеке мәліметтерге медициналық жазбалар, қылмыстық сот төрелігі бойынша тергеулер мен сот процестері, қаржы институттары және олардың транзакциялары, биологиялық белгілер (генетикалық материал), геолокация, веб-серфинг және cookie файлдарын пайдаланатын пайдаланушы параметрлері және т.б.",
          six:   "Сәйкестендірілетін тұлға – бұл тікелей немесе жанама түрде, атап айтқанда, осындай сәйкестендіргішке сілтеме жасау арқылы сәйкестендірілуі мүмкін тұлға. Бұл жеке тұлғаның физикалық, физиологиялық, генетикалық, психикалық, экономикалық, мәдени немесе әлеуметтік сәйкестендірілуіне тән бір немесе бірнеше факторлардың атауы, сәйкестендіру нөмірі, орналасқан жері, желі идентификаторы, соның ішінде IP мекенжайы немесе cookie идентификаторы немесе мүлдем басқа факторлар болуы мүмкін . Егер адамды тікелей өңделген ақпараттан анықтау мүмкін болса, онда ол жеке мәліметтер болуы мүмкін. Дербес деректер, сондай-ақ дербес деректердің арнайы санаттар-соттылығы және қылмыстар туралы деректерді қамтуы мүмкін. Олар сезімтал болып саналатындықтан, оларды өңдеу тек шектеулі жағдайларда ғана мүмкін болады.",
          seven: "Егер жеке деректерді қорғау рұқсатсыз кіруден қорғау болса, онда деректердің құпиялылығы уәкілетті қол жетімділік болып табылады: оны кім иеленеді және оны кім анықтайды. Басқаша айтқанда, деректерді қорғау – бұл техникалық міндет, ал деректердің құпиялылығы заңды (заңгерлік). Технологиялар жеке деректердің құпиялылығын қамтамасыз ете алмайтындығы мойындалады, нәтижесінде адам құқықтары саласындағы халықаралық құқық стандарттары мен қағидаттарын бұзудың теріс салдарын азайту үшін құқықтық, білім беру және басқа да бастамалар жасалады. Құпиялылықты қорғау хаттамаларының көпшілігі деректерге қол жеткізе алатын уәкілетті адамдарға әлі де осал. Бұл уәкілетті тұлғалардың ауыртпалығы, ең алдымен, технологиямен емес, құпиялылық туралы заңмен байланысты.",
        },
        international:   {
          sideTitleLeft:  "Халықаралық",
          sideTitleRight: "стандарттар",

          pageTitle1: "Халықаралық",
          pageTitle2: "стандарттар",

          one:   "Адам құқықтарының жалпыға бірдей декларациясының 12-бабында «ешкімді өзінің жеке және отбасылық өміріне өз бетінше араласуға, үй-баспанасына қол сұғылмаушылығына, хат-хабарларының құпиясына немесе оның ар-намысы мен беделіне өз бетінше қол сұғуға болмайтыны жайлы айтылған. Әрбір адам осындай араласудан немесе осындай қол сұғушылықтан заңды қорғауға құқылы» .",
          two:   "2013 жылғы желтоқсанда БҰҰ Бас Ассамблеясы 68/167 қарарды қабылдады, онда цифрлы «бақылау және тыңдау» адам құқықтарына әсер ететін жағымсыз салдары мен әсерлер туралы алаңдаушылық білдірді. Цифрлы құпиялылық құқығын сақтауға шақыра отырып, БҰҰ адамдардың нақты өмірдегі құқықтары онлайн ортада да қорғалуы керек екенін растады. Адам құқықтары саласындағы халықаралық құқық әмбебап шеңберлердіқамтамасыз етеді, олардың негізінде адам құқықтарының жеке өміріне қол сұғылмаушылыққа кез-келген араласу бағалануы керек . Бас Ассамблея барлық мемлекеттерді хабарламаларды бақылауға, жеке деректерді ұстап алуға және жинауға қатысты өз рәсімдерін, практикасы мен заңнамасын қайта қарауға шақырып, мемлекеттердің адам құқықтары саласындағы халықаралық құқық бойынша өз міндеттемелерін толық және тиімді орындауын қамтамасыз ету қажеттілігін атап өтті .",
          three: "Кейіннен цифрлы дәуірдегі жеке өмірге қол сұғу құқығы туралы есеп дайындалып, ұсынылды, онда бұл құқықты құрметтеу және қорғау қажеттілігі расталды . БҰҰ-ның 69/166 қарары  адам құқықтары жөніндегі кеңес өзінің 28/16 қарарында жеке өмірге, оның ішінде жаңа цифрлы технологиялар контекстінде жеке өмірге қатысты алғашқы арнайы баяндамашының пайда болғанын жариялаған кезде арнайы процедураны құру процесін бастады .",
          four:  "Азаматтық және саяси құқықтар туралы халықаралық пакт  сонымен бірге ешкімнің жеке өміріне, отбасына, тұрғын үйіне немесе хат-хабарларына, сондай-ақ оның ар-намысы мен беделіне заңсыз қол сұғушылыққа ұшырамауы керек және «әркімнің мұндай араласудан немесе шабуылдардан заңды қорғауға құқығы бар» екенін қамтамасыз етеді.",
          five:  "Адам құқықтары туралы Еуропалық конвенция  және ЕО негізгі құқықтары  Хартиясында ұқсас ережелер бар. Жеке өмірге қол сұғу құқығы абсолютті болмаса да, араласудың кез-келген жағдайында оның қажеттілігі, заңдылығы мен пропорционалдылығы мұқият және сыни бағалануы керек.",
          six:   "Планетаның әртүрлі бөліктеріндегі жеке деректерді қорғау жаңа реңктерге ие болады, өйткені бұл интернетті басқару мен бақылаумен және нәтижесінде авторитаризмнің кең көлемді өсуімен байланысты. Жеке деректерді шексіз жинау адамның жалғыздық құқығын шектейді, онсыз бейбітшілік, өркендеу және жеке бас бостандығы – демократиялық әлемнің жемісі – сақтау немесе пайдалану мүмкін емес.",
          seven: "Британдық Comparitech компаниясы жүргізген зерттеу нәтижелеріне сәйкес, әлемнің барлық елдері тұрақты негізде құпиялылықты сақтау және соның салдарынан жеке деректерді қорғау бойынша белгіленген міндеттерден өте алыс. Әзірленген критерийлер бойынша 1-ден 5-ке дейін (балл), жобаға қатысқан 47 мемлекеттің тек 5-і ғана 3 баллдан жоғары баға алды. Егер кейбір елдер неғұрлым жауапты және ашық болса, басқалары тұрақты жүйелі қауіпсіздік шараларын, атап айтқанда өз азаматтарына цифрлы бақылауды қолданады .",
          eight:  "Егер дербес деректерді қорғау туралы айтатын болсақ, жеке ақпаратқа қатысты жеті негізгі қағидатты белгілейтін GDPR-ге (деректерді қорғау бойынша жалпы регламент) назар аудару маңызды:\n" +
                   "•\tДеректерді өңдеудің заңдылығы, әділдігі және ашықтығы;\n" +
                   "•\tДеректерді жинау және пайдалану мақсатын шектеу;\n" +
                   "•\tДеректерді азайту (тек қажетті деректерді жинау);\n" +
                   "•\tДәлдік (дәл емес деректерді жою немесе түзету керек);\n" +
                   "•\tСақтауды шектеу (деректерді сақтау мерзімі);\n" +
                   "•\tШынайылық және құпиялылық (деректерді рұқсатсыз немесе заңсыз өңдеуден, жоюдан немесе бүлінуден қорғау);\n" +
                   "•\tЕсеп беру (GDPR-ді сақтау міндеті және ережеге сәйкестігін көрсету мүмкіндігі).\n",
          ten:   "Бұл принциптер Еуропада жеке деректерді өңдеудің кез-келген тәсілінің негізі болып табылады, сонымен қатар олар экстерриториялық принципке ие. Бұл Еуроодақпен ынтымақтасатын жеке тұлғалардың дербес деректерін жинайтын, өңдейтін және сақтайтын барлық ұйымдар азаматтардың цифрлы құқықтарын қорғауға шақырылғанын және осы талаптарға сәйкес келуге тиіс екенін білдіреді . GDPR жеке деректер иелерінен неғұрлым мағыналы келісім алуды, қандай деректер жиналатындығын және не үшін ашықтығын арттыруды және пайдаланушыларға ақпаратты жүктеуге, жіберуге немесе жоюға мүмкіндік беруді талап етеді. ",
        },
        national:        {
          sideTitleLeft:    "Ұлттық ",
          sideTitleRight:   "стандарттар",
          sideTitleMidLeft: "",

          pageTitle1:       "Ұлттық ",
          pageTitle2:       "стандарттар",

          one:              "2013 жылғы 21 мамырда Қазақстанда «Дербес деректер және оларды қорғау туралы заң» қабылданды . Заңға сәйкес, «дербес деректер – олардың негізінде айқындалған немесе айқындалатын Дербес деректер субъектісіне қатысты, электрондық, қағаз және (немесе) өзге де материалдық жеткізгіште тіркелген мәліметтер». Бұл заңның басты мақсаты «адамның және азаматтың жеке деректерін жинау және өңдеу кезінде оның құқықтары мен бостандықтарын қорғауды қамтамасыз ету» (2-бап) болып табылады, бұл ретте сақтау туралы сөз қозғалмайды. Бұдан басқа, заң дербес деректерді қорғауға кепілдіктер береді – жеке өмірге қол сұғылмаушылық, жеке және отбасылық құпия құқықтарын іске асыру; олардың тұтастығы мен сақталуын қамтамасыз ету; олардың құпиялылығын сақтау; оларға қол жеткізу құқығын іске асыру және оларды заңсыз жинау мен өңдеудің алдын алу мақсатында шаралар кешені, оның ішінде құқықтық, ұйымдастырушылық және техникалық шаралар кешені. Бұл ретте мемлекеттік жүйелер үшін дербес деректерді электрондық нысанда қорғау ерекшеліктері «Ақпараттандыру туралы» заңда айқындалған. ",
          two:              "2020 жылғы 25 маусымда ҚР Президенті Қ.-Ж.Тоқаев 35 заңнамалық актіге, оның ішінде 7 кодекс пен 28 заңға өзгерістер мен толықтырулар енгізетін «Қазақстан Республикасының кейбір заңнамалық актілеріне цифрлы технологияларды реттеу мәселелері бойынша өзгерістер мен толықтырулар енгізу туралы» Заңға қол қойды . ҚР цифрлық даму, инновациялар және аэроғарыш өнеркәсібі министрлігінің Ақпараттық қауіпсіздік комитеті (АҚК) тұлғасында дербес деректерді қорғау саласындағы уәкілетті органды (Data Protection Agency-ге ұқсас) құру басты жаңалық болды (АҚК дербес деректерді қорғау агенттігі мәртебесін алды) .",
          three:            "Дербес деректер және оларды қорғау туралы заңнаманы бұзғаны үшін Қазақстан Республикасының әкімшілік және қылмыстық кодекстері шеңберінде жауапкершілік көзделген (ҚР ҚК 147 және 211-баптары «Жеке өмірге қол сұғылмаушылықты және Қазақстан Республикасының Дербес деректер және оларды қорғау туралы заңнамасын бұзу» және «Қол жеткізілуі шектеулі электрондық ақпараттық ресурстарды заңсыз тарату», 205-бапта «Ақпаратқа, ақпараттық жүйеге немесе телекоммуникациялар желісіне заңсыз қол жеткізу», 208 бапта «Ақпаратты заңсыз иелену», ӘҚБтК-нің 79 және 641-баптары «Қазақстан Республикасының Дербес деректер және оларды қорғау туралы заңнамасын бұзу» және «Қазақстан Республикасының Ақпараттандыру туралы заңнамасын бұзу») бойынша айыппұлдардан бас бостандығынан айыруға жазаланады .",
          four:             "Дербес деректерді қорғау саласындағы уәкілетті органды құру GDPR талаптарына сәйкестікке қарай күтілетін қозғалыс жолындағы алғашқы қадам болды. ҚР цифрлы даму, инновациялар және аэроғарыш өнеркәсібі министрлігі Қазақстанда дербес деректерді қорғау жүйесі еуропалық GDPR-ге сәйкес толықтырылатынын атап өтті, дегенмен бұл жүйе ресейлік және қытайлық тұжырымдамалардың элементтерін – «егемен интернет», «ұлы файрволл», цензура және т.б. элементтерді біріктіреді. Жеке деректерді қорғау агенттігінің функциялары негізінен технологиялық шешімдерге және осы саладағы заңнаманы сақтауға негізделгеніне назар аудару керек. Азаматтарға өзінің дербес деректерімен жасалатын іс-әрекеттер туралы хабарлауға ниетті дербес деректерді қорғау сервисін енгізу дербес деректермен жұмыс істеудің барлық қағидаттарын қамтамасыз ету үшін қажетті барлық шаралар кешенін толық көлемде көрсетпейді .",
          five:             "Атап айтқанда, бейінді комитет деректерді қорғау мәселелері бойынша түсіндірулерді қалай және кімге ұсынатыны және бұл орган қаншалықты ашық болатыны туралы сөз болып отыр. Әлемдік тәжірибе цифрлы құқықтардың сақталуына және дербес деректердің қорғалуына барынша ашық кепілдік беру үшін уәкілетті органның тәуелсіз және дербес болуы тиіс екенін көрсетеді. Сонымен қатар, қазіргі жағдайда, мемлекеттік органдар жаңа қауіп-қатерлерге қарсы осал болу үшін өздерінің қауіпсіздігін априоримен қамтамасыз етуге тырысқанда, олар өз азаматтарын оқыту керектігін және осы мәселелерде хабардар етуінің өте маңызды екенін жиі ұмытып кетеді. Зерттеу көрсеткендей, азаматтар VPN қызметтерін жеке деректерді кибер алаяқтардан қорғау үшін емес, мемлекеттік бақылау мен тыңдаудан қорғау үшін пайдаланады .",
          six:              "Агенттіктің қызметі азаматтарға олардың деректерімен іс-әрекеттер туралы хабарлауға мүмкіндік береді деп күту қисынды. Алайда, бұл мемлекеттік органдар мен азаматтар арасында сенім болмаған қазіргі жағдайда мүмкін емес сияқты. Сонымен қатар, бейнемониторингті, бетті тану жүйелерін, биометриканы енгізу арқылы ауқымды цифрландыруды назарға ала отырып, бір жағынан, сондай-ақ 2019 жылы ОСК мен Бас прокуратураның деректер базасынан ірі деректердің ашылып қалуын – екінші жағынан, мемлекет дербес деректерді қорғаудың қандай кепілдіктерін бере алатынын және оның онлайн-және офлайн ортада адам құқықтары мен бостандықтарын сақтау бойынша өзіне алған халықаралық міндеттемелерді қалай сақтайтынын түсіну өте маңызды.",
        },
        ps2020:          {
          pageTitle1:       "Қазақстандағы ",
          pageTitle2:       "2020 жылғы жағдай",

          one:              "Бұл кезеңде ҚР-да дербес деректерді қорғау бойынша Құқықтық статистика жоқ, ал деректердің таралып кетуімен байланысты барлық жағдайлар қызметкерді жұмыстан шығарумен немесе қылмыс құрамының болмауына байланысты істің жабылуымен аяқталды. Бұл жағдайда құқықтық тетікті ашық және шынайы түрде дамыту қажет. Дербес деректерді қорғау мәдениетін, оның ішінде дербес деректер субъектілеріне ақпараттың таралып кеткендігі туралы хабарлау арқылы дамыту маңызды . Бұл Сізге сенімді қарым-қатынас орнатуға және деректердің ағып кету мүмкіндігін азайту және нәтижесінде олардың иелерінің құпиялылығын қорғау үшін деректерді жинау, сақтау, өңдеу және жою бойынша жауапкершілікті байыпты қабылдауға мүмкіндік береді. Осыған байланысты арнайы іске қосылған SMARTAstana мобильді қосымшалары және Covid19live.kz сондай-ақ COVID-19-мен күресу және қозғалысты бақылау үшін «Сергек» камералар арқылы цифрлы «бақылау» үшін қолда бар мүмкіндіктерді пайдалану, Ashyq қосымшалары COVID-19-мен күресуге және қозғалысты бақылауға арналған. ",
          two:              "Қазақстанда дербес деректерді қорғау туралы сөз болғанда, азаматтардың жеке ақпаратын жинау, өңдеу және сақтау қауіпсіздігіне кімнің қол жеткізе алатыны және бақылау жасайтыны белгісіз. Мемлекеттік органдар, әсіресе қауіпсіздік қызметтері азаматтардың жеке деректерін қорғау құқықтарын қалай және қалай құрметтейтіні түсініксіз болып қалады. Ұлттық бейнебақылау жүйесін енгізу контексінде Үкіметтің қоғамдық тәртіпті сақтау және ұлттық қауіпсіздікті қамтамасыз ету үшін жеке өмірге қол сұғылмаушылық құқығы мен оған араласу арасындағы тепе-теңдікті қалай сақтау керектігі туралы мәселе туындайды . ",
          three:            "Құрылған бейінді агенттік (2019-2020 жылдардың ұсынымдарында қалағандай тәуелсіз болмаса да ) дербес деректерді қорғау саласындағы заңнаманы сақтау жөніндегі жұмысқа белсенді түрде қосылды. Осылайша, 2020 жылдың екінші жартысында Министрлік Дербес деректер және оларды қорғау туралы Заңнаманы бұзу бойынша 40 шағымды қарады .",
          four:             "Көбінесе шағымдар дербес деректерді жинауға және өңдеуге келісімді кері қайтарып алу кезінде операторлардың келісімінсіз, әрекетсіздігінсіз, мемлекеттік органдардың жалпыға қолжетімді ресурстарында жарияланған деректердің интернет-ресурстарын пайдалануға қатысты болды:\n" +
                              "•\tэлектрондық ақпараттық ресурстарда қамтылған дербес деректерді қорғау жөніндегі шараларды тиісінше жүзеге асырмағаны үшін, (ҚР ӘҚБтК-нің 1 тармақша, 641-бабының 1-бөлігі), екі байланыс операторы, 1 сақтандыру компаниясы, 1 лауазымды тұлға әкімшілік жауапкершілікке тартылды;\n" +
                              "•\tдербес деректерді заңсыз жинағаны және өңдегені үшін (ҚР ӘҚБтК-нің 79-бабының 1-бөлігі) 1 жеке тұлға (интернет-ресурстың иесі) және 1 туристік компания әкімшілік жауапкершілікке тартылды.\n",
          five:             "Цифрлы даму, инновациялар және аэроғарыш өнеркәсібі министрлігінен алынған ақпаратқа сәйкес, осы кезеңде келесі бұзушылықтар тіркелді:\n" +
                              "•\tдербес деректерді жинауға және өңдеуге жеке тұлғалардың келісімін кері қайтарып алу кезіндегі дербес деректерді қамтитын база операторларының әрекетсіздігі;\n" +
                              "•\tартық дербес деректерді жинау және өңдеу;\n" +
                              "•\tоператор жүзеге асыратын міндеттерді орындау үшін қажетті және жеткілікті дербес деректер тізбесінің болмауы, сондай-ақ дербес деректерді өңдеуді ұйымдастыруға жауапты тұлға;\n" +
                              "•\tдербес деректерге рұқсатсыз қол жеткізу фактілерін уақытылы анықтау жөніндегі талаптарды сақтамау;\n" +
                              "•\tдербес деректерді сақтау және беру кезінде ҚР ТС 1073-2007  талаптарына сәйкес келетін параметрлері бар ақпаратты криптографиялық қорғау құралдарының болмауы.\n",
          six:              "Қазір жеке деректердің (аты-жөні, ЖСН, жеке куәлік деректері, айыппұлдар, әкімшілік істер, сот шешімдері және т.б.) әртүрлі іздеу жүйелерінде қол жетімді болуында кейбір проблемалар бар. Бұл ретте, цифрлық даму, инновациялар және аэроғарыш өнеркәсібі министрлігінің жауабына сәйкес, «жоғарыда көрсетілген мәліметтер мен дербес деректерді жариялағаны үшін әкімшілік жауапкершілікке тарту мүмкін емес...келіп түскен шағымдармен ғана айналысуға болады» .",
          seven:            "2021 жылы дербес деректерді қорғау жөніндегі ұлттық агенттік мемлекеттік органдар, бизнес және азаматтық қоғам өкілдері арасында дербес деректерді қорғау мәдениетін ілгерілету бойынша өз жұмысын жалғастырады деп күтілуде, атап айтқанда, әңгіме ашықтық пен жариялылық қағидаттарын сақтау, дербес деректерді жоюға, деректерді иесіздендіруге және оларды сақтау мерзімдеріне келісім алу туралы болып отыр.",
          nine:             "Сонымен, егер азаматтардың көп бөлігі жеке деректерді қорғау мен кибергигиенаның маңыздылығын білмесе, ақпараттық және киберқауіпсіздікті қамтамасыз ету мүмкін емес. Тұрақты экожүйені құру үшін үкімет пен азаматтық қоғамға азаматтардың цифрлы құқықтар мен дербес деректерді қорғау саласындағы хабардарлығы мен білім деңгейін арттыруға назар аудару аса маңызды.",
          smallDesc1: "2020 жылғы маусым-желтоқсан аралығында Қазақстанда дербес деректер туралы Заңнаманы бұзу бойынша шағымдар ",
          table:      {
            firstPart:  {
              left:  "Аймақ",
              right: "Шағымдардың саны",
            },
            secondPart: {
              title: "Заңды тұлғалар",
              list:  [
                {
                  left:  "Нур-Сұлтан",
                  right: "4",
                },
                {
                  left:  "алматы",
                  right: "1",
                },
                {
                  left:  "Актөбе",
                  right: "1",
                },
                {
                  left:  "Қараганда",
                  right: "1",
                },
              ],
            },
            thirdPart:  {
              title: "Жеке тұлғалар",
              list:  [
                {
                  left:  "Нур-Сұлтан",
                  right: "9",
                },
                {
                  left:  "алматы",
                  right: "12",
                },
                {
                  left:  "Қараганда",
                  right: "2",
                },
                {
                  left:  "шымкент",
                  right: "1",
                },
                {
                  left:  "Актөбе",
                  right: "1",
                },
                {
                  left:  "Ақтау",
                  right: "1",
                },
                {
                  left:  "Акмола обласы",
                  right: "3",
                },
                {
                  left:  "Солтүстік Қазақстан облысы",
                  right: "2",
                },
                {
                  left:  "Шығыс Қазақстан облысы",
                  right: "1",
                },
                {
                  left:  "Атырау облысы",
                  right: "1",
                },
              ],
            },
          },
        },
        recommendations: {
          sideTitleLeft:    "Ұсынымдар",
          pageTitle1:       "Ұсынымдар",
          one:              "COVID-19 жағдайында Қазақстанда дербес деректерді қорғау жөніндегі жеке регламентті әзірлеу бұрынғыдан да өзекті әрі маңызды бола түседі. Әрине, пандемия елді жаппай цифрландыру және жеке деректерді қорғау идеяларын ілгерілету бойынша белгіленген жоспарларды іске асыруға түзетулер енгізді. Уәкілетті органды құру жағдай мен істің жай – күйіне мониторинг жүргізу, сондай-ақ дербес деректерді қорғау саласындағы заңнаманы әрбір нақты жағдайда шенеуніктер де, белсенді азаматтар да мүлтіксіз сақтауына ықпал ету жолындағы алғашқы қадам болды. ",
          two:              "«Дербес деректер және оларды қорғау туралы» Заңның 20-бабы дербес деректердің қорғалуға жататынын және мемлекет оның кепілгері болатынын білдіреді. Дербес деректерді қорғау саласындағы уәкілетті органды құру-осы жолдағы алғашқы қадам. Алайда, тұтастай алғанда агенттіктің және оның қызметкерлерінің нақты функциялары, өкілеттіктері мен рәсімдеріне қатысты ең маңызды аспектілер реттелмеген болып қала береді. Осыған байланысты барлық осы аспектілерді әзірлеу және нақты жазу және Бас прокуратурадан жеке деректерді қорғау функциясын Агенттікке беру маңызды.",
          three:            "Дербес деректерді қорғау мәселелерімен айналысатын арнайы органның болуына қарамастан, барлық мемлекеттік органдар деректерді жинау, өңдеу, сақтау және жою бойынша әзірленген техникалық, этикалық және құқықтық параметрлерге сәйкес келуге тиіс. Сонымен қатар, жеке деректер туралы заңның жұмыс істеуі маңызды. Алайда жазалау практикасы жүйелі проблемаларды шешуге мүмкіндік бермейді, тек неғұрлым маңызды проблемаларды шешуді – оның ішінде кадрларды оқытуды, бүкіл мемлекеттік аппаратта құқықтық және цифрлық мәдениет құруды неғұрлым кеш мерзімге кейінге қалдыратын болады. Алайда, жұмыстан босату заңнаманы бұзғаны үшін жазалаудың тәсілі бола алмайды, өйткені ол жауапкершілікке тартудың нақты тетіктерін анықтайды.",
          four:             "Дербес деректерді қорғау жөніндегі тәуелсіз және дербес органды құру мүмкін болмаған жағдайда, қысқа мерзімді перспективада азаматтық қоғам, бизнес-қоғамдастық өкілдерінен, заңгерлерден, зерттеушілерден және құқық қорғаушылардан тұратын қоғамдық бақылау тетігін іске қосу қисынды болып көрінеді. Мұндай элемент дербес деректерді қорғау саласындағы жаңа енгізілімдерге барабар ден қоюға, оларды әзірлеуге және енгізуге қатысуға, сондай-ақ құқықтардың сақталуына мониторинг жүргізуге, бұзушылықтарды белгілеуге, дербес деректерді қорғау мәдениетін ілгерілетуге және жиналатын деректердің көлемі көзделген мақсатқа барабар болуын бақылауға мүмкіндік береді.",
          five:             "«Цифрлы Қазақстан» ұлттық бағдарламасын іске асыру барысында ауқымды цифрландыруды ескере отырып, дербес деректерді қорғау жөніндегі уәкілетті органның қызметі мен міндеттемелері адам құқықтары саласындағы халықаралық стандарттарға сәйкес келуі тиіс. Деректерді жинауға және өңдеуге қойылатын нақты талаптарды әзірлеу, жеке ақпаратты сақтау және жою мерзімдерін, оның ішінде адам құқықтарын бұзу тәуекелдерін болдырмау және азайту мақсатында бейнебақылау енгізу және жасанды интеллектті пайдалану үшін тіркеу қажет. Жеке деректерді қорғаудың этикалық компоненті by default (әдепкі) сандық мәдениеті мен цифрлы қоғамының негізі болуы керек",
          six:              "Жасанды интеллект технологиясын, бет-әлпетті тану жүйесін және басқа да технологиялық шешімдерді енгізу бастамалары жеке деректерді ұсыну, жинау, талдау және сақтауға байланысты қауіптерді ескеруі керек. Мұндай процестердің ашықтығы әлеуетті осалдықтарды барынша азайтуға, дағдарыстық жағдайларға барабар ден қоюға және қазақстандық жүйенің кибер тұрақтылығын күшейтуге мүмкіндік береді.",
          seven:            "Бейінді агенттік деректердің таралып кету инциденттері және дербес деректерге авторланбаған қол жеткізу бойынша регламент әзірлеуі қажет. Мұндай жағдайлар туралы басқа біреу емес, осындай дағдарыстық жағдайлар орын алатын ұйымдар хабарлауы керек. Бұл қалыпты стандартты хаттама, құралдар мен тәжірибелер жиынтығы еуропалық GDPR-де ғана емес (жеке деректерді қорғаудың жалпы ережесі), сонымен қатар әлемдегі барлық компанияларда да бар. Тәуекелдерді басқарудың ашық хаттамаларын енгізу жүйелер мен дербес деректерді қорғау жөніндегі компонентті күшейтіп қана қоймай, сыбайлас жемқорлық мақсаттары мен тыңшылық үшін пайдаланылуы мүмкін сұр аймақтарды қысқартуға мүмкіндік береді. Бұл практикалар жеке бизнес туралы айтпағанда, деректер базасымен жұмыс істейтін барлық мемлекеттік органдарға жаппай енгізілуге тиіс.",
          eight:             "Технологиялық және құқықтық шаралармен қатар жеке деректерді білім беру компонентінсіз қорғау мүмкін емес. Цифрлы құқықтар мен жеке деректерді қорғауға назар аудару цифрлы мәдениетті тезірек дамытуға мүмкіндік береді, ал кибергигиена әркімнің күнделікті өмірінің ажырамас бөлігі болуы тиіс.",
        },
      },
    },
  },
}
