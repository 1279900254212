import React, {useEffect, useState} from 'react';
import locationImg                  from '../../assets/images/right-to-fair-trial/point icon.svg';
import {newCalls}                   from '../../utils/new-calls';
import FileImg                      from "../../components/file-img";

function NcNational({lang}) {
  const [data, setData] = useState(newCalls.lang.ru.national)

  useEffect(() => {
    window.scrollTo(0, 0);
    if (lang === 'ru') {
      setData(newCalls.lang.ru.national)
    }
    if (lang === 'eng') {
      setData(newCalls.lang.eng.national)
    }
    if (lang === 'kaz') {
      setData(newCalls.lang.kaz.national)
    }
  }, [lang])
  return (
    <div className="nc-container nc-national">
      <div className="text-body">
        <span className={'side-title left'}>{data.sideTitleLeft}</span>
        <span className={'side-title right'}>{data.sideTitleRight}</span>
        <div className="titles">
          <h1>{data.pageTitle1}</h1>
          <h2>{data.pageTitle2}</h2>
        </div>
        <div className={'box left-text first-text'}>
          <p><img className="right-side-img" src={locationImg} alt="person avatar"/>{data.one}</p>
        </div>
        <div className={'box right-text second-text'}>
          <FileImg number={108} link={"http://adilet.zan.kz/rus/docs/Z2000000347"}/>
          <p>{data.two}</p>
        </div>
        <div className={'box left-text third-text'}>
          <p>{data.three}</p>
          <FileImg number={109} link={"http://adilet.zan.kz/rus/docs/Z1500000418"}/>
        </div>
        <div className={'box right-text fourth-text'}>
          <FileImg number={110} link={"http://adilet.zan.kz/rus/docs/V1800016654"}/>
          <p>{data.four}</p>
        </div>
        <div className={'box left-text fifth-text'}>
          <p>{data.five}
          </p>
          <FileImg number={111} link={"http://adilet.zan.kz/rus/docs/P2000000079"}/>
        </div>
      </div>
    </div>
  );
}

export default NcNational;
