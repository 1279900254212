import React, {useEffect, useState} from 'react';
import {dr}                         from '../../utils/digital-rights'
import questionIcon                 from '../../assets/images/digital-rights/question icon.svg'
import circleLong                   from '../../assets/images/digital-rights/circle-long.svg'
import TopBanner                    from "../../components/top-banner";

function DRWhat({lang}) {
    const [data, setData] = useState(dr.digitalRights.lang.ru.what)

    useEffect(() => {
        window.scrollTo(0, 0);
        if (lang === 'ru') {
            setData(dr.digitalRights.lang.ru.what)
        }
        if (lang === 'eng') {
            setData(dr.digitalRights.lang.eng.what)
        }
        if (lang === 'kaz') {
            setData(dr.digitalRights.lang.kaz.what)
        }
    }, [lang])

    return (
        <div className={'dr-what dr-container'}>
            <div className="text-body">
                <span className={'side-title left'}>{data.sideTitleLeft}</span>
                <span className={'side-title right'}>{data.sideTitleRight}</span>
                <h1>{data.firstPageTitle}</h1>
                <h2>{data.secondPageTitle}</h2>
                <div className={'box left-text first-text'}>
                    <p><img className="right-side-img lockIcon" src={questionIcon} alt=""/>{data.firstText}</p>
                </div>
                <div className={'box right-text second-text'}>
                    <p><img className="left-side-img long" src={circleLong} alt=""/>{data.secondText}</p>
                </div>
                <div className={'box left-text third-text'}>
                    <p>{data.thirdText}</p>
                </div>
            </div>

        </div>
    );
}

export default DRWhat;
