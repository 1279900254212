import React, {useEffect, useState} from 'react';
import listImg             from '../../assets/images/electronic-participation/list icon.svg';
import fileImg             from '../../assets/images/electronic-participation/papka.svg';
import {rightsToFairTrial} from '../../utils/rights-to-fair-trial';

function RightRecommendations({lang}) {
  const [data, setData] = useState(rightsToFairTrial.lang.ru.recommendations)

  useEffect(() => {
    window.scrollTo(0, 0);
    if (lang === 'ru') {
      setData(rightsToFairTrial.lang.ru.recommendations)
    }
    if (lang === 'eng') {
      setData(rightsToFairTrial.lang.eng.recommendations)
    }
    if (lang === 'kaz') {
      setData(rightsToFairTrial.lang.kaz.recommendations)
    }
  }, [lang])
  return (
    <div className="right-container right-recommendations">
      <div className="text-body">
        <span className={'side-title left'}>{data.sideTitleLeft}</span>
        <h1>{data.pageTitle1}</h1>
        <div className={'box left-text first-text'}>
          <span className={'side-number'}>1</span>
          <p><img className="right-side-img" src={listImg} alt="person avatar"/>{data.one}</p>
        </div>
        <div className={'box right-text second-text'}>
          <span className={'side-number'}>2</span>
          <p>{data.two}</p>
        </div>
        <div className={'box left-text third-text'}>
          <span className={'side-number'}>3</span>
          <p>{data.three}</p>
        </div>
        <div className={'box right-text fourth-text'}>
          <span className={'side-number'}>4</span>
          <p>{data.four}</p>
        </div>
        <div className={'box left-text fifth-text'}>
          <span className={'side-number'}>5</span>
          <p>{data.five}
          </p>
        </div>
        <div className={'box right-text sixth-text'}>
          <span className={'side-number'}>6</span>
          <p>{data.six}
          </p>
        </div>
      </div>
    </div>
  );
}

export default RightRecommendations;
