import React, {useEffect, useState} from 'react';
import {disclaimer}                 from '../../utils/disclaimer';
import {useHistory}                 from 'react-router-dom';
import TopBanner                    from '../../components/top-banner';
import GoBack                       from '../../components/goBack'
import ThemeButton                  from '../../components/theme-button';

function Disclaimer({lang,toggleTheme}) {
    const [data, setData] = useState(disclaimer.ru)
    const history = useHistory()
    const goToPage = (_path) => {
        history.push(_path)
    }
    useEffect(() => {
        if (lang === 'ru') {
            setData(disclaimer.ru)
        }
        if (lang === 'eng') {
            setData(disclaimer.eng)
        }
        if (lang === 'kaz') {
            setData(disclaimer.kaz)
        }
    }, [lang])
    return (
        <div className={'disclaimer__container'}>
            <GoBack lang={lang}/>
            <TopBanner/>
            <p>{data.p1}</p>
            <p>{data.p2} <a href="https://report2020.drfl.kz">https://report2020.drfl.kz</a></p>
            <ThemeButton toggleTheme={toggleTheme}/>
        </div>
    );
}

export default Disclaimer;
