import React, {useEffect, useState} from 'react';
import groupPeopleImg               from '../../assets/images/freedom-expression/peopleGroup.svg';
import img3                         from '../../assets/images/freedom-expression/Group 41.svg'
import squareImg                    from '../../assets/images/freedom-expression/squareImg.svg'
import {fe}                         from '../../utils/freedom-expression';
import FileImg                      from "../../components/file-img";

function FeInternational({lang}) {
  const [data, setData] = useState(fe.freedomExpression.lang.ru.international)

  useEffect(() => {
    window.scrollTo(0, 0);
    if (lang === 'ru') {
      setData(fe.freedomExpression.lang.ru.international)
    }
    if (lang === 'eng') {
      setData(fe.freedomExpression.lang.eng.international)
    }
    if (lang === 'kaz') {
      setData(fe.freedomExpression.lang.kaz.international)
    }
  }, [lang])
  return (
    <div className="fe-container fe-international">
      <div className="text-body">
        <span className={'side-title left'}>{data.sideTitleLeft}</span>
        <span className={'side-title right'}>{data.sideTitleRight}</span>
        <div className="titles">
          <h1>{data.pageTitle1}</h1>
          <h2>{data.pageTitle2}</h2>
        </div>
        <div className={'box left-text first-text'}>
          <p><img className="right-side-img groupPeopleImg" src={groupPeopleImg} alt="person avatar"/>{data.one}</p>
          <FileImg number={23} link={"https://undocs.org/ru/A/RES/59%28I%29"}/>
        </div>
        <div className={'box right-text second-text'}>
          <FileImg number={24} link={"https://www.un.org/ru/documents/decl_conv/conventions/pactpol.shtml"}/>
          <p>{data.two}</p>
        </div>
        <div className={'box left-text third-text'}>
          <p>{data.three}</p>
          <FileImg number={25} link={"http://cyberpeace.org.ua/files/ii_a_5.pdf"}/>
          <img className="img3" src={img3} alt="file"/>
        </div>
        <div className={'box right-text fourth-text'}>
          <p>{data.four}</p>
        </div>
        <div className={'box left-text fifth-text'}>
          <p>{data.five}
          </p>
        </div>
        <div className={'box right-text sixth-text'}>
          <p>{data.six}
          </p>
        </div>
        <div className={'box left-text seventh-text'}>
          <p>{data.seven}
          </p>
          <FileImg number={26 } link={"https://www.osce.org/files/f/documents/f/2/102323.pdf"}/>
          <img className="squareImg" src={squareImg} alt=""/>
        </div>
      </div>
    </div>
  );
}

export default FeInternational;
