import React, {useEffect, useState} from 'react';

import locationImg from '../../assets/images/right-to-fair-trial/point icon.svg';
import img4        from '../../assets/images/freedom-expression/Group 43.svg';
import unionImg    from '../../assets/images/freedom-expression/Union.svg'
import {ia}        from '../../utils/info-access';
import FileImg     from "../../components/file-img";

function IaNational({lang}) {
  const [data, setData] = useState(ia.infoAccess.lang.ru.national)

  useEffect(() => {
    window.scrollTo(0, 0);
    if (lang === 'ru') {
      setData(ia.infoAccess.lang.ru.national)
    }
    if (lang === 'eng') {
      setData(ia.infoAccess.lang.eng.national)
    }
    if (lang === 'kaz') {
      setData(ia.infoAccess.lang.kaz.national)
    }
  }, [lang])
  return (
    <div className="ia-container ia-national">
      <div className="text-body">
        <span className={'side-title left'}>{data.sideTitleLeft}</span>
        <span className={'side-title right'}>{data.sideTitleRight}</span>
        <div className="titles">
          <h1>{data.pageTitle1}</h1>
          <h2>{data.pageTitle2}</h2>
        </div>
        <div className={'box left-text first-text'}>
          <p><img className="right-side-img locationImg" src={locationImg} alt="person avatar"/>{data.one}</p>
          <FileImg number={60} link={"http://adilet.zan.kz/rus/docs/Z1500000401/z150401.htm"}/>
        </div>
        <div className={'box right-text second-text'}>
          <p>{data.two}</p>
        </div>
        <div className={'box left-text third-text'}>
          <p>{data.three}</p>
        </div>
        <div className={'box right-text fourth-text'}>
          <FileImg number={61} link={"http://adilet.zan.kz/rus/docs/P1500001196"}/>
          <p>{data.four}</p>
        </div>
        <div className={'box left-text fifth-text'}>
          <p>{data.five}
          </p>
          <FileImg number={62} link={"http://adilet.zan.kz/rus/docs/P1500001196"}/>
        </div>
        <div className={'box right-text text6'}>
          <p>{data.six}
          </p>
          <img className="img4" src={img4} alt=""/>
        </div>
        <div className={'box left-text text7'}>
          <p>{data.seven}
          </p>
        </div>
        <div className={'box right-text text8'}>
          <p>{data.eight}
          </p>
        </div>
        <div className={'box left-text text9'}>
          <p>{data.nine}
          </p>
          <FileImg number={63} link={"http://adilet.zan.kz/rus/docs/K1400000235"}/>
        </div>
        <div className={'box right-text text10'}>
          <p>{data.ten}
          </p>
        </div>
        <div className={'box left-text text11'}>
          <p>{data.eleven}
          </p>
        </div>
        <div className={'box right-text text12'}>
          <p>{data.twelve}
          </p>
        </div>
        <div className={'box left-text text13'}>
          <p>{data.thirteen}
          </p>
          <img className="unionImg" src={unionImg} alt="file"/>
          <FileImg number={64} link={"https://www.rti-rating.org"}/>
        </div>

      </div>
    </div>
  );
}

export default IaNational;
