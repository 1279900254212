import React, {useEffect, useState} from "react";
import {resources, resourcesTitle}  from "../../utils/resources";
import TopBanner                    from '../../components/top-banner';
import ThemeButton                  from '../../components/theme-button';

function ResourcesPage({lang,toggleTheme}) {
  const [data, setData] = useState(resourcesTitle.ru)

  useEffect(() => {
    window.scrollTo(0, 0);
    if (lang === "ru") {
      setData(resourcesTitle.ru)
    }
    if (lang === "eng") {
      setData(resourcesTitle.eng)
    }
    if (lang === "kaz") {
      setData(resourcesTitle.kaz)
    }
  }, [lang])
  const rec = resources;
  return (
    <div className="container">
      <TopBanner title={data}/>
      <div className={"res-title"}>{data}</div>
      {rec.map((resource, i) => (
        <div id={`${i+1}`} className={"resources__row"}>
          <span className={"resources__number"}>{i + 1}</span>
          <span className={"resources__link"}>{resource}</span>
        </div>
      ))}
      <ThemeButton toggleTheme={toggleTheme}/>
    </div>
  );
}

export default ResourcesPage;
