import React, {useEffect, useState} from 'react';
import menu1                        from '../../assets/icons/menu-page/1.svg';
import menu2                        from '../../assets/icons/menu-page/menu2.svg';
import menu3            from '../../assets/icons/menu-page/1.svg';
import menu4            from '../../assets/icons/menu-page/menu4.svg';
import menu5            from '../../assets/icons/menu-page/menu5.svg';
import menu6            from '../../assets/icons/menu-page/2.svg';
import {menu}           from '../../utils/menu';
import {mainPage}       from "../../utils/main-page";
import MenuTopNav       from "../../components/menu-top-nav";
import TopBanner        from "../../components/top-banner";
import {Link}           from "react-router-dom";
import ArrowRightIcon   from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon    from "@material-ui/icons/ArrowLeft";
import DisclaimerButton from '../../components/disclaimer';

function DigitalRightsMenu({lang,navData,truncateString, goToPage}) {
    const [data, setData] = useState(menu.lang.ru)

    useEffect(() => {
        window.scrollTo(0, 0);
        if (lang === 'ru') {
            setData(menu.lang.ru)
        }
        if (lang === 'eng') {
            setData(menu.lang.eng)
        }
        if (lang === 'kaz') {
            setData(menu.lang.kaz)
        }
    }, [lang])
    return (
        <div className={'menu-page ps-menu-container'}>
            <TopBanner title={navData.digitalRights}/>
            <div className="menu-body">
                <MenuTopNav lang={lang} currentNav={'digital-rights'} data={navData.personalSecurity}/>

                <div  className="menu-title">
                    <Link className="left side-icons" to={"/menu/about"}><ArrowLeftIcon /></Link>
                    <h1 onClick={()=>goToPage('/')}>digital rights matter</h1>
                    <Link className="right side-icons" to={"/menu/freedom-expression"}> <ArrowRightIcon /> </Link>
                </div>



                <span className="left side-button" onClick={() => goToPage('/menu/about')}/>
                <span className="right side-button" onClick={() => goToPage('/menu/freedom-expression')}/>
                <div className="upper-part">
                    <span className="side-word">DIGITA</span>
                    <div className="text-boxes">
                        <div className="left-box">
                            <h3 className="sub-page-title">{data.pageTitle1}</h3>
                            <p>
                                {truncateString(data.ps.one)}
                            </p>
                        </div>
                        <div className="right-box">
                            <p>{truncateString(data.ps.two)}</p>
                        </div>
                        <span className="small-desc">{data.smallDesc}</span>
                    </div>

                </div>
                <div className="lower-part">
                    <nav className="five-navs">
                        <span onClick={()=>{goToPage("/digital-rights/")}} className="smaller-text button-one menu-entrance">{data.nav.entrance}</span>
                        <span onClick={()=>{goToPage("/digital-rights/international")}} className="smaller-text button-same button-bigger what-is-it menu-international">{data.nav.international}</span>
                        <span onClick={()=>{goToPage("/digital-rights/national")}} className="smaller-text button-same authors menu-national">{data.nav.national}</span>
                        <span onClick={()=>{goToPage("/digital-rights/2020")}} className="smaller-text button-same menu-ep2020 ">{data.nav.ep2020}</span>
                        <span onClick={()=>{goToPage("/digital-rights/recommendations")}} className="smaller-text button-same conclusion menu-recommendations">{data.nav.recommendations}</span>
                    </nav>
                    <DisclaimerButton/>
                </div>
            </div>
        </div>
    );
}

export default DigitalRightsMenu;
