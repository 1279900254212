export const disclaimer = {
    ru: {
        p1:"Печать публикации осуществляется за счет средств Фонда Сорос-Казахстан. Содержание данной публикации отражает точку зрения авторов, которая не обязательно совпадает с точкой зрения Фонда Сорос-Казахстан.\n Ответственность за факты, сведения, суждения и выводы, содержащиеся в материале, несут авторы.",
        p2:"Любое копирование материала(-ов) разрешено с обязательной ссылкой на сайт"
    },
    kaz: {
       p1:"Басылымды басып шығару Сорос-Қазақстан Қорының қаражаты есебінен жүзеге асырылады. Бұл жарияланымның мазмұны Сорос-Қазақстан Қорының көзқарасымен міндетті түрде сәйкес келмейтін авторлардың көзқарасын көрсетеді.\n Материалда бар фактілер, мәліметтер, пайымдар мен тұжырымдар үшін авторлар жауапты.",
        p2:"Кез келген материалды(-дарды) көшіруге,сайтқа міндетті түрде сілтеме жасай отыра арқылы рұқсат етіледі"
    },

    eng: {
        p1:"The project was implemented with the financial support of the Soros Foundation-Kazakhstan. The views expressed herein are those of the authors and do not necessarily reflect the views of the Soros Foundation-Kazakhstan. The responsibility for the facts, information, judgments and conclusions contained in the publication lies with the authors.\nResponsibility for the facts, information, judgments and conclusions contained in the material lies with the authors.",
        p2:"Any copying of the material(s) is allowed with a mandatory link to the site"
    }
}
