import React, {useEffect, useState} from 'react';
import {ps}                         from '../../utils/personal-security';
import groupPeopleImg               from '../../assets/images/freedom-expression/peopleGroup.svg';
import FileImg                      from "../../components/file-img";

function DaInternationalStandards({lang}) {
    const [data, setData] = useState(ps.personalSecurity.lang.ru.international)

    useEffect(() => {
        window.scrollTo(0, 0);
        if (lang === 'ru') {
            setData(ps.personalSecurity.lang.ru.international)
        }
        if (lang === 'eng') {
            setData(ps.personalSecurity.lang.eng.international)
        }
        if (lang === 'kaz') {
            setData(ps.personalSecurity.lang.kaz.international)
        }
    }, [lang])
    return (
        <div className={'da-container da-narodniy'}>
            <div className="text-body">
                <span className={'side-title left'}>{data.sideTitleLeft}</span>
                <span className={'side-title right'}>{data.sideTitleRight}</span>
                <h1>{data.pageTitle1}</h1>
                <h2>{data.pageTitle2}</h2>
                <div className={'box left-text first-text'}>
                    <p><img className={"right-side-img groupPeopleImg"} src={groupPeopleImg} alt="file"/>{data.one}</p>
                    <FileImg number={4} link={"https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/key-definitions/what-is-personal-data/"} />
                </div>
                <div className={'box right-text second-text'}>
                    <FileImg number={5} link={"https://www.un.org/ru/universal-declaration-human-rights/index.html"}/>
                    <p>{data.two}
                    </p>
                </div>
                <div className={'box left-text third-text'}>
                    <p>{data.three}</p>
                    <FileImg number={6} link={"https://undocs.org/A/RES/68/167"} />
                </div>
                <div className={'box right-text fourth-text'}>
                    <FileImg number={7} link={"https://www.un.org/en/ga/search/view_doc.asp?symbol=A/HRC/28/39"} />
                    <p>{data.four}</p>
                </div>
                <div className={'box left-text fifth-text'}>
                    <FileImg number={8} link={"https://www.un.org/ru/documents/decl_conv/conventions/pactpol.shtml "} />
                    <p>{data.five}</p>
                </div>
                <div className={'box right-text sixth-text'}>
                    <p>{data.six}</p>
                </div>
                <div className={'box left-text seventh-text'}>
                    <p>{data.seven}</p>
                    <FileImg number={9}  link={"https://www.echr.coe.int/documents/convention_rus.pdf"}/>
                </div>
                <div className={'box right-text eighth-text'}>
                    <p>
                        {data.eight}
                    </p>
                </div>
                <div className={'box left-text ninth-text'}>
                    <p>
                        {data.nine}
                    </p>
                    <FileImg number={10} link={"https://www.precisesecurity.com/articles/Top-10-Countries-by-Number-of-CCTV-Cameras"} />
                </div>
            </div>

        </div>
    );
}

export default DaInternationalStandards;
