import React, {useEffect, useState} from 'react';
import locationImg                  from '../../assets/images/right-to-fair-trial/point icon.svg';
import {rightsToFairTrial}          from '../../utils/rights-to-fair-trial';
import FileImg                      from "../../components/file-img";

function RightNational({lang}) {
  const [data, setData] = useState(rightsToFairTrial.lang.ru.national)

  useEffect(() => {
    window.scrollTo(0, 0);
    if (lang === 'ru') {
      setData(rightsToFairTrial.lang.ru.national)
    }
    if (lang === 'eng') {
      setData(rightsToFairTrial.lang.eng.national)
    }
    if (lang === 'kaz') {
      setData(rightsToFairTrial.lang.kaz.national)
    }
  }, [lang])
  return (
    <div className="right-container right-national">
      <div className="text-body">
        <span className={'side-title left'}>{data.sideTitleLeft}</span>
        <span className={'side-title right'}>{data.sideTitleRight}</span>
        <div className="titles">
          <h1>{data.pageTitle1}</h1>
          <h2>{data.pageTitle2}</h2>
        </div>
        <div className={'box left-text first-text'}>
          <p><img className="right-side-img" src={locationImg} alt="person avatar"/>{data.one}</p>
          <FileImg number={94} link={"http://adilet.zan.kz/rus/docs/K950001000_"}/>
        </div>
        <div className={'box right-text second-text'}>
          <FileImg number={95} link={"https://www.icj.org/wp-content/uploads/2020/04/Universal-ICJ-courts-covid-Advocacy-Analysis-brief-2020-RUS.pdf"}/>
          <p>{data.two}</p>
        </div>
        <div className={'box left-text third-text'}>
          <p>{data.three}</p>
          <FileImg number={96} link={"https://www.ohchr.org/RU/NewsEvents/Pages/DisplayNews.aspx?NewsID=25810&LangID=R"}/>
        </div>
        <div className={'box right-text fourth-text'}>
            <FileImg number={97} link={"https://www.icj.org/covid-19-icj-publishes-global-guidance-on-the-use-of-videoconferencing-in-judicial-proceedings/?fbclid=IwAR3SBfjyoKNHyVbCZssyGIXgB0V7viKOG8m4TIZhf56AhFe6EZ9FECldXnY"}/>
          <p>{data.four}
          </p>
        </div>
      </div>
    </div>
  );
}

export default RightNational;
