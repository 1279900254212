import React, {useEffect, useState} from "react";
import {Redirect, Route, Switch}    from 'react-router-dom';
import FeEntrance                   from './fe-entrance';
import FeInternational              from './fe-international';
import FeNational                   from './fe-national';
import Fe2020                       from './fe-2020';
import FeRecommendations            from './fe-recommendations';
import GoBack                       from '../../components/goBack';
import FiveNavs                     from '../../components/five-navs';
import {mainPage}                   from "../../utils/main-page";
import TopBanner                    from "../../components/top-banner";
import ThemeButton                  from '../../components/theme-button';


function FreedomExpression({lang,navData,toggleTheme}) {
  return (
    <div className={'container'}>
      <GoBack lang={lang}/>
      <TopBanner title={navData.freedomExpression}/>
      <Switch>
        <Route exact path={'/freedom-expression'} render={props => <FeEntrance lang={lang} {...props} />}/>
        <Route exact path={'/freedom-expression/international'} render={props => <FeInternational lang={lang} {...props} />}/>
        <Route exact path={'/freedom-expression/national'} render={props => <FeNational lang={lang} {...props} />}/>
        <Route exact path={'/freedom-expression/2020'} render={props => <Fe2020 lang={lang} {...props} />}/>
        <Route exact path={'/freedom-expression/recommendations'}
               render={props => <FeRecommendations lang={lang} {...props} />}/>
        <Redirect to={'/not-found'}/>
      </Switch>
        <FiveNavs lang={lang} path={"freedom-expression"}/>
        <ThemeButton toggleTheme={toggleTheme}/>
    </div>
  );
}

export default FreedomExpression;
