import React, {useEffect, useState} from 'react';
import groupPeopleImg               from '../../assets/images/freedom-expression/peopleGroup.svg'
import {ep}                         from '../../utils/electronic-participation';
import FileImg                      from "../../components/file-img";

function EpInternational({lang}) {
  const [data, setData] = useState(ep.electronicParticipation.lang.ru.international)

  useEffect(() => {
    window.scrollTo(0, 0);
    if (lang === 'ru') {
      setData(ep.electronicParticipation.lang.ru.international)
    }
    if (lang === 'eng') {
      setData(ep.electronicParticipation.lang.eng.international)
    }
    if (lang === 'kaz') {
      setData(ep.electronicParticipation.lang.kaz.international)
    }
  }, [lang])
  return (
    <div className="ep-container ep-international">
      <div className="text-body">
        <span className={'side-title left'}>{data.sideTitleLeft}</span>
        <span className={'side-title right'}>{data.sideTitleRight}</span>
        <div className='titles'>
          <h1>{data.pageTitle1}</h1>
          <h2>{data.pageTitle2}</h2>
        </div>

        <div className={'box left-text first-text'}>
          <p><img className="right-side-img" src={groupPeopleImg} alt="person avatar"/>{data.one}</p>
        </div>
        <div className={'box right-text second-text'}>
          <p>{data.two}</p>
          <FileImg number={74}  link={"http://hrlibrary.umn.edu/russian/euro/Rz17euroco.html"}/>
        </div>
        <div className={'box left-text third-text'}>
          <p>{data.three}</p>
          <FileImg number={75}  link={"https://www.un.org/ru/documents/decl_conv/conventions/pactpol.shtml"}/>
        </div>
        <div className={'box right-text fourth-text'}>
          <p>{data.four}</p>
        </div>
        <div className={'box left-text fifth-text'}>
          <p>{data.five}
          </p>
          <FileImg number={76}  link={"https://www.un.org/ru/documents/decl_conv/conventions/pactecon.shtml"}/>
        </div>
        <div className={'box right-text sixth-text'}>
          <FileImg number={77}  link={"https://www.un.org/ru/documents/decl_conv/conventions/elect_com.shtml"}/>
          <p>{data.six}</p>
        </div>
        <div className={'box left-text seventh-text'}><p>{data.seven}</p>
          <FileImg number={78} link={"https://undocs.org/A/HRC/32/L.20"}/>
        </div>
      </div>

    </div>
  );
}

export default EpInternational;
