import React, {useEffect, useState} from 'react';
import personImg                    from '../../assets/images/electronic-participation/person icon.svg';
import listImg                      from '../../assets/images/electronic-participation/list icon.svg';
import {ep}                         from '../../utils/electronic-participation';

function EpRecommendations({lang}) {
  const [data, setData] = useState(ep.electronicParticipation.lang.ru.recommendations)

  useEffect(() => {
    window.scrollTo(0, 0);
    if (lang === 'ru') {
      setData(ep.electronicParticipation.lang.ru.recommendations)
    }
    if (lang === 'eng') {
      setData(ep.electronicParticipation.lang.eng.recommendations)
    }
    if (lang === 'kaz') {
      setData(ep.electronicParticipation.lang.kaz.recommendations)
    }
  }, [lang])
  return (
    <div className="ep-container ep-recommendations">
      <div className="text-body">
        <span className={'side-title left'}>{data.sideTitleLeft}</span>
        <h1>{data.pageTitle1}</h1>
        <h3>{data.smallDesc1}</h3>
        <div className={'box left-text first-text'}>
          <span className={'side-number'}>1</span>
          <p><img className="right-side-img" src={listImg} alt="person avatar"/>{data.one}</p>

        </div>
        <div className={'box right-text second-text'}>
          <span className={'side-number'}>2</span>
          <p>{data.two}</p>
        </div>
        <div className={'box left-text third-text'}>
          <span className={'side-number'}>3</span>
          <p>{data.three}</p>
        </div>
        <h4>{data.smallDesc2}</h4>
        <div className={'box right-text fourth-text'}>
          <span className={'side-number'}>1</span>
          <p>{data.four}</p>
        </div>
        <div className={'box left-text fifth-text'}>
          <span className={'side-number'}>2</span>
          <p>
            {data.five}
          </p>
        </div>
        <div className={'box right-text'}>
          <span className={'side-number'}>3</span>
          <p>{data.six}</p>
        </div>
        <div className={'box left-text'}>
          <span className={'side-number'}>4</span>
          <p>{data.seven}</p>
        </div>
        <div className={'box right-text'}>
          <span className={'side-number'}>5</span>
          <p>{data.eight}</p>
        </div>
        <h4>{data.smallDesc3}</h4>
        <div className={'box right-text'}>
          <span className={'side-number'}>1</span>
          <p>{data.nine}</p>
        </div>
        <div className={'box left-text'}>
          <span className={'side-number'}>2</span>
          <p>{data.ten}</p>
        </div>
        <div className={'box right-text'}>
          <span className={'side-number'}>3</span>
          <p>{data.eleven}
          </p>
        </div>
      </div>
    </div>
  );
}

export default EpRecommendations;
