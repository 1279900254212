import React, {useEffect, useState} from 'react';
import {menu}                       from '../utils/menu'
import {useHistory}                 from 'react-router-dom';

function NavButtons({lang}) {
    const [data, setData] = useState(menu.lang.ru)
    const history = useHistory()
    const goToPage = (path) => {
        history.push(path)
    }
    useEffect(() => {
        if (lang === 'ru') {
            setData(menu.lang.ru)
        }
        if (lang === 'eng') {
            setData(menu.lang.eng)
        }
        if (lang === 'kaz') {
            setData(menu.lang.kaz)
        }
    }, [lang])
    return (
        <nav className="nav-buttons">
            <span onClick={()=>{goToPage('/about/')}} className="button-one entrance">{data.digitalRightsNav.entrance}</span>
            <span onClick={()=>{goToPage('/about/what')}} className="button-same what-is-it">{data.digitalRightsNav.what}</span>
            <span onClick={()=>{goToPage('/about/authors')}} className="button-same authors">{data.digitalRightsNav.authors}</span>
            <span onClick={()=>{goToPage('/about/results')}} className="button-same conclusion">{data.digitalRightsNav.results}</span>
        </nav>
    );
}

export default NavButtons;
