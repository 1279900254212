import './assets/styles/index.scss';
import {Redirect, Route, Switch, useHistory, useLocation} from 'react-router-dom';
import React, {Suspense, useEffect, useState}             from 'react';
import HomePage                                           from './containers/home-page';
import NotFound                                           from './containers/not-found';
import MenuPage                                           from './containers/menu-page';
import ElectronicParticipation                            from './containers/electronic-participation';
import RightsToFairTrial                                  from './containers/right-to-fair-trial';
import NewCalls                                           from './containers/new-calls';
import FreedomExpression                                  from './containers/freedom-expression';
import LimitOnInternet                                    from './containers/limitation-on-internet-content';
import InfoAccess                                         from './containers/info-access';
import PersonalSecurity                                   from './containers/digital-rights';
import EjectIcon                                          from '@material-ui/icons/Eject';
import About                                              from './containers/about';
import DigitalRights                                      from './containers/digital-rights';
import {mainPage}                                         from './utils/main-page';
import ResourcesPage                                      from './containers/resources';
import Disclaimer                                         from './containers/disclaimer';
import ReactGA                                            from 'react-ga';

const TRACKING_ID = 'UA-209296597-1'; // YOUR_OWN_TRACKING_ID
ReactGA.initialize(TRACKING_ID);
ReactGA.pageview(window.location.pathname);

function App() {
    const [lang, setLang] = useState(localStorage.getItem('lang'))
    const [navData, setNavData] = useState(mainPage.lang.ru)
    const history = useHistory();
    const location = useLocation();
    const goToPage = (path) => {
        history.push(path)
        console.log(path)
    }

    const selectLang = (_lang) => {
        if (_lang === 'ru') {
            localStorage.setItem('lang', 'ru')
            setNavData(mainPage.lang.ru)
            setLang('ru')
        }
        if (_lang === 'eng') {
            localStorage.setItem('lang', 'eng')
            setLang('eng')
            setNavData(mainPage.lang.eng)
        }
        if (_lang === 'kaz') {
            localStorage.setItem('lang', 'kaz')
            setNavData(mainPage.lang.kaz)
            setLang('kaz')
        }
    }

    const scrollTop = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    };

    // function to set a given theme/color-scheme
    function setTheme(themeName) {
        localStorage.setItem('theme', themeName);
        document.documentElement.className = themeName;
    }

    // function to toggle between light and dark theme
    const toggleTheme = () => {
        console.log('TOGGLING THEME')
        if (localStorage.getItem('theme') === 'theme-light') {
            setTheme('theme-dark');
            localStorage.setItem('isDark', 'true');
        } else {
            setTheme('theme-light');
            localStorage.setItem('isDark', 'false');
        }
    }

    // Immediately invoked function to set the theme on initial load
    if (localStorage.getItem('theme') === 'theme-light') {
        setTheme('theme-light');
    } else {
        setTheme('theme-dark');
    }

    useEffect(() => {

        if (window.location.pathname === '/' || window.location.pathname.includes("menu")) {
            setTheme('theme-dark');
        } else {
            if (localStorage.getItem('isDark') === 'true') {
                setTheme('theme-dark')
            }
            if (localStorage.getItem('isDark') === 'false') {
                setTheme('theme-light')
            }
        }
    }, [location])

    return (
        <div className="App">
            <Switch>
                <Route exact path={'/'} render={props =>
                    <HomePage
                        navData={navData}
                        goToPage={goToPage}
                        lang={lang} {...props}
                    />}
                />
                <Route path={'/menu'}
                       render={props =>
                           <MenuPage
                               navData={navData}
                               goToPage={goToPage}
                               lang={lang}
                               selectLang={selectLang}
                               {...props} />}/>
                <Route path={'/about'} render={props =>
                    <About goToPage={goToPage}
                           lang={lang}
                           navData={navData}
                           toggleTheme={toggleTheme}
                           {...props}
                    />}/>
                <Route path={'/digital-rights'}
                       render={props =>
                           <DigitalRights
                               navData={navData}
                               goToPage={goToPage}
                               toggleTheme={toggleTheme}
                               lang={lang}
                               {...props} />}/>
                <Route path={'/electronic-participation'}
                       render={props =>
                           <ElectronicParticipation
                               goToPage={goToPage}
                               navData={navData}
                               lang={lang}
                               toggleTheme={toggleTheme}
                               {...props}/>}/>
                <Route path={'/rights-to-fair-trial'}
                       render={props =>
                           <RightsToFairTrial
                               goToPage={goToPage}
                               navData={navData}
                               lang={lang}
                               toggleTheme={toggleTheme}
                               {...props} />}/>
                <Route path={'/new-calls'} render={props =>
                    <NewCalls
                        goToPage={goToPage}
                        lang={lang}
                        navData={navData}
                        toggleTheme={toggleTheme}
                        {...props} />}/>
                <Route path={'/freedom-expression'}
                       render={props =>
                           <FreedomExpression
                               goToPage={goToPage}
                               navData={navData}
                               lang={lang}
                               toggleTheme={toggleTheme}
                               {...props} />}/>
                <Route path={'/limit-on-internet'}
                       render={props =>
                           <LimitOnInternet
                               goToPage={goToPage}
                               navData={navData}
                               lang={lang}
                               toggleTheme={toggleTheme}
                               {...props} />}/>
                <Route path={'/info-access'} render={props =>
                    <InfoAccess
                        goToPage={goToPage}
                        navData={navData}
                        lang={lang}
                        toggleTheme={toggleTheme}
                        {...props} />}/>
                <Route path={'/resources'} render={props =>
                    <ResourcesPage
                        goToPage={goToPage}
                        navData={navData}
                        lang={lang}
                        toggleTheme={toggleTheme}
                        {...props} />}/>
                <Route path={'/disclaimer'} render={props =>
                    <Disclaimer
                        goToPage={goToPage}
                        navData={navData}
                        lang={lang}
                        toggleTheme={toggleTheme}
                        {...props} />}/>
                <Route exact path={'/not-found'} render={props =>
                    <NotFound
                        goToPage={goToPage}
                        lang={lang}
                        toggleTheme={toggleTheme}
                        {...props} />}/>
                <Redirect to={'/not-found'}/>
            </Switch>
            <div className="languages-links">
                <span onClick={() => selectLang('ru')} className={lang === 'ru' ? 'active' : ''}>RU</span>
                <span onClick={() => selectLang('eng')} className={lang === 'eng' ? 'active' : ''}>EN</span>
                <span onClick={() => selectLang('kaz')} className={lang === 'kaz' ? 'active' : ''}>KZ</span>
            </div>
            <span onClick={() => scrollTop()} className="scroll-to-top"> <EjectIcon/></span>

        </div>
    );
}

export default App;
