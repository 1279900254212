import React, {useEffect, useState} from 'react';
import {Redirect, Route, Switch}    from 'react-router-dom';
import LimitEntrance                from './limit-entrance';
import LimitInternational           from './limit-international';
import LimitNational                from './limit-national';
import Limit2020                    from './limit-2020';
import LimitRecommendations         from './limit-recommendations';
import GoBack                       from '../../components/goBack';
import FiveNavs                     from '../../components/five-navs';
import {mainPage}                   from '../../utils/main-page';
import TopBanner                    from '../../components/top-banner';
import ThemeButton                  from '../../components/theme-button';


function LimitOnInternet({lang, navData, toggleTheme}) {
    return (
        <div className={'container'}>
            <GoBack lang={lang}/>
            <TopBanner title={navData.limitOnInternet}/>
            <Switch>
                <Route exact path={'/limit-on-internet'} render={props => <LimitEntrance lang={lang}{...props} />}/>
                <Route exact path={'/limit-on-internet/international'}
                       render={props => <LimitInternational lang={{lang}}{...props} />}/>
                <Route exact path={'/limit-on-internet/national'} render={props => <LimitNational lang={lang} {...props} />}/>
                <Route exact path={'/limit-on-internet/2020'} render={props => <Limit2020 lang={lang} {...props} />}/>
                <Route exact path={'/limit-on-internet/recommendations'}
                       render={props => <LimitRecommendations lang={lang} {...props} />}/>
                <Redirect to={'/not-found'}/>
            </Switch>
            <FiveNavs lang={lang} path={'limit-on-internet'}/>
            <ThemeButton toggleTheme={toggleTheme}/>
        </div>
    );
}

export default LimitOnInternet;
