export const rightsToFairTrial = {
    lang: {
        ru:  {
            entrance:        {
                sideTitleLeft:    "право на",
                sideTitleRight:   "разбирательство",
                sideTitleMidLeft: "",

                pageTitle1:       "ВВЕДЕНИЕ",
                pageTitle2:       "МЕЖДУНАРОДНЫЕ",
                pageTitle3:       "СТАНДАРТЫ",

                one:              "Право на справедливое судебное разбирательство – одно из базовых прав человека. Оно подразумевает, что любое дело должно рассматриваться независимым и беспристрастным компетентным судом, и во время процесса сторонам должно быть обеспечено равенство.",
                two:              "Право на справедливое судебное разбирательство регулируется несколькими международными документами и соглашениями: Всеобщей декларацией прав человека, Пактом о гражданских и политических правах , Европейской конвенцией о правах челове ка . Эти документы указывают на несколько условий исполнения права: так, помимо вышеуказанного равенства сторон и независимости и беспристрастности суда, необходимо соблюдение презумпции невиновности, возможность проводить судебные процессы публично, и главное – возможность обратиться к справедливой судебной системе для любого человека.",
            },
            national:        {
                sideTitleLeft:    "НАЦИОНАЛЬНЫЕ",
                sideTitleRight:   "СТАНДАРТЫ",
                sideTitleMidLeft: "",

                pageTitle1:       "НАЦИОНАЛЬНЫЕ",
                pageTitle2:       "СТАНДАРТЫ",

                one:              "В Казахстане право на справедливое судебное разбирательство регулируется:\n" +
                                    "●\tКонституцией Республики Казахстан ;\n" +
                                    "●\tКонституционным законом «О судебной системе и статусе судей Республики Казахстан» ;\n" +
                                    "●\t«Кодексом судейской этики» ;\n" +
                                    "●\tГражданским процессуальным кодексом Республики Казахстан ;\n" +
                                    "●\tУголовно-процессуальным кодексом Республики Казахстан ;\n" +
                                    "●\tКодексом об административных правонарушениях Республики Казахстан .",
                two:              "После объявления эпидемии коронавирусной инфекции пандемией и введения почти повсеместного строгого карантина международные организации призвали правительства обеспечить надежный цифровой доступ к работе судов. Так, Международная комиссия юристов в своих рекомендациях  от 06 апреля 2020 подчеркнула, что важно сохранить работу судов и доступа населения к заседаниям даже во времена ЧП, а странам следует создать механизмы для того, чтобы граждане могли обжаловать необоснованное введение карантина.",
                three:            "Управление Верховного комиссара ООН по правам человека в своем заявлении о трудностях работы судебной системы в условиях ЧП  настаивало, что страны должны обеспечить своим гражданам возможность подавать обращения и жалобы в цифровом виде.",
                four:             "В ноябре 2020 года Международный суд опубликовал глобальное руководство по использованию видеоконференций в судебных разбирательствах, особенно в контексте пандемии COVID-19 . Среди прочего в документе упоминалась необходимость обеспечить публичный доступ к процедурам, проводимым в режиме видеоконференции, и приводились соображения по обеспечению права на адвоката в цифровых условиях.\n",
            },
            ep2020:          {
                pageTitle1:       "СИТУАЦИЯ ЗА 2020 ГОД",
                pageTitle2:       "В КАЗАХСТАНЕ",

                one:              "В Казахстане режим ЧП и карантина на всей территории страны был введен впервые в истории: 16 марта президент Касым-Жомарт Токаев подписал соответствующий указ. В тот же день Верховный Суд Республики Казахстан выпустил пресс-релиз «О режиме работы судов республики в рамках ЧП».",
                two:              "Сообщалось, что судебные процессы рекомендовано проводить дистанционно с применением IT-технологий (в т.ч. мобильного приложения «TrueConf»). Отсутствие физического доступа к правосудию ограничило право на справедливое судебное разбирательство, а рекомендации Верховного Суда исполняются далеко не всегда: на практике процессы проводятся в любых мессенджерах, невзирая на качество технологии и связи.",
                three:            "Онлайн-процессы регулируются ст. 11-1 Гражданского процессуального кодекса «Особенности электронного судопроизводства», однако в уголовном и административном кодексах соответствующих глав нет. Единственным документом, регламентирующим проведение судебных процессов, является Приказ Руководителя Департамента по обеспечению деятельности судов при Верховном Суде Республики Казахстан «Об утверждении Правил технического применения средств видеоконференцсвязи от 07.07.2018 года» .В Приказе указывается, что «настоящие Правила технического применения средств видеоконференцсвязи разработаны в соответствии со статьей 370 Уголовно-процессуального кодекса Республики Казахстан, статьей 767 Кодекса Республики Казахстан об административных правонарушениях», однако обе статьи регламентируют только допрос свидетелей и не могут являться правовой базой для полноценного применения видеоконференцсвязи.",
                four:             "Помимо прочего в стране существуют и проблемы технического характера, не позволяющие обеспечить удаленный доступ к правосудию. Например, резонансный уголовный процесс по делу о наезде на блокпост, приведший к гибели двоих полицейских, должен был рассматриваться удаленно в Карасайском районном суде Алматинской области, однако судебный процесс был сорван. По информации портала zakon.kz, подсудимого не могли подключить к видеоконференцсвязи: в следственном изоляторе работал лишь один компьютер.",
                five:             "Несколько судебных процессов над активистами, проведенных в онлайн-формате уже после введения режима ЧП, можно считать маркерами, выявляющими проблемы онлайн-судопроизводства. Любой человек, имеющий доступ к сети Интернет, мог выступить «наблюдателем» на судебных процессах: административных – над активистками Фаризой Оспан и Ариной Осиновской, уголовных – над Альнуром Ильяшевым и Асией Тулесовой. Как результат, в ходе процессов была выстроена система, позволяющая в режиме реального времени предоставить обществу возможности самостоятельно оценить уровень доступности сторон к справедливому судебному разбирательству.",
                six:              "Однако, несмотря на очевидную важность роли наблюдателя с точки зрения права на справедливое судебное разбирательство, у наблюдателей нет процессуального статуса. Это влечёт за собой отсутствие чётко обозначенных прав и обязанностей наблюдателя в ходе судебного процесса, что позволяет судьям запрещать ведение онлайн-трансляции или вовсе не допускать наблюдателя на судебный процесс, ссылаясь на «технические» сложности. Вышеупомянутые судебные процессы еще раз показали, что принципы равенства сторон процесса не соблюдаются, а из-за технических неполадок невозможно ни дать слово всем участникам процесса, ни обеспечить доступ журналистов и других зрителей к трансляции.\n ",
            },
            recommendations: {
                sideTitleLeft:    "рекомендации",

                pageTitle1:       "рекомендации",

                one:              "Проводить дальнейшую работу по техническому обеспечению доступа сторон к судебному процессу с обеспечением всех процессуальных прав в режиме онлайн, включая обеспечение гласности судебного разбирательства и доступа к нему всех заинтересованных сторон без каких бы то ни было ограничений.",
                two:              "Обеспечить своевременную подготовку и выдачу определений, решений, исполнительных листов в рамках гражданского судопроизводства.",
                three:            "Разработать и принять специальное законодательство, регулирующее деятельность правовой системы при режиме чрезвычайного положения и карантина, с учетом международных обязательств и позитивной мировой практики, нацеленной на защиту права человека на справедливое судебное разбирательство.",
                four:             "Дополнить соответствующие разделы уголовного и административного законодательства главами, полностью регламентирующими возможность электронного судопроизводства, базируясь на международных стандартах, обеспечивающих полное равенство сторон процесса.",
                five:             "Дополнить техническую возможность сервиса «Судебный кабинет» возможностью направления заявлений в кассационную инстанцию в порядке, предусмотренном ч. 1 ст. 28 Гражданского процессуального кодекса Республики Казахстан.",
                six:              "Провести мониторинг зданий государственных учреждений, в которых могут проводиться онлайн-заседания по гражданским, уголовным и административным судам, на предмет соответствия техническим требованиям для проведения судебного процесса онлайн.\n",
            },
        },
        eng: {
            entrance:        {
                sideTitleLeft:    "Right to a",
                sideTitleRight:   "fair trial",
                sideTitleMidLeft: "",

                pageTitle1:       "Introduction",
                pageTitle2:       "International",
                pageTitle3:       "standards",

                one:              "The right to a fair trial – by a competent, independent and impartial court established by law, ensuring guarantees of due process and equality of sides is an integral part of human rights.",
                two:              "The right to a fair trial is regulated by several international instruments and agreements: The Universal Declaration of Human Rights, the Covenant on Civil and Political Rights , European Convention on Human Rights . These documents point to several conditions for exercising the right: in addition to the above equality of the parties and the independence and impartiality of the court, there must be respect for the presumption of innocence, the ability to conduct trials in public, and most importantly, the ability to appeal to a fair judicial system for anyone.",
            },
            national:        {
                sideTitleLeft:    "National",
                sideTitleRight:   "standards",
                sideTitleMidLeft: "",

                pageTitle1:       "National",
                pageTitle2:       "standards",

                one:              "Constitution of the Republic of Kazakhstan;\n Constitutional Law \"On the Judicial System and the Status of Judges of the Republic of Kazakhstan\";\nCode of Judicial Ethics;\n Civil Procedure Code of the Republic of Kazakhstan;\n Criminal Procedure Code of the Republic of Kazakhstan;\n Code of Administrative Offences of the Republic of Kazakhstan.\n",
                two:              "Following the declaration of a coronavirus pandemic and the imposition of an almost universal strict quarantine, international organizations called on governments to ensure reliable digital access to the courts. For example, the International Commission of Jurists, in its recommendations  of April 06, 2020, stressed that it is important to keep courts and public access to meetings even in times of emergency, and that countries should create mechanisms for citizens to appeal against unjustified quarantine imposed.",
                three:            "Office of the UN High Commissioner for Human Rights in its statement on the difficulties of the judiciary in a state of emergency  Insisted that countries must ensure that their citizens can file appeals and complaints digitally.",
                four:             "In November 2020, the International Court of Justice published global guidance on the use of videoconferencing in litigation, especially in the context of the COVID-19 pandemic . Among other things, the paper mentioned the need for public access to procedures conducted via videoconference and included considerations for ensuring the right to counsel in a digital environment.",
            },
            ep2020:          {
                pageTitle1:       "Situation in Kazakhstan ",
                pageTitle2:       "as of 2020",

                one:              "In Kazakhstan, the state of emergency and quarantine regime throughout the country was introduced for the first time in history: on March 16, President Kassym-Jomart Tokayev signed the corresponding decree. On the same day, the Supreme Court of the Republic of Kazakhstan issued a press release \"On the Work Mode of the Courts of the Republic under the State of Emergency\"  .",
                two:              "It was reported that trials were recommended to be conducted remotely using IT technologies (including the \"TrueConf\" mobile application). The lack of physical access to justice has limited the right to a fair trial, and the recommendations of the Supreme Court are not always implemented: in practice, trials are held in any messengers, regardless of the quality of technology and communication. ",
                three:            "Online processes are regulated by Art. 11-1 of the Civil Procedure Code \"Features of Electronic Court Proceedings\", but there are no relevant chapters in the Criminal and Administrative Codes. The only document regulating court proceedings is the Order of the Head of the Department for Court Support under the Supreme Court of the Republic of Kazakhstan \"On Approval of the Rules of Technical Application of Videoconferencing Facilities\" dated 07.07.2018 . The Order states that \"these Rules of technical application of videoconferencing were developed in accordance with Article 370 of the Criminal Procedure Code of the Republic of Kazakhstan, Article 767 of the Code of the Republic of Kazakhstan on Administrative Offences\", but both articles regulate only the questioning of witnesses and cannot be the legal basis for the full application of videoconferencing.",
                four:             "Among other things, there are technical problems that prevent remote access to justice in the country. For example, a high-profile criminal case of a hit-and-run roadblock, which resulted in the death of two policemen, was to be heard remotely in the Karasai District Court of the Almaty region, but the trial was disrupted. According to the portal zakon.kz, the defendant could not be connected to video conferencing: only one computer worked in the detention center .",
                five:             "Several trials of activists held in the online format after the introduction of the state of emergency can be considered markers that reveal the problems of online legal proceedings. Any person with access to the Internet could act as an \"observer\" at the trials: administrative trials against activists Fariza Ospan and Arina Osinovskaya, and criminal trials against Alnur Ilyashev and Asiya Tulesova. As a result, a system was built during the trials to provide the public with real-time opportunities to independently assess the level of accessibility of the parties to a fair trial.",
                six:              "However, despite the obvious importance of the role of the observer from the point of view of the right to a fair trial, observers have no procedural status. This entails the absence of clearly defined rights and obligations of the observer during the trial, which allows judges to prohibit the online broadcasting or not allow the observer to attend the trial at all, citing \"technical\" difficulties. The aforementioned trials have once again demonstrated that the principles of equality of arms are not respected, and due to technical problems it is impossible to give the floor to all participants in the process or to ensure access to the broadcast for journalists and other spectators.",
            },
            recommendations: {
                sideTitleLeft:    "Recommendations",
                pageTitle1:       "Recommendations",

                one:              "To further work on the technical provision of access to the trial by the parties with all procedural rights online, including ensuring the publicity of the trial and access to it by all interested parties without restrictions of any kind.",
                two:              "Ensure timely preparation and issuance of rulings, decisions and writs of execution in civil proceedings.",
                three:            "Develop and adopt special legislation governing the legal system under a state of emergency and quarantine regime, taking into account international obligations and positive international practice aimed at protecting the human right to a fair trial;",
                four:             "Supplement the relevant sections of criminal and administrative legislation with chapters fully regulating the possibility of e-proceedings, based on international standards and good international practice to ensure full equality of arms;",
                five:             "To supplement the technical ability of the e-service \"Court Office\" with the ability to send statements to the court of cassation statements in accordance with paragraph 1, article 28 of the Civil Procedure Code of the Republic of Kazakhstan;",
                six:              "To monitor the buildings of public institutions, in which civil, criminal and administrative courts can be held online, for compliance with the technical possibility of organizing unimpeded access to the Internet and as a consequence, access to justice online.",
            },
        },
        kaz: {
            entrance:        {
                sideTitleLeft:    "Онлайн",
                sideTitleRight:   "әділ сот ",
                sideTitleMidLeft: "",

                pageTitle1:       "Кіріспе",
                pageTitle2:       "Халықаралық ",
                pageTitle3:       "стандарттар ",

                one:              "Әділ сот ісін жүргізу құқығы-адамның негізгі құқықтарының бірі. Бұл кез-келген істі тәуелсіз және бейтарап құзыретті сот қарауы керек және процесс барысында тараптарға теңдік қамтамасыз етілуі керек дегенді білдіреді.",
                two:              "Әділ сот талқылауына құқық бірнеше халықаралық құжаттармен және келісімдермен реттеледі: адам құқықтарының жалпыға бірдей декларациясымен, азаматтық және саяси құқықтар туралы пактімен , Адам құқықтары туралы Еуропалық Конвенциямен . Бұл құжаттар Құқықты орындаудың бірнеше шарттарын көрсетеді: жоғарыда аталған тараптардың теңдігі мен соттың тәуелсіздігі мен бейтараптығынан басқа, кінәсіздік презумпциясын сақтау, сот процестерін көпшілік алдында өткізу мүмкіндігі және ең бастысы – кез-келген адам үшін әділ сот жүйесіне жүгіну мүмкіндігі.",
            },
            national:        {
                sideTitleLeft:    "Ұлттық",
                sideTitleRight:   "стандарттар",
                sideTitleMidLeft: "",

                pageTitle1:       "Ұлттық",
                pageTitle2:       "стандарттар ",

                one:              "Қазақстан Республикасының Конституциясы ; «Қазақстан Республикасының сот жүйесі мен судьяларының мәртебесі туралы» Конституциялық заң ;Судья әдебі кодексі ;Қазақстан Республикасының Азаматтық іс жүргізу кодексі ; Қазақстан Республикасының қылмыстық іс жүргізу кодексі ;Қазақстан Республикасының Әкімшілік құқық бұзушылық туралы Кодексі .",
                two:              "Коронавирустық инфекция індеті пандемия деп жарияланғаннан кейін және барлық жерде қатаң карантин енгізілгеннен кейін халықаралық ұйымдар үкіметтерді соттардың жұмысына сенімді сандық қол жетімділікті қамтамасыз етуге шақырды. Мәселен, халықаралық заңгерлер комиссиясы 2020 жылғы 6 сәуірдегі өз ұсынымдарында  төтенше жағдай кезінде де соттардың жұмысын және халықтың отырыстарға қол жеткізуін сақтау маңызды екенін, ал елдер азаматтар карантиннің негізсіз енгізілуіне шағымдана алатындай тетіктер жасауы керек екенін атап өтті.",
                three:            "БҰҰ Адам құқықтары жөніндегі Жоғарғы комиссарының басқармасы төтенше жағдай  кезінде сот жүйесінің жұмысындағы қиындықтар туралы мәлімдемесінде елдер өз азаматтарына өтініштер мен шағымдарды сандық түрде беру мүмкіндігін қамтамасыз етуі керек деп сендірді.",
                four:             "2020 жылдың қараша айында Халықаралық Сот сот процестерінде, әсіресе COVID-19  пандемиясының аясында бейнеконференцияларды қолдану туралы жаһандық Нұсқаулық жариялады. Сонымен қатар, құжат бейнеконференция режимінде өткізілетін рәсімдерге қоғамдық қол жетімділікті қамтамасыз ету қажеттілігін атап өтті және сандық жағдайда адвокат құқығын қамтамасыз ету туралы пікірлер айтылды.",
            },
            ep2020:          {
                pageTitle1:       "2020 жылғы ",
                pageTitle2:       "Қазақстандағы жағдай",

                one:              "Қазақстанда елдің барлық аумағында төтенше жағдай және карантин режимі тарихта алғаш рет енгізілді: 16 наурызда президент Қасым-Жомарт Тоқаев тиісті Жарлыққа қол қойды. Сол күні Қазақстан Республикасының Жоғарғы Соты «ТЖ аясында республика соттарының жұмыс тәртібі туралы» баспасөз-релизін шығарды .",
                two:              "Сот процестерін IT-технологияларды (оның ішінде «TrueConf» мобильді қосымшасын) пайдалана отырып, қашықтықтан жүргізу ұсынылғаны хабарланды. Сот төрелігіне физикалық қол жетімділіктің болмауы әділ сот ісін жүргізу құқығын шектеді, ал Жоғарғы Соттың ұсыныстары әрдайым орындалмайды: іс жүзінде процестер технология мен байланыстың сапасына қарамастан кез-келген мессенджерлерде жүзеге асырылады.",
                three:            "Азаматтық іс жүргізу кодексінің 11-1 бап «электрондық сот ісін жүргізудің ерекшеліктері», бірақ қылмыстық және әкімшілік кодекстерде тиісті тараулар жоқ. Сот процестерін жүргізуді регламенттейтін жалғыз құжат «07.07.2018 жылғы бейнеконференцбайланыс құралдарын техникалық қолдану қағидаларын бекіту туралы» Қазақстан Республикасы Жоғарғы Сотының жанындағы Соттардың қызметін қамтамасыз ету департаменті басшысының бұйрығы болып табылады .Бұйрықта «осы бейнеконференцбайланыс құралдарын техникалық қолдану қағидалары Қазақстан Республикасы Қылмыстық іс жүргізу кодексінің 370-бабына, Қазақстан Республикасы Әкімшілік құқық бұзушылық туралы Кодексінің 767-бабына сәйкес әзірленді» деп көрсетіледі, алайда, екі бап тек куәгерлерден жауап алуды реттейді және бейнеконференцбайланысты толық қолданудың құқықтық негізі бола алмайды.",
                four:             "Бұдан басқа, елде сот төрелігіне қашықтан қол жеткізуді қамтамасыз етуге мүмкіндік бермейтін техникалық сипаттағы проблемалар да бар. Алматы облысының Карайсай аудандық сотында қаралған екі полицейдің өліміне әкеп соққан блокпостқа шығу туралы іс бойынша резонанстық қылмыстық процесс аса маңызды емес себеппен кейінге қалдырылды. Портал ақпараты бойынша zakon.kz сот процесі сотталушыны бейнеконференцбайланысқа қосудың техникалық мүмкіндігінің болмауына байланысты тоқтатылды, өйткені тергеу изоляторында тек бір компьютер жұмыс істеген .",
                five:             "ТЖ режимі енгізілгеннен кейін онлайн форматта жүргізілген белсенділерге қатысты бірнеше сот процестерін онлайн-сот ісін жүргізу проблемаларын анықтайтын маркерлер деп санауға болады. Интернет желісіне қолжетімділігі бар кез келген адам сот процестерінде: әкімшілік – белсенділер Фариза Оспан мен Арина Осиновскаяға, қылмыстық – Альнұр Ильяшев пен Асия Тулесоваға қатысты «бақылаушы» бола алады. Нәтижесінде, процестер барысында нақты уақыт режимінде қоғамға тараптардың әділ сот талқылауына қолжетімділік деңгейін өз бетінше бағалау мүмкіндігін беруге мүмкіндік беретін жүйе құрылды.",
                six:              "Алайда, әділ сот ісін жүргізу құқығы тұрғысынан бақылаушы рөлінің айқын маңыздылығына қарамастан, бақылаушылар іс жүргізу мәртебесіне ие емес. Бұл сот процесі барысында байқаушының нақты белгіленген құқықтары мен міндеттерінің болмауына әкеп соқтырады, бұл судьяларға онлайн-трансляция жүргізуге тыйым салуға немесе «техникалық» қиындықтарға сілтеме жасай отырып, байқаушыны сот процесіне мүлдем жібермеуге мүмкіндік береді. Жоғарыда аталған сот процестері процесс тараптарының теңдік принциптері сақталмайтындығын тағы бір рет көрсетті, ал техникалық ақауларға байланысты процестің барлық қатысушыларына сөз беру де, журналистер мен басқа да көрермендердің хабар таратуға қол жеткізуін қамтамасыз ету мүмкін емес.",
            },
            recommendations: {
                sideTitleLeft:    "Ұсынымдар",

                pageTitle1:       "Ұсынымдар",

                one:              "Сот талқылауының жариялылығын және оған барлық мүдделі тараптардың қандай да бір шектеулерсіз қол жеткізуін қамтамасыз етуді қоса алғанда, барлық процестік құқықтарды онлайн режимінде қамтамасыз ете отырып, тараптардың сот процесіне қол жеткізуін техникалық қамтамасыз ету бойынша одан әрі жұмыс жүргізу.",
                two:              "Азаматтық сот ісін жүргізу шеңберінде ұйғарымдарды, шешімдерді, атқару парақтарын уақтылы дайындауды және беруді қамтамасыз ету.",
                three:            "Адамның әділ сот талқылауына құқығын қорғауға бағытталған халықаралық міндеттемелер мен оң әлемдік практиканы ескере отырып, төтенше жағдай және карантин режимі кезінде құқықтық жүйенің қызметін реттейтін арнайы заңнаманы әзірлеу және қабылдау;",
                four:             "Қылмыстық және әкімшілік заңнаманың тиісті бөлімдерін процесс тараптарының толық теңдігін қамтамасыз ететін халықаралық стандарттар мен оң әлемдік практикаға негізделе отырып, электрондық сот ісін жүргізу мүмкіндігін толық регламенттейтін тараулармен толықтыру;",
                five:             "«Сот кабинеті» сервисінің техникалық мүмкіндігін Қазақстан Республикасы Азаматтық іс жүргізу кодексінің 28-бабының 1-бөлігі тәртібінде өтініштерді кассациялық сатыға жіберу мүмкіндігімен толықтыру;",
                six:              "Азаматтық, қылмыстық және әкімшілік соттар бойынша онлайн отырыстар өткізілуі мүмкін Мемлекеттік мекемелердің ғимараттарына Интернет желісіне кедергісіз қол жеткізуді ұйымдастырудың техникалық мүмкіндігінің сәйкестігі мәніне және соның салдарынан онлайн режимінде сот әділдігіне қол жеткізу мәніне мониторинг жүргізу.",
            },
        },
    },
}
