export const resources = [
  "https://web.archive.org/web/20131227074032/",
  "http:/www.coe.int/t/dghl/standardsetting/dataprotection/Data_protection_day_en.asp",

  "https://www.coe.int/en/web/data-protection/data-protection-day#%7B%2220421407%22:[1]%7D",
  "https://freedomhouse.org/report/freedom-net/2019/crisis-social-media"
  , "https://www.wired.com/2006/05/the-eternal-value-of-privacy/",
  "https://ico.org.uk/for-organisations/guide-to-data-protection/guide-to-the-general-data-protection-regulation-gdpr/key-definitions/what-is-personal-data/",
  "https://www.un.org/ru/universal-declaration-human-rights/index.html",
  "https://undocs.org/A/RES/68/167\n" +
  "https://www.ohchr.org/en/issues/digitalage/pages/digitalageindex.aspx",
  "https://www.un.org/en/ga/search/view_doc.asp?symbol=A/HRC/28/39\n" +
  "https://undocs.org/en/A/RES/69/166\n" +
  "https://documents-dds-ny.un.org/doc/UNDOC/GEN/G15/068/78/PDF/G1506878.pdf?OpenElement",
  "https://www.un.org/ru/documents/decl_conv/conventions/pactpol.shtml ",
  "https://www.echr.coe.int/documents/convention_rus.pdf\n" +
  "https://www.europarl.europa.eu/charter/default_en.htm",
  "https://www.precisesecurity.com/articles/Top-10-Countries-by-Number-of-CCTV-Cameras",
  "https://www.soros.kz/wp-content/uploads/2020/04/Personal_data_report.pdf",
  "http://adilet.zan.kz/rus/docs/Z1300000094",
  "https://www.inform.kz/ru/zaschitu-personal-nyh-dannyh-usilyat-v-kazahstane_a3660626\n" +
  "https://online.zakon.kz/Document/?doc_id=38319324",
  "https://digital.report/zakonodatelstvo-kazahstana-personalnye-dannye-i-ih-zashchita/",
  "https://liter.kz/dlya-zashhity-personalnyh-dannyh-grazhd/",
  "https://www.soros.kz/wp-content/uploads/2020/04/Personal_data_report.pdf",
  "https://www.soros.kz/ru/personal-data-protection-in-kz/",
  "https://www.soros.kz/ru/personal-data-protection-in-kz/",

  "https://forbes.kz/process/technologies/bolshoy_brat_po-kazahski_1582187734.",
  "https://www.soros.kz/wp-content/uploads/2020/04/Personal_data_report.pdf\n" +
  "Ответ Министерства цифрового развития, инноваций и аэрокосмической промышленности РК на обращение № ЗТ-А-653 от 6 декабря 2020 года через портал egov.kz",
  "Составлено автором на основе данных Министерства цифрового развития, инноваций и аэрокосмической промышленности РК на обращение № ЗТ-А-653 от 6 декабря 2020 года",
  "https://online.zakon.kz/document/?doc_id=30615266",
  "Ответ Министерства цифрового развития, инноваций и аэрокосмической промышленности РК на обращение № ЗТ-А-653 от 6 декабря 2020 года через портал egov.kz",
  "https://undocs.org/ru/A/RES/59%28I%29",
  "https://www.un.org/ru/documents/decl_conv/conventions/pactpol.shtml http://hrlibrary.umn.edu/russian/gencomm/Rhrcom34.html",
  "http://cyberpeace.org.ua/files/ii_a_5.pdf\n" +
  "https://undocs.org/pdf?symbol=ru/A/HRC/RES/20/8 ",
  "https://www.osce.org/files/f/documents/f/2/102323.pdf",
  "http://adilet.zan.kz/rus/docs/Z990000451",
  "http://adilet.zan.kz/rus/docs/Z2000000347",
  "http://adilet.zan.kz/rus/docs/K1400000235\n" +
  "http://adilet.zan.kz/rus/docs/K1400000226",
  "http://adilet.zan.kz/rus/docs/P2000000183/info",
  "http://adilet.zan.kz/rus/docs/Z1500000418",
  "https://ratel.kz/scandal/politsija_zaderzhala_zhurnalistov_ratelkz_i_forbes_kazakhstan ",
  "http://www.adilsoz.kz/news/show/id/2642",
  "http://adilet.zan.kz/rus/docs/Z040000567_",
  "https://vlast.kz/novosti/40475-v-kazahstane-dekriminalizirovali-klevetu.html\n" +
  "http://adilet.zan.kz/rus/docs/K1400000235",
  "https://qamqor.gov.kz/portal/page/portal/POPageGroup/Services/Pravstat",
  "https://rus.azattyq.org/a/30685818.html\n" +
  "http://www.adilsoz.kz/monitoring/show/id/218",
  "http://www.ratel.kz/scandal/zhurnalista_zastavili_molchat\n" +
  "http://www.adilsoz.kz/monitoring/show/id/218",
  "https://rsf.org/en/ranking\n" +
  "https://www.azattyq.org/a/freedom-house-report/30892934.html",
  "https://ria.ru/20151201/1333655718.html",
  "https://digital.ac.gov.ru/news/4904/",
  "https://www.rbc.ru/technology_and_media/29/06/2020/5efa33dd9a79474a8a515f7a",
  "https://ifkz.org/post/61",
  "https://www.un.org/ru/documents/decl_conv/conventions/pactpol.shtml",
  "https://www.un.org/ru/documents/decl_conv/declarations/declhr.shtml\n" +
  "http://adilet.zan.kz/rus/docs/Z050000091_",
  "http://www.itu.int/net/wsis/outcome/booklet/tunisru.html\n" +
  "http://www.itu.int/net/wsis/outcome/booklet/tunis-agenda_Cru.html",
  "https://digitallibrary.un.org/record/706331/files/A_HRC_17_27-RU.pdf",
  "http://www.akorda.kz/ru/official_documents/constitution\n" +
  "http://adilet.zan.kz/rus/docs/Z040000567\n" +
  "http://adilet.zan.kz/rus/docs/Z1500000418\n" +
  "http://adilet.zan.kz/rus/docs/Z990000451_",
  "http://adilet.zan.kz/rus/docs/V1800016782",
  "http://adilet.zan.kz/rus/docs/V1800016781",
  "http://adilet.zan.kz/rus/docs/V1800016654",
  "http://adilet.zan.kz/rus/docs/Z1500000401",
  "http://adilet.zan.kz/rus/docs/P1800000679",
  "https://www.gov.kz/memleket/entities/mdai/press/news/details/132113?lang=ru",
  "https://www.businessinsider.com/apple-google-mozilla-block-kazakhstan-governments-browser-spying-tool-2019-8",
  "https://www.youtube.com/watch?v=8Nk8f0kHS1Q&feature=youtu.be",
  "https://uchet.kz/news/informatsionnye-sistemy-komiteta-gosdokhodov-vnov-vne-dostupa/\n" +
  "https://www.kp.kz/daily/21712096/4334559/\n" +
  "https://www.zakon.kz/5050166-kazahstantsam-nedostupen-portal-egov.html",
  "https://www.un.org/ru/documents/decl_conv/declarations/declhr.shtml\n" +
  "https://www.un.org/ru/documents/decl_conv/conventions/pactpol.shtml\n" +
  "https://www.un.org/ru/documents/decl_conv/conventions/corruption.shtml\n" +
  "https://www.un.org/ru/documents/decl_conv/conventions/orhus.shtml",
  "https://www.rti-rating.org/\n" +
  "https://www.internationalbudget.org/open-budget-survey\n" +
  "https://www.opengovpartnership.org\n" +
  "https://www.coe.int/en/web/greco",
  "http://adilet.zan.kz/rus/docs/Z1500000401/z150401.htm\n" +
  "http://adilet.zan.kz/rus/docs/Z990000451_\n" +
  "http://adilet.zan.kz/rus/docs/K070000212_\n" +
  "http://adilet.zan.kz/rus/docs/K080000095_\n" +
  "http://adilet.zan.kz/rus/docs/Z980000326_\n" +
  "http://adilet.zan.kz/rus/docs/K1700000125",
  "http://adilet.zan.kz/rus/docs/P1500001196",
  "http://adilet.zan.kz/rus/docs/P1500001196",
  "http://adilet.zan.kz/rus/docs/K1400000235",
  "https://www.rti-rating.org",
  "https://esquire.kz/potchemu-zasekretchena-informatsiya-o-rashodovanii-byudzheta/\n" +
  "https://informburo.kz/novosti/tokaev-borba-s-koronavirusom-oboshlas-kazahstanu-pochti-v-6-trln-tenge--106068.html",
  "https://neonomad.kz/u-gosudarstva-sereznye-problemy-so-sluhom-kazahstanskie-zhurnalisty-obratilis-k-tokaevu/",
  "https://lmc.kz/kk/node/54077",
  "http://www.goszakup.gov.kz",
  "https://budget.egov.kz\n" +
  "https://stat.gov.kz\n" +
  "https://www.gov.kz/memleket/entities/esep?lang=ru\n" +
  "https://www.gov.kz/memleket/entities/minfin?lang=ru",
  "www.publicbudget.kz\n" +
  "http://openbudget.kz/\n" +
  "https://nedra.echo.kz/",
  "http://tikazakhstan.org/v-indekse-vospriyatiya-korruptsii-kazahstan-podnyalsya-na-19-pozitsij-nabrav-vpervye-38-ballov/?fbclid=IwAR27ybkY3_MlFsDK0kZxDQmndAhTctv08i7VxyQD-HmehlLiwcNduj3iUHs",

  "https://publicadministration.un.org/publications/content/pdfs/un%20e-government%20survey%202018%20russian.pdf",
  "https://ega.ee/wp-content/uploads/2017/10/ega_e-demcyber_FINAL_web.pdf",
  "http://hrlibrary.umn.edu/russian/euro/Rz17euroco.html",
  "https://www.un.org/ru/documents/decl_conv/conventions/pactpol.shtml",
  "https://www.un.org/ru/documents/decl_conv/conventions/pactecon.shtml",
  "https://www.un.org/ru/documents/decl_conv/conventions/elect_com.shtml",
  "https://undocs.org/A/HRC/32/L.20",
  "blicadministration.un.org/egovkb/Portals/egovkb/Documents/un/2020-Survey/2020%20UN%20E-Government%20Survey%20-%20Russian.pdf",
  "https://egov.kz/cms/ru\n" +
  "https://egov.kz/cms/ru/eparticipation",
  "https://articlekz.com/article/13436",
  "https://open.egov.kz",
  "https://data.egov.kz/#3",
  "https://legalacts.egov.kz",
  "https://dialog.egov.kz",
  "https://budget.egov.kz",
  "https://goszakup.gov.kz/ru/",
  "https://www.nitec.kz/index.php/post/portal-elektronnogo-pravitelstva",
  "https://forbes.kz/news/2020/04/04/newsid_222566",
  "https://egov.kz/cms/ru/online-services/for_citizen/vyplata_42500\n" +
  "http://adilet.zan.kz/rus/docs/V2000020985#z3",
  "https://evaluation.egov.kz",
  "https://www.gov.kz/memleket/entities/mdai/press/news/details/132113?lang=ru&fbclid=IwAR3pMxA-pKP87HQAmpW4yJPGjybmEVAPlRANrJSpUD3_fw5LztwKU-QCbwc\n" +
  "https://rus.azattyq.org/a/cyber-training-from-the-knb-kazakhstanis-again-impose-safety-certificates-/30986811.html",
  "https://www.un.org/ru/documents/decl_conv/conventions/pactpol.shtml\n" +
  "https://www.echr.coe.int/Documents/Convention_RUS.pdf",
  "http://adilet.zan.kz/rus/docs/K950001000_\n" +
  "http://adilet.zan.kz/rus/docs/Z000000132_\n" +
  "http://sud.gov.kz/rus/content/kodeks-sudeyskoy-etiki\n" +
  "http://adilet.zan.kz/rus/docs/K1500000377\n" +
  "http://adilet.zan.kz/rus/docs/K1400000231\n" +
  "http://adilet.zan.kz/rus/docs/K1400000235",
  "https://www.icj.org/wp-content/uploads/2020/04/Universal-ICJ-courts-covid-Advocacy-Analysis-brief-2020-RUS.pdf",
  "https://www.ohchr.org/RU/NewsEvents/Pages/DisplayNews.aspx?NewsID=25810&LangID=R",
  "https://www.icj.org/covid-19-icj-publishes-global-guidance-on-the-use-of-videoconferencing-in-judicial-proceedings/?fbclid=IwAR3SBfjyoKNHyVbCZssyGIXgB0V7viKOG8m4TIZhf56AhFe6EZ9FECldXnY",
  "http://sud.gov.kz/rus/news/o-rezhime-raboty-sudov-respubliki-v-ramkah-chp",
  "http://adilet.zan.kz/rus/docs/V1800017124",
  "https://www.zakon.kz/5038813-naezd-na-blokpost-v-almaty-nachalsya.html",
  "http://journals.rudn.ru/informatization-education/article/view/22165/ru_RU",
  "https://www.unglobalcompact.org/what-is-gc/mission/principles",
  "https://www.un.org/ru/events/pastevents/pdf/dec_wsis.pdf",
  "http://www.undocs.org/ru/A/HRC/8/5",
  "https://undocs.org/ru/A/HRC/RES/17/4",
  "https://undocs.org/ru/A/HRC/RES/44/15",
  "https://www.ohchr.org/Documents/HRBodies/HRCouncil/WGTransCorp/Session6/OEIGWG_Chair-Rapporteur_second_revised_draft_LBI_on_TNCs_and_OBEs_with_respect_to_Human_Rights.pdf",
  "http://adilet.zan.kz/rus/docs/Z2000000347\n" +
  "http://adilet.zan.kz/rus/docs/V2000021498\n" +
  "http://adilet.zan.kz/rus/docs/V2000021693",
  "http://adilet.zan.kz/rus/docs/Z1500000418",
  "http://adilet.zan.kz/rus/docs/V1800016654",
  "http://adilet.zan.kz/rus/docs/P2000000079\n" +
  "http://adilet.zan.kz/rus/docs/Z2000000382",
  "https://kursiv.kz/news/otraslevye-temy/2020-04/v-kazakhstane-poschitali-polzovateley-interneta\n" +
  "https://datareportal.com/reports/digital-2020-kazakhstan",
  "См. подробнее в разделе «Электронное участие»\n" +
  "https://bluescreen.kz/news/v-nur-sultane-obsudili-voprosy-razvitija-cifrovizacii-v-kazahstane-s-jekspertami-mezhdunarodnogo-soveta/\n" +
  "https://forbes.kz/news/2020/09/26/newsid_234332",
  "https://online.zakon.kz/Document/?doc_id=37609220#pos=6;-108\n" +
  "https://kaspi.kz/guide/gold/conditions/q2159/",
  "https://transparencyreport.google.com/user-data/overview?hl=ru&user_requests_report_period=series:requests,accounts;authority:KZ;time:&lu=user_requests_report_period",
  "https://transparencyreport.google.com/government-removals/by-country/KZ?hl=ru",
  "https://profit.kz/news/59092/Apple-Google-Microsoft-i-Mozilla-snova-zablokirovali-kazahstanskij-sertifikat-bezopasnosti/",


]
export const resourcesTitle = {
  ru:  "ссылки  на источники",
  eng: "Sources",
  kaz: "ДЕРЕКТЕР КӨЗІНЕ СІЛТЕМЕЛЕР",
}
