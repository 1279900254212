import React, {Suspense, useEffect}          from 'react';
import {Redirect, Route, Switch, useHistory} from 'react-router-dom';
import AboutMenu                             from './about';
import DigitalRightsMenu                     from './digital-rights';
import ElectronicParticipationMenu           from './electronic-participation';
import NewCallsMenu                          from './new-calls';
import FreedomExpressionMenu                 from './freedom-expression';
import RightsToFairTrialMenu                 from './rights-to-fair-trial';
import InfoAccessMenu                        from './info-access';
import LimitOnInternetMenu                   from './limit-on-internet';
import GoBack                                from '../../components/goBack';
import HomePage                              from '../home-page';


function MenuPage({navData, goToPage, selectLang, lang}) {


    function truncateString(str) {
        if (str.length > 200) {
            return str.slice(0, 200) + '...';
        } else {
            return str;
        }
    }

    return (
        <div className="container">
            <GoBack lang={lang}/>
            <Switch>
                <Route exact path={'/menu/about'}
                       render={props =>
                           <AboutMenu
                               truncateString={truncateString}
                               navData={navData}
                               goToPage={goToPage}
                               selectLang={selectLang}
                               lang={lang} {...props}
                           />}
                />
                <Route exact path={'/menu/digital-rights'}
                       render={props =>
                           <DigitalRightsMenu
                               truncateString={truncateString}
                               navData={navData}
                               goToPage={goToPage}
                               selectLang={selectLang}
                               lang={lang}{...props}
                           />}
                />
                <Route exact path={'/menu/electronic-participation'}
                       render={props =>
                           <ElectronicParticipationMenu
                               truncateString={truncateString}
                               navData={navData}
                               goToPage={goToPage}
                               selectLang={selectLang}
                               lang={lang} {...props}
                           />}
                />
                <Route exact path={'/menu/new-calls'}
                       render={props =>
                           <NewCallsMenu
                               goToPage={goToPage}
                               truncateString={truncateString}

                               selectLang={selectLang}
                               navData={navData}
                               lang={lang}
                               {...props}
                           />}
                />
                <Route exact path={'/menu/freedom-expression'}
                       render={props =>
                           <FreedomExpressionMenu
                               truncateString={truncateString}
                               goToPage={goToPage}
                               selectLang={selectLang}
                               navData={navData}
                               lang={lang}
                               {...props}
                           />}
                />
                <Route exact path={'/menu/rights-to-fair-trial'}
                       render={props =>
                           <RightsToFairTrialMenu
                               truncateString={truncateString}
                               goToPage={goToPage}
                               selectLang={selectLang}
                               navData={navData}
                               lang={lang}
                               {...props}
                           />}
                />
                <Route exact path={'/menu/info-access'}
                       render={props =>
                           <InfoAccessMenu
                               truncateString={truncateString}
                               goToPage={goToPage}
                               selectLang={selectLang}
                               navData={navData}
                               lang={lang}
                               {...props}
                           />}
                />
                <Route exact path={'/menu/limit-on-internet'}
                       render={props => <LimitOnInternetMenu
                           truncateString={truncateString}
                           goToPage={goToPage}
                           selectLang={selectLang}
                           navData={navData}
                           lang={lang}
                           {...props}
                       />}
                />
                <Redirect to={'/not-found'}/>
            </Switch>

        </div>
    );
}

export default MenuPage;
