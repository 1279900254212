import React, {useEffect, useState} from 'react';
import listImg                      from '../../assets/images/electronic-participation/list icon.svg';
import square1Img                   from '../../assets/images/freedom-expression/squareImg1.svg';
import {ia}                         from '../../utils/info-access';

function IaRecommendations({lang}) {
    const [data, setData] = useState(ia.infoAccess.lang.ru.recommendations)

    useEffect(() => {
        window.scrollTo(0, 0);
        if (lang === 'ru') {
            setData(ia.infoAccess.lang.ru.recommendations)
        }
        if (lang === 'eng') {
            setData(ia.infoAccess.lang.eng.recommendations)
        }
        if (lang === 'kaz') {
            setData(ia.infoAccess.lang.kaz.recommendations)
        }
    }, [lang])
    return (
        <div className="ia-container ia-recommendations">
            <div className="text-body">
                <span className={'side-title left'}>{data.sideTitleLeft}</span>
                <h1>{data.pageTitle1}</h1>
                <h3>{data.smallDesc1}</h3>
                <div className={'box left-text first-text'}>
                    <span className="side-number">1</span>
                    <p><img className="right-side-img listImg" src={listImg} alt="person avatar"/>{data.listOne.one}</p>
                </div>
                <div className={'box right-text second-text'}>
                    <span className={'side-number'}>2</span>
                    <p>{data.listOne.two}</p>
                </div>
                <div className={'box left-text third-text'}>
                    <span className={'side-number'}>3</span>
                    <p>{data.listOne.three}</p>
                </div>
                <div className={'box right-text fourth-text'}>
                    <span className={'side-number'}>4</span>
                    <p>{data.listOne.four}</p>
                </div>
                <div className={'box left-text fifth-text'}>
                    <span className={'side-number'}>5</span>
                    <p>{data.listOne.five}
                    </p>
                </div>
                <div className={'box right-text sixth-text'}>
                    <span className={'side-number'}>6</span>
                    <p>{data.listOne.six}
                    </p>
                </div>
                {/*  */}

                <div className={'box left-text text7'}>
                    <span className={'side-number'}>7</span>
                    <p>{data.listOne.seven}
                    </p>
                </div>
                <div className={'box right-text text8'}>
                    <span className={'side-number'}>8</span>
                    <p>{data.listOne.eight}
                    </p>
                </div>
                <div className={'box left-text text9'}>
                    <span className={'side-number'}>9</span>
                    <p>{data.listOne.nine}
                    </p>
                </div>
                <div className={'box right-text text11'}>
                    <span className={'side-number'}>10</span>
                    <p>{data.listOne.ten}
                    </p>
                </div>
                <div className={'box left-text text11'}>
                    <span className={'side-number'}>11</span>
                    <p>{data.listOne.eleven}
                    </p>
                </div>
                <div className={'box right-text text12'}>
                    <span className={'side-number'}>12</span><p>
                    {data.listOne.twelve}
                </p>
                </div>
                <div className={'box left-text text13'}>
                    <span className={'side-number'}>13</span>
                    <p>{data.listOne.thirteen}
                    </p>
                </div>
                <h3>{data.smallDesc2} </h3>
                <div className={'box right-text first-text'}>
                    <span className="side-number">1</span>
                    <p>{data.listTwo.one}</p>
                </div>
                <div className={'box left-text second-text'}>
                    <span className={'side-number'}>2</span>
                    <p>{data.listTwo.two}</p>
                </div>
                <div className={'box right-text third-text'}>
                    <span className={'side-number'}>3</span>
                    <p>{data.listTwo.three}</p>
                </div>
                <div className={'box left-text fourth-text'}>
                    <span className={'side-number'}>4</span>
                    <p>{data.listTwo.four}</p>
                </div>
                <div className={'box right-text fifth-text'}>
                    <span className={'side-number'}>5</span>
                    <p>{data.listTwo.five}
                    </p>
                </div>
                <div className={'box left-text sixth-text'}>
                    <span className={'side-number'}>6</span>
                    <p>{data.listTwo.six}
                    </p>
                </div>
                {/*  */}

                <div className={'box right-text text7'}>
                    <span className={'side-number'}>7</span>
                    <p>{data.listTwo.seven}
                    </p>
                </div>
                <div className={'box left-text text8'}>
                    <span className={'side-number'}>8</span>
                    <p>{data.listTwo.eight}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default IaRecommendations;
