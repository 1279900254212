export const ia = {
  infoAccess: {
    lang: {
      ru:  {
        entrance:        {
          sideTitleLeft:    "доступ к",
          sideTitleRight:   "информации",
          sideTitleMidLeft: "",

          pageTitle1: "ВВЕДЕНИЕ",
          pageTitle2: "",

          one: "Первый закон о праве на доступ к информации появился в 1766 году в Швеции и почти двести лет был единственным подобным документом в мире. Он позволял национальному парламенту получать доступ к информации от короля. В 1951 году закон о доступе к информации стал действовать в Финляндии. Сегодня более сотни стран приняли нормы национального права, регулирующие процессы в этой сфере.",
          two: "Право на информацию (Right of Access to information, RTI) является одним из базовых прав человека. Оно описано в международном законодательстве как право, которое относится в свободе самовыражения, необходимое для укрепления демократии и общественного участия; как антикоррупционный инструмент, а также как важный инструмент защиты окружающей среды. Основными преимуществами реализации этого права являются улучшение качества государственного управления и инвестиционного климата, снижение коррупции, активизация гражданского общества и усиление его роли в принятии решений. \n ",

        },
        international:   {
          sideTitleLeft:    "МЕЖДУНАРОДНЫЕ",
          sideTitleRight:   "СТАНДАРТЫ",
          sideTitleMidLeft: "",

          pageTitle1: "МЕЖДУНАРОДНЫЕ",
          pageTitle2: "СТАНДАРТЫ",

          one:   "Базовые нормы доступа к информации содержатся в:\n" +
                   "●\tст.10 Всеобщей декларации прав человека ; \n" +
                   "●\tст. 19 Международного пакта о гражданских и политических правах (МПГПП) ;\n" +
                   "●\tст. 13 Конвенции ООН против коррупции ; \n" +
                   "●\tОрхусской конвенции ; \n" +
                   "●\tдокументах ООН, ОБСЕ, ЮНЕСКО, Конвенции Совета Европы о доступе к официальным документам, решениях Европейского суда по правам человека, резолюциях Комитета и Парламентской ассамблеи Совета Европы.",
          two:   "Главным принципом считается принцип максимальной открытости информации, от которого можно отступить в очень ограниченных случаях: они должны быть четко и узко сформулированы.",
          three: "В директивном замечании Комитета ООН по правам человека сказано, что «ст. 19 Пакта гарантирует право на информацию, находящуюся в распоряжении государственных органов. Он требует, чтобы государства в приоритетном порядке распространяли информацию, имеющую общественный интерес, и что доступ к ней должен быть \"легким, быстрым, эффективным и практическим\"». В замечании также сказано, что государства должны учредить «необходимые процедуры»: например, законодательство для реализации права на информацию; плата за доступ к информации должна быть ограничена в размерах, отвечать на запросы следует своевременно, органы власти должны обосновывать отказы в предоставлении информации, а государствам следует создать механизмы обжалования отказов.",
          four:  "Конвенция ООН против коррупции требует, чтобы государства принимали надлежащие меры для обеспечения населения эффективным доступом к информации, и обязывает защищать информаторов (whistle-blower).",
          five:  "Выполнение стандартов накладывает два обязательства на правительства и государственные органы. Во-первых, обязанность обнародовать ключевую информацию о мероприятиях, осуществляемых государственными органами; во-вторых, обязанность отвечать на запросы людей и предоставлять доступ к документам.",
          six:   "Основные принципы базовых законов о доступе к информации следующие: \n" +
                   "●\tПраво на доступ – право физического лица, организации или юридического лица на возможность запрашивать информацию у государственных органов без правового обоснования своего к ней интереса; \n" +
                   "●\tОбязанность предоставлять информацию – обязанность государственных органов отвечать на запросы и делиться информацией. Это включает механизмы работы с запросами и установление предельных сроков ответа; \n" +
                   "●\tИсключения позволяют не раскрывать определенные категории информации. Обычно для этого требуется, чтобы до засекречивания информации было указано, какой вред ее раскрытие может нанести;\n" +
                   "●\tВнутреннее обжалование – механизмы, позволяющие заявителю оспаривать отказы в предоставлении информации; \n" +
                   "●\tВнешнее обжалование и надзор – рассмотрение жалоб независимым органом;\n" +
                   "●\tПроактивная публикация – требование к органам власти проактивно публиковать информацию о своей деятельности;\n" +
                   "●\tСанкции – наказание для должностных лиц за незаконное уничтожение или изменение информации либо за незаконный отказ в ее предоставлении.",
          seven: "Надо отметить, что в последние годы проактивный доступ к информации обеспечивается такими способами как открытые данные и электронные правительства.",
          eight: "Право на доступ к информации, как и право на свободу выражения мнений, не является абсолютным и может быть ограничено. Так, п. 3 ст. 19 Международного пакта о гражданских и политических правах говорит, что ограничения должны быть установлены законом и являться необходимыми: а) для уважения прав и репутации других лиц; б) для охраны государственной безопасности, общественного порядка, здоровья или нравственности населения. Это можно использовать в качестве теста при определении законности и обоснованности ограничения или нарушения права на свободу информации. Если национальные законы не соответствуют указанным правилам, то они противоречат международным нормам, и в спорных моментах должны применяться международные нормы.",
          nine:  "Существуют различные рейтинги, в которых оценивается ситуация с доступом к информации в странах. Наиболее известные – Карта глобального права на информацию   и Обзор открытости бюджета . Также действуют международные партнерства, такие как OGP (Open Government Partnership ), GRECO (Group of States against Corruption ), стимулирующие государства внедрять прогрессивные стандарты доступа к информации.",
          ten:   "В 2015 году предложением ЮНЕСКО 28 сентября был назначен «Международным днем всеобщего доступа к информации». ",

        },
        national:        {
          sideTitleLeft:    "НАЦИОНАЛЬНЫЕ",
          sideTitleRight:   "СТАНДАРТЫ",
          sideTitleMidLeft: "",

          pageTitle1: "НАЦИОНАЛЬНЫЕ",
          pageTitle2: "СТАНДАРТЫ",

          one:      "Право на доступ к информации закреплено в различных национальных документах: \n" +
                      "●\tв ст. 20 Конституции («Каждый имеет право свободно получать и распространять информацию любым, не запрещенным законом способом. Перечень сведений, составляющих государственные секреты Республики Казахстан, определяется законом»)\n" +
                      "●\tв Законе о доступе к информации\n" +
                      "●\tв Законе о СМИ\n" +
                      "●\tв Экологическом кодексе (гл. 21)\n" +
                      "●\tв Бюджетном кодексе (принцип транспарентности, п. 4 ст. 4)\n" +
                      "●\tв Законе об архивах\n" +
                      "●\tв Законе о недрах и недропользовании (ст.7)",
          two:      "Закон о доступе к информации был принят в Казахстане в конце 2015 года. Планировалось, что его принятие вместе с Законами об общественных советах и противодействии коррупции даст толчок серьезным общественным реформам и положительно скажется на качестве государственного управления, а объем открытой информации государственных органов увеличится с 40% до 70%.",
          three:    "К прогрессивным пунктам закона можно отнести следующее: \n" +
                      "●\tСлужебная информация теперь не относится к информации с ограниченным доступом, что формально делает доступным большой массив документов госорганов;\n" +
                      "●\tКвазигосударственные органы (такие как «Самрук-Казына», КТЖ и другие с госучастием) относятся к обладателям информации и обязаны предоставлять ее наряду с госорганами;\n" +
                      "●\tЧетко регламентируется порядок обращений на сайте «электронное правительство»; граждане могут получать информацию через eGov, что значительно упрощает процедуру и экономит время.",
          four:     "Закон делит информацию на три категории: общедоступную информацию, информацию, доступ к которой не может быть ограничен, и информацию с ограниченным доступом. К последней относятся государственные секреты, тайны (личная, банковская, усыновления, налоговая, врачебная, коммерческая) и служебная информация с пометкой «для служебного пользования» . Последний пункт вызывает много нареканий, споров и несогласий.",
          five:     "Так, в «Правилах отнесения сведений к служебной информации ограниченного распространения и работы с ней»  устанавливается право руководителей государственных органов самостоятельно относить те или иные сведения к категории «для служебного пользования». При этом Правила позволяют широко толковать критерии информации ограниченного распространения. Более того, список критериев не является закрытым, то есть у исполнителя есть возможность ограничивать доступ к информации, если она, по мнению исполнителя, может быть использована в ущерб интересам госоргана. Таким образом, эти нормы создают потенциал для незаконного сокрытия общественно значимой информации.",
          six:      "Кроме того, в законе содержится принципиальное противоречие, сводящее на «нет» многие пункты закона и гражданские инициативы. Так, ст. 6 гласит, что к информации, не подлежащей ограничению, относится информация о формировании и расходовании средств из республиканского и местного бюджетов, за исключением сведений, содержащих государственные секреты. В то же время в ст. 3 сказано: «Настоящий Закон действует на территории Республики Казахстан и распространяется на общественные отношения, связанные с доступом к информации, не относящейся к информации с ограниченным доступом». То есть секреты, тайны и информация ДСП автоматически исключается из сферы действия закона.",
          seven:    "Другая проблема – это неисполнение закона государственными органами, которые нередко путают закон о доступе к информации и закон об обращениях физических и юридических лиц. Это происходит из-за схожих понятий и нечеткого понимания, чем различаются запросы и как на них реагировать. Основные претензии к госорганам – ответы не в срок, игнорирование запросов, предоставление неполных ответов, отсылки и отписки. Закон не предусматривает подачу анонимного запроса, что также является гипотетическим препятствием для реализации прав граждан.",
          eight:    "Кроме того, закон не предусматривает единый формат опубликования информации, поэтому этот вопрос остается на совести каждого госоргана. К примеру, на интернет-ресурсах раскрывается информация общего характера, но практически нет данных о проведении проверок, или размещается план проверок, но отсутствуют их результаты. Сведения об оказываемых государственных услугах предоставляются частично, с различной степенью детализации, обычно не по всем услугам и не в простом и понятном виде.",
          nine:     "Санкции за нарушения предусмотрены ст. 456-1 «Незаконное ограничение права на доступ к информации» КоАП РК . Ответственность может наступить за:\n" +
                      "●\tнеправомерный отказ в предоставлении информации либо предоставление заведомо ложной информации в случаях, когда такая информация подлежит предоставлению по запросу пользователя информации;\n" +
                      "●\tразмещение заведомо ложной информации в СМИ, на интернет-ресурсе обладателя информации, на интернет-портале открытых данных или иными способами, предусмотренными законодательством Республики Казахстан;\n" +
                      "●\tнеправомерное отнесение информации, не являющейся информацией с ограниченным доступом, к информации с ограниченным доступом.",
          ten:      "Штрафы за нарушения для должностных лиц, субъектов предпринимательства, некоммерческих организаций варьируются от 30 до 200 МРП. Однако в судебном порядке добиться справедливости сложно: по состоянию на 2016 год было зарегистрировано и рассмотрено всего одно дело, связанное с отказом в доступе к информации. В 2018-2019 гг. количество судебных исков приблизилось к десяти. В основном иски связаны с отказом местных исполнительных органов предоставить информацию экологического характера, а также о расходовании бюджетных средств. В большинстве случаев суды выносили решения с минимальными штрафами должностным лицам – 30 МРП.",
          eleven:   "Существуют и внесудебные механизмы защиты права на информацию. Так, для «учета и защиты общественных интересов в области доступа к информации, а также удовлетворения потребностей пользователей информации» была создана Комиссия по вопросам доступа к информации. Организованная при Министерстве информации, Комиссия является консультативно-совещательным органом и не обладает необходимыми полномочиями надзора за исполнением закона (в ее компетенцию входит лишь выработка рекомендаций, но не входит рассмотрение жалоб).",
          twelve:   "Казахстан является участником Инициативы Прозрачности в Добывающих Отраслях (ИПДО) с 2005 года, и большая часть информации в сфере доходов добывающих отраслей с тех пор стала открытой. Недропользователи ежегодно предоставляют отчеты, которые становятся основой для процесса валидации. Функционирует база, в которой собрана информация об отчислениях на социальное развитие и местную инфраструктуру всех недропользователей, их налогах и доходах, квазифискальных расходах и т.п. Однако до сих пор не решен вопрос раскрытия информации о бенефициарных владельцах, нет определения термина «политически значимое лицо», непонятна политика правительства в области раскрытия контрактов. Это создает препятствия для опубликования необходимой по стандартам ИПДО информации.",
          thirteen: "Глобальный рейтинг законов о доступе к информации The Global Right to Information Rating (RTI Rating)  оценивает законодательство Казахстана в 61 балл из 150 возможных. Страна набирает баллы по таким показателям, как объем прав на доступ и наличие процедур в отношении запросов об информации, но заметно теряет в сферах установления правовых рамок доступа к информации, объема исключений и возможностей для отказа в информации, процедур обжалования, санкций за неисполнение законодательства и мер по просвещению и продвижению права на информацию.\n",

        },
        ep2020:          {
          pageTitle1: "СИТУАЦИЯ ЗА 2020 ГОД",
          pageTitle2: "В КАЗАХСТАНЕ",

          one:      "Текущее положение дел обострилось всемирной волной пандемии, показавшей, что государственные органы не готовы в полной мере справляться с новыми вызовами. Так, значительно увеличилось количество жалоб от журналистов на непредоставление информации. В связи с переходом на онлайн формат возникли проблемы с участием СМИ в совещаниях, судебных онлайн-процессах, и в целом с грамотной организацией оперативного информирования общественности о ситуации. Практика показала, что государственные органы не владеют навыками антикризисного пиара.",
          two:      "Примером может служить ситуация с расходованием средств на борьбу с коронавирусом , когда Министерство здравоохранения отнесло эти сведения к категории ДСП. Также большой резонанс в обществе вызвала информация о шести триллионах тенге , которые будут потрачены во время пандемии. Никто из чиновников не объяснил публично, из чего сложилась эта сумма, и в итоге заявление вызвало бурные критические обсуждения в обществе, поскольку люди сделали ложные выводы из-за отсутствия объективной официальной информации.",
          three:    "В марте более 160 казахстанских журналистов подписали обращение к Касым-Жомарту Токаеву, в котором выразили возмущение работой Службы центральных коммуникаций и «длительным игнорированием острых вопросов в стране». Они назвали ситуацию с доступом к информации катастрофической.",
          four:     "«Причина такой ситуации – это полное отсутствие коммуникаций между представителями государственных органов и средствами массовой информации. Более того – мы видим абсолютное непонимание самой сути коммуникаций, демонстрируемое представителями государственных органов и особенно – Службы центральных коммуникаций при президенте. Последняя именно в период карантина стала фактически единственной площадкой, на которой в режиме онлайн должен был выстроиться диалог между государством и обществом, посредством средств массовой информации. Однако вместо коммуникаций на протяжении 3,5 месяцев мы наблюдаем лишь сужение информационного поля и непрерывное сокращение важной для общества информации» –говорится в обращении.",
          five:     "Ответ, который получили журналисты, можно назвать типичной отпиской: в нем было перечислено, как много полезных и важных инициатив реализует государство в период пандемии.",
          six:      "В сентябре группа правозащитных организаций и граждан обратилась к президенту и парламенту с требованием пересмотреть действующие правила отнесения информации к категории «для служебного пользования».",
          seven:    "Эксперты отметили, что с момента введения карантина гражданское общество столкнулось с тем, что государственные органы без видимых причин все чаще относят информацию к категории ДСП. При этом, как показывает практика, оспорить действия чиновников невозможно, поскольку на соответствующих приказах также стоит гриф ДСП. Так была засекречена информация о том, какие СМИ получают деньги по линии государственного информационного заказа; о том, каков размер зарплаты депутатов мажилиса парламента. В итоге Министерство информации ответило, что проблема действительно есть, и в ближайшее время будет создана рабочая группа по реформированию законодательства, куда будут приглашены эксперты от гражданского общества.",
          eight:    "Что касается бюджетной информации в целом, то здесь по-прежнему нет единых требований для опубликования. Основные сведения об использовании бюджета можно найти на сайте госзакупок , который в период пандемии стал главным источником бюджетных новостей. Активисты и журналисты публиковали сведения о закупаемых чиновниками услугах, предавая огласке сомнительные закупки и обращая на них внимание Антикоррупционного агентства.",
          nine:     "Данные о бюджете также можно найти на портале eGov (открытые бюджеты) , на сайтах Агентства по статистике , Счетного комитета , Министерства финансов . По статистике в разделе «Открытые бюджеты» портала «Открытое правительство» опубликовано более 13 500 проектов бюджетных программ, однако сделать вывод о степени вовлеченности граждан в процесс обсуждения проектов и отчетов о реализации бюджетных средств сложно: отсутствует статистика посещения. Кроме того, информация обновляется нерегулярно и бессистемно. Например, информация о бюджете Конституционного совета последний раз обновлялась три года назад, а по администрации президента и канцелярии первого президента данные вообще отсутствуют. При этом информацию о расходах этих ведомств можно найти на сайте госзакупок.",
          ten:      "Несколько позитивных сдвигов все же стоит отметить. Во-первых, в последнее время появились общественные инициативы, предоставляющие населению бюджетную информацию в простом и доступном виде: интерактивная карта открытых бюджетов , открытая база данных о госбюджете РК , дата-проект «НЕДРА. ДЕНЬГИ. РЕГИОНЫ».",
          eleven:   "Во-вторых, в конце года были приняты поправки в закон о Доступе к информации, которые коснулись нескольких моментов. В поправках был четко обозначен уполномоченный орган в области доступа к информации (им стало Министерство информации), описаны его функции, полномочия и ответственность. Кроме того, были даны определения понятиям «интернет-портал открытого диалога» и «блог-платформа первых руководителей», а также перечислены компетенции правительства и уполномоченных органов в сфере информации и информатизации.",
          twelve:   "В-третьих, в этом году была детализирована информация, которая должна размещаться на интернет-ресурсах обладателей информации, в частности, на сайтах судов, акиматов, министерств, квазигосударственных органов. К обладателям информации теперь отнесены также исполнители функций центральных и (или) местных исполнительных органов.",
          thirteen: "В 2020 году Казахстан впервые набрал 38 баллов в Индексе восприятия коррупции Transparency International, поднявшись на 19 позиций. Однако эксперты отметили, что «власти Казахстана допускали нарушения демократических свобод, связанных с прозрачностью и доступом к финансовой информации о расходах на здравоохранение, необоснованными ограничениями деятельности журналистов, правозащитников и представителей гражданского общества». ",

        },
        recommendations: {
          sideTitleLeft: "рекомендации",

          pageTitle1: "рекомендации",
          listOne:    {
            one:      "Дать определение «служебной информации ограниченного распространения с пометкой «Для служебного пользования» в Законе «О доступе к информации» с указанием категорий сведений, которые к ней относятся, либо предусмотреть в Административном процедурно-процессуальном кодексе исчерпывающий перечень сведений, которые являются основаниями для отнесения той или иной информации к служебной с пометкой ДСП.",
            two:      "Внести изменения в Закон «О доступе к информации» и разработать подзаконные акты для обеспечения доступа на заседания коллегиальных органов государственной власти.",
            three:    "Пересмотреть статью 456-1 КоАП РК, включив в нее ответственность за нарушение сроков предоставления информации, за предоставление неполной информации, за неразмещение информации, либо размещение неполной информации на интернет-портале, интернет-ресурсе, в помещениях, занимаемых обладателями информации и т.д.",
            four:     "Предусмотреть в законе дополнительные функции Комиссии по вопросам доступа к информации, включая возможность получать и рассматривать жалобы и заявления пользователей информации по фактам нарушения или ограничения права на доступ к информации. Пересмотреть статус Комиссии.",
            five:     "Исключить из Закона «О доступе к информации» запрет на подачу анонимных письменных запросов.",
            six:      "Исключить термин «запрос» из Закона «О порядке рассмотрения обращений физических и юридических лиц».",
            seven:    "Провести анализ законодательства на наличие коллизий, неоднозначности норм, правовой неопределенности.",
            eight:    "Разработать стандарты публикации открытых данных.",
            nine:     "Разработать стандарты публикации информации на сайтах госорганов.",
            ten:      "Продолжать просветительские и образовательные мероприятия для чиновников. Разработать пособие по доступу к информации для госслужащих. Разработать и внедрить внутренние инструкции по предоставлению информации и по стандартам публикации данных.",
            eleven:   "Начать стратегическую наработку административной и судебной практики по отказам в предоставлении информации, проводить анализ этой практики.",
            twelve:   "Создать и/или поддерживать инфраструктуру юридической помощи по вопросам доступа к информации (жалобы, судебные дела, сервисы).",
            thirteen: "Каталогизировать уже действующие инструменты по доступу к информации.",
          },
          listTwo:    {
            one:   "Привести Закон «О доступе к информации» в соответствие с международными стандартами, в частности: закрепить презумпцию открытости информации, «трисоставной тест» для ограничения доступа к информации, приоритет закона о доступе к информации перед любыми другими законами, которые регулируют вопросы информации, исключить автоматическое ограничение доступа к отдельным категориям информации.",
            two:   "Создать эффективный независимый механизм контроля за соблюдением Закона «О доступе к информации», а также создать (определить) в органах власти должностных лиц (подразделения), ответственных за реализацию закона, наделив их достаточными полномочиями и ресурсами. Принять меры по продвижению и популяризации Закона «О доступе к информации», обучению как пользователей, так и обладателей информации.",
            three: "Расширить ответственность за нарушение права на доступ к информации и обеспечить применение действенных санкций за нарушения.",
            four:  "Обеспечить ведение ведомственного учета информационных запросов, хода и результатов их рассмотрения, а также внедрить соответствующий централизованный статистический учет с регулярным опубликованием данных онлайн. Обеспечить подготовку ежегодного национального доклада о состоянии реализации Закона «О доступе к информации» и обеспечении права на доступ к информации в стране.",
            five:  "Обеспечить открытый онлайн-доступ к основным базам данных (реестрам) государственных органов, в частности к реестрам юридических лиц, прав собственности на недвижимое имущество и транспортные средства, в том числе в формате открытых данных.",
            six:   "Отменить уголовную ответственность за оскорбление и другие похожие деяния. В случае временного сохранения такой ответственности перевести ее в разряд уголовных проступков, исключив возможность применения мер наказания, связанных с ограничением или лишением свободы. Отменить квалифицированные составы правонарушений, связанные с распространением информации о возможных коррупционных деяниях.",
            seven: "Обеспечить действенность мер по предотвращению чрезмерных денежных исковых требований к СМИ и журналистам по возмещению морального вреда, в том числе путем ограничения срока исковой давности по такого рода искам до одного года, запрета должностным лицам органов власти и самим государственным учреждениям подавать иски о защите чести и достоинства, распространения размера пошлины, пропорционального сумме иска, на иски, которые подаются в уголовном процессе, проведения регулярного обучения судей по международным стандартам рассмотрения соответствующих дел. Закрепить в нормативном постановлении Верховного суда Республики Казахстан правила рассмотрения дел о защите чести и достоинства, которые соответствуют международным стандартам и рекомендациям.",
            eight: "Присоединиться к международной инициативе «Партнерство Открытое правительство» (Open Government Partnership).\n ",

          },

          smallDesc1: "Нижеперечисленные рекомендации предоставлены государственными органами, НПО, медиа-экспертами, а также международной организацией ICNL (Международный центр некоммерческого права).",
          smallDesc2: "Рекомендация №13 ОECD (Организации экономического сотрудничества и развития): ",

        },
      },
      eng: {
        entrance:        {
          sideTitleLeft:    "access to",
          sideTitleRight:   "information",
          sideTitleMidLeft: "",

          pageTitle1: "Introduction",
          pageTitle2: "",

          one: "The first law on the right to access information appeared in 1766 in Sweden and was the only such document in the world for almost 200 years. It allowed the national Parliament to access information from the King. In 1951, the law on access to information became effective in Finland. Today, more than 100 countries have adopted the norms of national law regulating the processes in this area.",
          two: "The right to information (or RTI) is one of the basic human rights. It is described in international law as a right that relates to freedom of expression, necessary to strengthen democracy and public participation; as an anti-corruption tool, and as an important tool for protecting the environment. The main advantages of exercising this right are improving the quality of public administration, improving the investment climate, reducing corruption, activating civil society and strengthening its role in decision-making. ",

        },
        international:   {
          sideTitleLeft:    "International",
          sideTitleRight:   "standards",
          sideTitleMidLeft: "",

          pageTitle1: "International",
          pageTitle2: "standards",

          one:   "The basic rules for access to information are contained in:\n" +
                   "•\tArt. 10 of the Universal Declaration of Human Right;\n" +
                   "•\tArt. 19 of the International Covenant on Civil and Political Rights (ICCPR);\n" +
                   "•\tArt. 13 of the UN Convention against Corruption;\n" +
                   "•\tin the Aarhus Convention ; \n" +
                   "•\tin documents of the UN, OSCE, UNESCO, the Council of Europe Convention on Access to Official Documents, decisions of the European Court of Human Rights, resolutions of the Committee and the Parliamentary Assembly of the Council of Europe. \n",
          two:   "The main principle is considered to be the principle of maximum transparency of information, which can be deviated from in very limited cases, and they should be clearly and narrowly formulated. ",
          three: "The directive of the UN Human Rights Committee states that Art. 19 of the Covenant guarantees the right to information held by public authorities. It requires states to give priority to the dissemination of information of public interest and that access to it must be easy, fast, effective and practical. The comment also states that States must establish \"necessary procedures\": for example, legislation to implement the right to information; fees for access to information should be limited, requests should be responded to in a timely manner, authorities should justify refusals, and states should establish mechanisms to appeal refusals.",
          four:  "The United Nations Convention against Corruption requires States to take appropriate measures to ensure that the public has effective access to information, and requires the protection of whistleblowers.",
          five:  "The implementation of the standards imposes two obligations on Governments and public authorities. First, the obligation to disclose key information about the activities carried out by public authorities; second, the obligation to respond to people's requests and provide access to documents.",
          six:   "The basic principles of the basic laws on access to information are as follows: Right of access – the right of an individual, organization or legal entity to request information from state authorities without the obligation to legally substantiate their interest in it;\n Duty to provide information – the duty of public authorities to respond to requests and provide information. This includes mechanisms for handling requests and setting deadlines for responding to requests;\n Exceptions allow certain categories of information to not be disclosed. This usually requires that prior to the concealment of information, it is shown what harm its disclosure can cause;\n Internal appeal - mechanisms that allow the applicant to challenge refusals to provide information;\n External review and supervision – review of complaints by an independent body;\n Proactive publication - a requirement for the authorities to proactively (ahead of time) publish information about their activities;\n Sanctions - punishment for officials for illegal destruction or alteration of information, or for illegal refusal to provide it.\n",
          seven: "It should be noted that in recent years, proactive access to information has been provided by such methods as open data and e-government.",
          eight: "It is important to know that the right to access information, like the right to freedom of expression, is not absolute and can be limited. So, paragraph 3 of Art. 19 of the International Covenant on Civil and Political Rights says that restrictions must be established by law and are necessary: a) to respect the rights and reputation of others; b) for the protection of state security, public order, health or morality of the population. This can be used as a test in determining the legality and justification of restricting or violating the right to freedom of information. If national laws do not comply with the specified rules, then they contradict international norms, and international norms should be applied in controversial moments.",
          nine:  "There are various ratings that assess the situation with access to information in countries. The most famous are the Global Right to Information Map  and the Open Budget Survey . There are also international partnerships such as OGP (Open Government Partnership), GRECO (Group of States Against Corruption ), which encourage states to introduce progressive standards of access to information. ",
          ten:   "In 2015, UNESCO designated 28 September as the International Day for Universal Access to Information.",

        },
        national:        {
          sideTitleLeft:    "National",
          sideTitleRight:   "standards",
          sideTitleMidLeft: "",

          pageTitle1: "National",
          pageTitle2: "standards",

          one: "The right to access information is enshrined in various national documents: Article 20 of the Constitution (\"Everyone has the right to freely receive and disseminate information by any means not prohibited by law. The list of information constituting state secrets of the Republic of Kazakhstan shall be determined by law\"); \n in the Law \"On Access to Information\" ;\n in the Law \"On Mass Media\"; in the Environmental Code (Chapter 21); in the Budget Code (the transparency principle, Art. 4, para. 4); in the Law \"On Archives\"; in the Law \"On Subsoil and Subsoil Use\" (Art. 7)  etc.",


          two:      "The Law \"On Access to Information\" was adopted in Kazakhstan at the end of 2015. It was planned that its adoption together with the Laws \"On Public Councils\" and \"On Counteracting Corruption\" would give an impetus to serious public reforms and have a positive impact on the quality of public administration. Initially it was planned that the amount of open information of state bodies would increase from 40% to 70%. ",
          three:    "Progressive clauses of the law include the following: Official information is no longer classified as \"for official use only\", which formally makes a large array of documents of public bodies available;\n Quasi-state authorities (such as JSC \"National Welfare Fund Samruk-Kazyna\", JSC NC \"Kazakhstan Temir Zholy\" and others with state participation) are related to the holders of information and are obliged to provide it along with public authorities;\n The order of appeals on the site \"e-government\" is clearly regulated. Citizens can get information through eGov, which greatly simplifies the procedure and saves time. \n",
          four:     "The law divides information into three categories: publicly available information, information access to which cannot be restricted, and information with restricted access. The latter includes state secrets, secrets (personal, banking, adoption, tax, medical, commercial), official information marked \"for official use only\"  . The last point causes a lot of criticism, disputes and disagreements.",
          five:     "Thus, in the \"Rules of classification of information as official information of restricted distribution and work with it\" establishes the right of heads of state bodies to independently categorize certain information as «for official use only\". At the same time, the Rules establish the possibility of a broad interpretation of the criteria of restricted information and, moreover, the list of criteria is not closed, giving the possibility to limit access to information if they, in the opinion of the executor, may be used to the detriment of the interests of a public authority. These rules, in turn, provide opportunities for the abuse of restrictions and create the potential for illegal concealment of publicly significant information.",
          six:      "In addition, the law contains a fundamental contradiction that negates many points of the law and civil initiatives. Thus, Article 6 stipulates that information not subject to restriction, includes information on the formation and expenditure of funds from the republican and local budgets, with the exception of information containing state secrets. At the same time, Article 3 says: \"This Law is effective in the territory of the Republic of Kazakhstan and applies to public relations related to access to information not related to information with restricted access\". That is, secrets, and information of the \"for official use only\" are automatically excluded from the scope of the law. ",
          seven:    "Another problem is the non-enforcement of the law by government agencies, which often confuse the Law \"On Access to Information\" and the Law \"On the Procedure for Consideration of Appeals of Individuals and Legal Entities\". This is due to similar concepts and a vague understanding of how requests differ and how to respond to them. The main complaints against government agencies - answers not in time, ignoring requests, providing incomplete answers, sending and diverting replies. The law does not provide for the filing of an anonymous request, which is also a hypothetical obstacle to the realization of the rights of citizens. ",
          eight:    "In addition, the law does not provide a uniform format for publishing information, so this issue is left to the conscience of each state body. For example, on Internet resources information of a general nature is disclosed, but almost no information about inspections is disclosed. Or a plan of inspections is placed, but there are no results of the inspections. Information about the public services provided is provided in part, with varying degrees of detail, usually not for all services and not in a simple and clear form. Publication of knowingly false information in the media, on the Internet resource of the holder of information, on the Internet portal of open data or by other means stipulated by the legislation of the Republic of Kazakhstan;\n Unlawful classification of information that is not restricted information as restricted information.\n",
          nine:     "Sanctions for violations are stipulated by Article 456-1 \"Unlawful restriction of the right to access information\" of the Administrative Code of the Republic of Kazakhstan . Liability may arise for: Unlawful refusal to provide information or provision of knowingly false information in cases where such information is to be provided at the request of the user of information;\n",
          ten:      "Penalties for violations for officials, business entities, non-profit organizations range from 30 to 200 MCIs. However, it is difficult to obtain justice in court: as of 2016, only one case related to the denial of access to information was registered and considered. In 2018-2019, the number of lawsuits approached ten. Most of the lawsuits are related to the refusal of local executive bodies to provide information of environmental nature, as well as on the expenditure of budgetary funds. In most cases, the courts issued decisions with minimal fines for officials - 30 MCIs. ",
          eleven:   "There are also extrajudicial mechanisms to protect the right to information. Thus, the Commission on Access to Information was established to \"take into account and protect the public interest in the field of access to information, as well as to meet the needs of information users\". Organized under the Ministry of Information and Social Development, the commission is an advisory body and does not have the necessary authority to supervise the implementation of the law (its competence only includes making recommendations, but not complaints). ",
          twelve:   "Kazakhstan has been a participant in the Extractive Industries Transparency Initiative (EITI) since 2005, and much of the information on extractive industry revenues has since become public. Subsoil users submit annual reports, which become the basis for the validation process. There is a database that contains information on the social development and local infrastructure contributions of all subsoil users, their taxes and revenues, quasi-fiscal expenditures, etc. However, the problem remains the fact that the issue of disclosure of beneficial owners has not been resolved. There is no definition of the term \"politically exposed person\". The government's policy on contract disclosure is unclear. This creates obstacles to publishing the information required by EITI standards. ",
          thirteen: "The Global Right to Information Rating (RTI Rating)  of laws on access to information rates Kazakhstan's legislation 61 points out of 150 possible. The country scores better on indicators such as the scope of rights of access and the existence of procedures for requests for information, but loses noticeably in the areas of establishing the legal framework for access to information, the scope of exceptions and opportunities for denial of information, appeals procedures, sanctions for non-compliance with the law, and measures to educate and promote the right to information.",

        },
        ep2020:          {

          pageTitle1: "Situation in Kazakhstan ",
          pageTitle2: "as of 2020",

          one:      "The current situation has been exacerbated by the worldwide pandemic wave, which has shown that government agencies are not fully prepared to cope with the new challenges. Thus, the number of complaints from journalists on the provision of information has significantly increased. Due to the transition to an online format, there were problems with media participation in meetings, online court processes, and in general with the competent organization of prompt public information about the situation. Practice has shown that government agencies do not have skills of anti-crisis PR. ",
          two:      "An example of this is the situation with coronavirus spendings , when the Ministry of Health categorized the information as \"for official use only\". Also a big resonance in the society caused by the information about six trillion tenge, which will be spent during the pandemic. None of the officials explained publicly what this amount was made of, and as a result, the statement sparked a heated critical discussion in social networks and in society at large, as people drew false conclusions from the lack of objective official information.",
          three:    "In March, more than 160 Kazakhstani journalists signed an appeal to Kassym-Jomart Tokayev, expressing indignation at the work of the Central Communications Service and the \"prolonged neglect of acute issues in the country\". They called the situation with access to information disastrous .",
          four:     "\"The reason for this situation is the complete lack of communication between representatives of government agencies and the media. Moreover, we see a complete misunderstanding of the very essence of communications, as demonstrated by representatives of government agencies, especially the Presidential Central Communications Service. During the quarantine period, the latter was in fact the only platform where an online dialogue between the state and society was to be built through the mass media. But instead of communication for 3.5 months we have seen only a narrowing of the information field and a continuous reduction of information important to society\", reads the appeal. ",
          five:     "The answer that the journalists received was a typical piece of paper, listing the many useful and important initiatives the government is implementing during the pandemic. ",
          six:      "In September, a group of human rights organizations and citizens appealed to the president and parliament to reconsider the current rules for categorizing information as \"for official use only\".",
          seven:    "Experts noted that since the introduction of the quarantine, civil society has faced the fact that government agencies for no apparent reason increasingly categorize information as \"for official use only\". In this case, as practice shows, it is impossible to challenge the actions of officials, since the corresponding orders also have the stamp of the \"for official use only\". This way, the information about which media outlets receive money through the state information order and what are the salaries of the members of the Majilis of the parliament was classified. As a result, the Ministry of Information and Public Development responded that there was indeed a problem, and in the near future a working group will be created to reform the law, which will invite experts from civil society. ",
          eight:    "As for budget information in general, there are still no uniform requirements for publication. Basic information about the use of the budget can be found on the site of state procurement , which became the main source of budget news during the pandemic. Activists and journalists published information about the services purchased by officials, publicizing questionable purchases and bringing them to the attention of the Anti-Corruption Agency. ",
          nine:     "Data on the budget can also be found on the portal egov.kz (open budgets) , on the websites of the Statistics Agency , the Accounts Committee , the Ministry of Finance . According to statistics, more than 13,500 draft budget programs have been published in the Open Budgets section of the Open Government portal, but it is difficult to draw a conclusion about the degree of citizen involvement in the discussion of draft budget programs and reports on the implementation of budget funds, since there are no statistics on visits. In addition, the information is updated irregularly and unsystematically. For example, the Constitutional Council's budget information was last updated three years ago. No data on the Presidential Administration and the Office of the First President at all. Information about the expenditures of these agencies can be found on the state procurement website. ",
          ten:      "Recently, there have been public initiatives that provide the public with budget information in a simple and accessible form: interactive open budgets map , open database of state budget of RK , data-projects «SUBSOIL. MONEY. REGIONS\"  . ",
          eleven:   "At the end of the year, amendments to the Law \"On Access to Information\" were adopted, which addressed several points. First, the authorized body in the area of access to information (the Ministry of Information and Social Development) is clearly defined, and its functions, powers, and responsibilities are described. Second, definitions of the concepts of \"open dialogue Internet portal\" and \"blogging platform of the first leaders\" are given\". Third, the competencies of the government, and authorized bodies in the field of information and informatization are listed. ",
          twelve:   "Also detailed is the information that must be placed on the Internet resources of information holders. In particular, the lists of categories of information to be placed on the websites of courts, akimats, ministries, quasi-state bodies are defined. Holders of information now include also executors of functions of central and (or) local executive bodies.",
          thirteen: "In 2020, Kazakhstan scored 38 points on Transparency International's Corruption Perception Index for the first time, climbing 19 positions. At the same time, the experts noted that \"the Kazakhstani authorities allowed violations of democratic freedoms related to transparency and access to financial information on health care expenditures, unreasonable restrictions on the activities of journalists, human rights defenders and civil society representatives\". ",

        },
        recommendations: {
          sideTitleLeft:    "Recommendations",
          sideTitleRight:   "",
          sideTitleMidLeft: "",

          pageTitle1: "Recommendations",
          pageTitle2: "",
          listOne:    {
            one:      "Define \"restricted official information marked \"For official use only\" in the Law \"On Access to Information\" with an indication of the categories of information that it refers to. Or to provide in the Administrative Procedural Code an extensive list of information that is the basis for classifying certain information as official with the notation \"for official use only\";",
            two:      "Amend the Law \"On Access to Information\" and develop bylaws to ensure access to meetings of collegial bodies of state power;",
            three:    "Revise Article 456-1 of the Administrative Code of the Republic of Kazakhstan to include liability for violating the deadlines for providing information, for providing incomplete information, for failure to post information, or for posting incomplete information on the Internet portal, Internet resources, in the premises occupied by information holders, etc.;",
            four:     "Provide in the law additional functions of the Commission on Access to Information, including the ability to receive and consider complaints and petitions from users of information on violations or restrictions of the right of access to information. Reconsider the status of the Commission;",
            five:     "Remove the prohibition on anonymous written requests from the Law on Access to Information;",
            six:      "Exclude the term \"inquiry\" from the Law \"On the Procedure for Consideration of Appeals of Individuals and Legal Entities;",
            seven:    "Analyze the legislation for conflicts, ambiguity of norms, legal uncertainty;",
            eight:    "Develop standards for publishing open data;",
            nine:     "Develop standards for publishing information on the websites of public authorities;",
            ten:      "Continue awareness and educational activities for officials. Develop a handbook on access to information for civil servants. Develop and implement internal instructions for the provision of information and standards for the publication of data;",
            eleven:   "Begin strategic development of administrative and judicial practice on refusals to provide information, to analyze this practice;",
            twelve:   "Create and/or maintain a legal aid infrastructure for access to information (complaints, court cases, services);",
            thirteen: "Catalog existing tools for accessing information.",

          },
          listTwo:    {
            one:   "To bring the Law \"On Access to Information\" in accordance with international standards, in particular: to fix the presumption of openness of information, the \"three-part test\" for restricting access to information, the priority of the law on access to information over any other laws that regulate information issues, to eliminate automatic restrictions on access to certain categories of information;",
            two:   "Create an effective independent mechanism to monitor compliance with the Law \"On Access to Information\", and create (identify) officials (units) in the authorities responsible for the implementation of the law, giving them sufficient powers and resources. Take measures to promote and popularize the Law \"On Access to Information\", training both users and owners of information; ",
            three: "Expand liability for violations of the right of access to information and ensure effective sanctions for violations;",
            four:  "Ensure the maintenance of departmental records of information requests, the progress and results of their consideration, as well as the introduction of appropriate centralized statistical records with regular publication of data online. To ensure the preparation of an annual national report on the status of implementation of the Law \"On Access to Information\" and ensuring the right of access to information in the country;",
            five:  "Provide open online access to the main databases (registers) of state bodies, in particular registers of legal entities, property rights to real estate and vehicles, including format of open data; ",
            six:   "Abolish criminal liability for insult and other similar acts. In case of temporary preservation of such liability, transfer it to the category of criminal misconduct, excluding the possibility of penalties associated with restriction or deprivation of liberty. Abolish qualified offences related to the dissemination of information about possible acts of corruption; ",
            seven: "Ensure the effectiveness of measures to prevent excessive monetary claims against media outlets and journalists for moral damages, such as setting a one-year statute of limitations on such claims, prohibiting government officials and government agencies themselves from filing lawsuits to protect honor and dignity, extending the fee proportional to the amount claimed to claims filed in criminal proceedings, conducting regular training for judges on international standards for reviewing relevant cases. To enshrine in the normative resolution of the Supreme Court of the Republic of Kazakhstan the rules for consideration of cases on protection of honor and dignity, which comply with international standards and recommendations;",
            eight: "Join the international Open Government Partnership initiative.",
          },
          smallDesc1: "The following recommendations are provided by government agencies, NGOs, media experts, and the international organization ICNL (International Center for Not-for-Profit Law). ",
          smallDesc2: "OECD (Organization for Economic Cooperation and Development) recommendations) Recommendation № 13: ",

        },
      },
      kaz: {
        entrance:        {
          sideTitleLeft:  "Ақпаратқа",
          sideTitleRight: "қол жеткізу",

          pageTitle1: "Кіріспе",

          one: "Ақпаратқа қол жеткізу құқығы туралы алғашқы заң 1766 жылы Швецияда пайда болды және 200 жылға жуық уақыт ішінде әлемдегі осындай жалғыз құжат болды. Ол ұлттық Парламентке патшадан ақпарат алуға мүмкіндік берді. 1951 жылы Ақпаратқа қол жеткізу туралы заң Финляндияда қолданыла бастады. Бүгінде 100-ден астам ел осы саладағы процестерді реттейтін ұлттық құқық нормаларын қабылдады.",
          two: "Ақпаратқа құқық (Right of Access to information, RTI) – адамның негізгі құқықтарының бірі. Ол халықаралық стандарттарда демократия мен қоғамдық қатысуды нығайту үшін қажетті өзін-өзі көрсету еркіндігін білдіретін құқық ретінде; сыбайлас жемқорлыққа қарсы, сондай-ақ қоршаған ортаны қорғаудың маңызды құралы ретінде сипатталған. Бұл құқықты іске асырудың негізгі артықшылықтары мемлекеттік басқару сапасын жақсарту, инвестициялық ахуалды жақсарту, сыбайлас жемқорлықты азайту, азаматтық қоғамды жандандыру және оның шешімдер қабылдаудағы рөлін күшейту болып табылады.",

        },
        international:   {
          sideTitleLeft:    "Халықаралық",
          sideTitleRight:   "стандарттар",
          sideTitleMidLeft: "",

          pageTitle1: "Халықаралық ",
          pageTitle2: "стандарттар",

          one:   "Ақпаратқа қол жеткізудің базалық нормалары мынадай:\n" +
                   "•\tАдам құқықтарының жалпыға бірдей декларациясының 10-бабы ;\n" +
                   "•\tАзаматтық және саяси құқықтар туралы халықаралық пактінің (АСҚХП) 19-бабы ;\n" +
                   "•\tБҰҰ сыбайлас жемқорлыққа қарсы конвенциясының 13-бабы ;\n" +
                   "•\tОрхус конвенциясында ;\n" +
                   "•\tБҰҰ, ЕҚЫҰ, ЮНЕСКО құжаттарында, Еуропа Кеңесінің ресми құжаттарға қол жеткізу туралы Конвенциясында, адам құқықтары жөніндегі Еуропалық соттың шешімдерінде, Комитеттің және Еуропа Кеңесі Парламенттік Ассамблеясының қарарларында көрсетілген.\n",
          two:   "Негізгі қағидат ақпараттың максималды ашықтығының принципі болып саналады, одан өте шектеулі жағдайларда ауытқуға болатын, нақты және қысқаша тұжырымдалуы керек.",
          three: "БҰҰ Адам құқықтары жөніндегі комитетінің директивалық ескертуінде «Пактінің 19-бабында мемлекеттік органдардың ақпаратқа құқығы кепілдендірілген. Ол мемлекеттерден қоғамды қызықтыратын ақпаратты таратуға басымдық беруді және оған қол жеткізу «жеңіл, жылдам, тиімді және практикалық» болуды талап етеді. Түсініктемеде мемлекеттер «қажетті рәсімдерді» белгілеуі керек делінген: мысалы, ақпаратқа құқықты жүзеге асыратын заңнама; ақпаратқа қол жетімділік үшін төлемдер шектелуі керек, сұрауларға уақытында жауап берілуі керек, органдар бас тартуды негіздеуі керек және мемлекеттер бас тартуға қарсы тетіктер құруы керек.",
          four:  "БҰҰ Сыбайлас жемқорлыққа қарсы конвенциясы мемлекеттерден халықты ақпаратқа тиімді қол жеткізумен қамтамасыз ету үшін тиісті шаралар қабылдауды талап етеді және ақпарат берушілерді (whistle-blower) қорғауды міндеттейді.",
          five:  "Стандарттарды орындау үкіметтер мен мемлекеттік органдарға екі міндеттеме жүктейді. Біріншіден, мемлекеттік органдар жүзеге асыратын іс-шаралар туралы негізгі ақпаратты жариялау міндеті; екіншіден, адамдардың сұрауларына жауап беру және құжаттарға қол жеткізу міндеті.",
          six:   "Ақпаратқа қол жеткізу туралы заңдардың негізгі принциптері: Қолжетімділік құқығы – жеке тұлғаның, ұйымның немесе заңды тұлғаның мемлекеттік органдардан өзіне деген өзінің мүддесінің құқықтық негіздемесі міндетінсіз ақпарат сұрату мүмкіндігі құқығы; Ақпарат беру міндеті – мемлекеттік органдардың сұрауларға жауап беру және ақпарат беру міндеті. Бұған сұраулармен жұмыс істеу тетіктері және сұрауларға жауап берудің шекті мерзімдерін белгілеу кіреді; Ерекшеліктер ақпараттың белгілі бір санаттарын ашпауға мүмкіндік береді. Әдетте бұл ақпаратты жасырмас бұрын оның ашылуы қандай зиян келтіруі мүмкін екенін көрсетуді талап етеді; Ішкі шағымдану – өтініш берушіге ақпарат беруден бас тартуы туралы айтуға мүмкіндік беретін тетіктер; Сырттай шағымдану және қадағалау – тәуелсіз органның шағымдарды қарауы; Проактивті жариялау – билік органдарына өз қызметі туралы ақпаратты проактивті түрде жариялау талабы; Санкциялар – ақпаратты заңсыз жойғаны немесе өзгерткені үшін немесе оны беруден заңсыз бас тартқаны үшін шенеуніктер үшін жаза.",
          seven: "Соңғы жылдары ақпаратқа проактивті қолжетімділік ашық деректер және электрондық үкіметтер сияқты тәсілдермен қамтамасыз етілетінін атап өту қажет.",
          eight: "Ақпаратқа қол жеткізу құқығы, сондай-ақ сөз бостандығы құқығы абсолютті емес және шектелуі мүмкін екенін білу қажет. Мәселен, азаматтық және саяси құқықтар туралы халықаралық пактінің 19-бабының 3-тармағында шектеулер заңмен белгіленуі және: а) басқа адамдардың құқықтары мен беделін құрметтеу үшін; b) мемлекеттік қауіпсіздікті, қоғамдық тәртіпті, халықтың денсаулығы мен имандылығын қорғау үшін қажет болуы тиіс деп көрсетілген. Мұны ақпарат бостандығы құқығын шектеудің немесе бұзудың заңдылығы мен негізділігін анықтауда тест ретінде пайдалануға болады.",
          nine:  "Елдердегі ақпаратқа қол жеткізу жағдайын бағалайтын түрлі рейтингтер бар. Ең танымалдары-Ақпаратқа жаһандық құқық картасы  және бюджеттің ашықтығына шолу . Сондай-ақ мемлекеттерге ақпаратқа қол жеткізудің прогрессивті стандарттарын енгізуге ынталандыратын OGP (Open Government Partnership ), GRECO (Group of States against Corruption ) сияқты халықаралық серіктестіктер жұмыс істейді.",
          ten:   "2015 жылы ЮНЕСКО-ның ұсынысы бойынша 28 қыркүйек «Ақпаратқа жалпыға бірдей қол жеткізудің халықаралық күні» болып белгіленді.",

        },
        national:        {
          sideTitleLeft:    "Ұлттық ",
          sideTitleRight:   "стандарттар",
          sideTitleMidLeft: "",

          pageTitle1: "Ұлттық ",
          pageTitle2: "стандарттар",

          one:      "Ақпаратқа қол жеткізу құқығы әртүрлі ұлттық құжаттарда бекітілген: Конституцияның 20-бабында («Әркімнің заң жүзінде тыйым салынбаған кез келген тәсілмен еркін ақпарат алуға және таратуға құқығы бар. Қазақстан Республикасының Мемлекеттік құпиялары болып табылатын мәліметтердің тізбесі заңмен белгіленеді»); Ақпаратқа қол жеткізу туралы заңда ; БАҚ туралы заңда ; Экологиялық кодексте (21-тарау) ; Бюджет кодексінде (транспаренттілік қағидаты, 4-б. 4-т.) ; Мұрағат туралы Заңда ; «Жер қойнауы және жер қойнауын пайдалану туралы» Заңда (7-бап)  және т.б.",
          two:      "Ақпаратқа қол жеткізу туралы заң Қазақстанда 2015 жылдың соңында қабылданды. Оның қоғамдық кеңестер және сыбайлас жемқорлыққа қарсы іс-қимыл туралы заңдармен бірге қабылдануы маңызды қоғамдық реформаларға серпін беріп, мемлекеттік басқару сапасына оң әсер етеді деп жоспарланған болатын. Бастапқыда мемлекеттік органдардың ашық ақпарат көлемі 40%-дан 70%-ға дейін ұлғаяды деп жоспарланған болатын.",
          three:    "Заңның прогрессивті тармақтарына мыналар жатады: Қызметтік ақпарат енді қолжетімділігі шектеулі ақпаратқа жатпайды, бұл мемлекеттік органдардың көптеген құжаттарын ресми түрде қолжетімді етеді; Квазимемлекеттік органдар («Самұрық-Қазына», ҚТЖ және мемлекет қатысатын басқа да органдар) ақпарат иеленушілерге жатады және оны мемлекеттік органдармен қатар беруге міндетті; «Электрондық үкімет» сайтында өтініштер тәртібі нақты регламенттеледі. Азаматтар eGov арқылы ақпарат ала алады, бұл процедураны айтарлықтай жеңілдетеді және уақытты үнемдейді.",
          four:     "Заң ақпаратты үш санатқа бөледі: жалпыға қол жетімді ақпарат, ақпаратқа қол жетімділігі шектеулі болмайтын және ақпаратқа қол жетімділігі шектеулі. Соңғысына Мемлекеттік құпиялар, (жеке, банктік, бала асырап алу, салық, дәрігерлік, коммерциялық), «Қызмет бабында пайдалану үшін»  деген белгісі бар қызметтік ақпарат жатады. Соңғы тармақ көптеген шағымдар, даулар мен келіспеушіліктерді тудырады.",
          five:     "Мәселен, «Мәліметтерді таратылуы шектелген қызметтік ақпаратқа жатқызу және онымен жұмыс істеу қағидалары»  мемлекеттік органдар басшыларының сол немесе өзге мәліметтерді «Қызмет бабында пайдалану үшін» санатына дербес жатқызу құқығы белгіленеді. Бұл ретте қағидалар таратылуы шектелген ақпарат өлшемшарттарын кеңінен түсіндіру мүмкіндігін белгілейді және бұдан басқа, өлшемшарттар тізімі жабық болып табылмайды, егер олар орындаушының пікірі бойынша, мемлекеттік органның мүдделеріне нұқсан келтіре отырып пайдаланылуы мүмкін болса, ақпаратқа қолжетімділікті шектеуге мүмкіндік береді. Бұл нормалар, өз кезегінде, шектеулерді теріс пайдалануға және әлеуметтік маңызды ақпаратты заңсыз жасыруға мүмкіндік береді.",
          six:      "Сонымен қатар, Заңда көптеген тармақтары мен Азаматтық бастамаларды «жоққа шығаратын» түбегейлі қайшылық бар. 6-бапта Мемлекеттік құпияларды қамтитын мәліметтерді қоспағанда, республикалық және жергілікті бюджеттерден бөлінетін қаражаттың қалыптастырылуы мен жұмсалуы туралы ақпарат шектеуге жатпайтын ақпаратқа жатады делінген. Сонымен бірге 3-бапта былай делінген: «Осы Заң Қазақстан Республикасының аумағында қолданылады және қол жеткізу шектелген ақпаратқа жатпайтын ақпаратқа қол жеткізуге байланысты қоғамдық қатынастарға қолданылады». Яғни, бөлшектер тақтасының құпиялары мен ақпараты автоматты түрде заңның шеңберінен шығарылады.",
          seven:    "Тағы бір проблема – ақпаратқа қол жеткізу туралы Заң мен жеке және заңды тұлғалардың өтініштері туралы Заңды жиі шатастыратын Мемлекеттік органдардың заңды орындамауы. Бұл ұқсас ұғымдар мен анық емес түсініктерге, сұраулардың қалай ерекшеленетініне және оларға қалай жауап беруге байланысты. Мемлекеттік органдарға қойылатын негізгі кінәрат-талаптар-мерзімінде жауаптар болмаған, сұраныстарды елемеу, толық емес жауаптар беру, сілтемелер және жазылымнан бас тарту. Заңда анонимді сұрау салу қарастырылмаған, бұл азаматтардың құқықтарын жүзеге асыруға гипотетикалық кедергі болып табылады.",
          eight:    "Бұдан басқа, Заң ақпаратты жариялаудың бірыңғай форматын көздемейді, сондықтан бұл мәселе әрбір мемлекеттік органның ар-ожданында қалады. Мысалы, интернет-ресурстарда жалпы сипаттағы ақпарат ашылады, бірақ тексерулер жүргізу туралы ақпарат іс жүзінде ашылмайды. Немесе тексеру жоспары орналастырылады, бірақ жүргізілген тексеру нәтижелері жоқ. Көрсетілетін мемлекеттік қызметтер туралы мәліметтер ішінара, әртүрлі нақтылау дәрежесімен, әдетте барлық қызметтер бойынша емес, қарапайым және түсінікті түрде ұсынылады.",
          nine:     "Бұзушылықтар үшін Санкциялар ҚР ӘҚБтК-нің  «Ақпаратқа қол жеткізу құқығын заңсыз шектеу» 456-1 бабында көзделген. Жауапкершілік болуы мүмкін: Ақпарат берушінің заңсыз бас тартуы немесе ақпарат пайдаланушының сұранысы бойынша осындай ақпарат ұсынылуы қажет болған жағдайда көрінеу жалған ақпарат беру; Бұқаралық ақпарат құралдарында, ақпарат иесінің интернет-ресурсында, ашық деректердің интернет-порталында немесе Қазақстан Республикасының заңнамасында көзделген өзге де тәсілдермен қасақана жалған ақпаратты орналастыру; Шектеулі ақпараттан басқа ақпаратты шектеулі ақпарат ретінде сәйкес емес жіктеу.",
          ten:      "Лауазымды тұлғалар, кәсіпкерлік субъектілері, коммерциялық емес ұйымдар үшін бұзушылықтар үшін айыппұлдар 30-дан 200 АЕК-ке дейін түрленеді. Алайда сот тәртібінде әділеттілікке қол жеткізу қиын: 2016 жылғы жағдай бойынша ақпаратқа қол жеткізуден бас тартуға байланысты бір ғана іс тіркеліп, қаралды. 2018-2019 жылдары сот істерінің саны онға жетті. Негізінен талап-арыздар жергілікті атқарушы органдардың экологиялық сипаттағы ақпарат беруден бас тартуына, сондай-ақ бюджет қаражатының жұмсалуына байланысты. Көп жағдайда соттар лауазымды тұлғаларға ең төменгі айыппұл – 30 АЕК мөлшерінде шешім шығарды.",
          eleven:   "Ақпарат құқығын қорғаудың соттан тыс тетіктері де бар. Осылайша, «ақпаратқа қол жеткізу саласындағы қоғамдық мүдделерді ескеру және қорғау, сондай-ақ ақпаратты пайдаланушылардың қажеттіліктерін қанағаттандыру» үшін ақпаратқа қол жеткізу мәселелері жөніндегі Комиссия құрылды. Ақпарат министрлігі жанынан ұйымдастырылған комиссия консультативтік-кеңесші орган болып табылады және заңның орындалуын қадағалаудың қажетті өкілеттіктеріне ие емес (оның құзыретіне ұсынымдар әзірлеу ғана кіреді, бірақ шағымдарды қарау кірмейді).",
          twelve:   "Қазақстан 2005 жылдан бастап өндіруші салалардағы ашықтық бастамасының (ӨСАБ) қатысушысы болып табылады және өндіруші салалардың кірістері саласындағы ақпараттың басым бөлігі сол кезден бастап ашық бола бастады. Жер қойнауын пайдаланушылар жыл сайын валидация процесі үшін негіз болатын есептерді ұсынады. Барлық жер қойнауын пайдаланушылардың әлеуметтік дамуы мен жергілікті инфрақұрылымына аударымдар, олардың салықтары мен кірістері, квазифискалды шығыстары және т.б. туралы ақпарат жинақталған база жұмыс істейді. «Саяси маңызды тұлға» терминінің анықтамасы жоқ. Үкіметтің келісімшарттарды ашу саласындағы саясаты түсініксіз. Бұл ӨСАБ стандарттары бойынша қажетті деректерді жариялауға кедергі жасайды.",
          thirteen: "Ақпаратқа қол жеткізу туралы заңдардың жаһандық рейтингі the Global Right to Information Rating (RTI Rating)  Қазақстан заңнамасын ықтимал 150 балдан 61 балға бағалайды. Ел ақпарат туралы сұрау салуларға қатысты қол жеткізу құқықтарының көлемі және рәсімдердің болуы сияқты көрсеткіштер бойынша балл жинайды, бірақ ақпаратқа қол жеткізудің құқықтық шеңберлерін белгілеу, ақпараттан бас тарту үшін ерекшеліктер мен мүмкіндіктер көлемі, шағымдану рәсімдері, заңнаманы орындамағаны үшін \n" +
                      "Санкциялар және ақпарат алу құқығын тарату және ілгерілету жөніндегі шаралар салаларында айтарлықтай жоғалады.\n",

        },
        ep2020:          {

          pageTitle1: "2020 жыл бойынша ",
          pageTitle2: "Қазақстан жағдайы",

          one:      "Қазіргі жағдай пандемияның дүниежүзілік толқынымен шиеленісіп, мемлекеттік органдар жаңа сын-қатерлерді толық шешуге дайын емес екенін көрсетті. Осылайша, журналистерден ақпарат беру бойынша шағымдар саны едәуір артты. Онлайн-форматқа көшуге байланысты жиналыстарға, сот онлайн-процестеріне БАҚ-тың қатысуымен және жалпы жағдай туралы жұртшылықты жедел ақпараттандыруды сауатты ұйымдастырумен проблемалар туындады. Тәжірибе көрсеткендей, мемлекеттік органдардың дағдарысқа қарсы пиар дағдылары жоқ.",
          two:      "Денсаулық сақтау министрлігі бұл ақпаратты қызмет көрсету үшін санатына жатқызған кезде коронавируспен күресуге қаражат жұмсау жағдайы  мысал бола алады. Сондай-ақ, пандемия кезінде жұмсалатын алты триллион теңге туралы  ақпарат қоғамда үлкен резонанс тудырды. Шенеуніктердің ешқайсысы бұл соманың не екенін көпшілік алдында түсіндірмеді, нәтижесінде бұл мәлімдеме әлеуметтік желілерде және жалпы қоғамда қызу пікірталас тудырды, өйткені адамдар объективті ресми ақпараттың болмауына байланысты жалған тұжырымдар жасады.",
          three:    "Наурызда 160-тан астам қазақстандық журналист Қасым-Жомарт Тоқаевқа Орталық коммуникациялар қызметінің жұмысына және «елдегі өткір мәселелерді ұзақ уақыт ескермеуге» наразылық білдірген үндеуге қол қойды. Олар ақпаратқа қол жеткізу жағдайын апатты деп атады .",
          four:     "«Мұндай жағдайдың себебі-мемлекеттік органдар өкілдері мен бұқаралық ақпарат құралдары арасында коммуникацияның толық болмауы. Сонымен қатар, біз мемлекеттік органдардың, әсіресе, Президент жанындағы Орталық коммуникациялар қызметінің өкілдері көрсетіп отырған коммуникациялардың мәнін толық түсінбеушілікті көріп отырмыз. Соңғысы карантин кезеңінде іс жүзінде мемлекет пен қоғам арасында бұқаралық ақпарат құралдары арқылы онлайн режимінде диалог құрылуы тиіс жалғыз алаңға айналды. Алайда, 3,5 ай бойы коммуникациялардың орнына біз ақпараттық өрістің тарылуын және қоғам үшін маңызды ақпараттың үздіксіз қысқаруын ғана байқаймыз», - делінген хатта.",
          five:     "Журналистердің жауабын типтік хат деп атауға болады: онда пандемия кезінде мемлекет қаншалықты пайдалы және маңызды бастамаларды жүзеге асыратыны көрсетілген.",
          six:      "Қыркүйек айында Құқық қорғау ұйымдары мен азаматтар тобы президент пен парламентке ақпаратты «қызмет бабында пайдалану үшін» санатына жатқызудың қолданыстағы қағидаларын қайта қарауды талап етіп жүгінді .",
          seven:    "Сарапшылар карантин енгізілген сәттен бастап азаматтық қоғам мемлекеттік органдардың көзге көрінетін себептерсіз ақпаратты қызмет бабында пайдалану санатына жиі жатқызатындығына тап болғанын атап өтті. Сонымен бірге, тәжірибе көрсеткендей, шенеуніктердің іс-әрекеттеріне дау айту мүмкін емес, өйткені тиісті бұйрықтарда ресми пайдалануға арналған белгі бар. Осылайша, мемлекеттік ақпараттық тапсырыс желісі бойынша қандай БАҚ ақша алатыны және Парламент Мәжілісі депутаттарының жалақысының мөлшері туралы ақпарат құпияландырылды. Нәтижесінде Ақпарат және қоғамдық даму министрлігі мәселенің шын мәнінде бар екенін және жақын арада заңнаманы реформалау жөніндегі жұмыс тобы құрылып, оған азаматтық қоғам сарапшылары шақырылатынын айтты.",
          eight:    "Бюджетке келетін болсақ, мұнда жариялау үшін бірыңғай талаптар жоқ. Бюджетті пайдалану туралы негізгі мәліметтерді пандемия кезінде бюджеттік жаңалықтардың басты көзіне айналған Мемлекеттік сатып алу  сайтынан табуға болады. Белсенділер мен журналистер күмәнді сатып алуларды жария ете отырып және сыбайлас Жемқорлыққа қарсы агенттіктің назарын аудара отырып, шенеуніктер сатып алатын қызметтер туралы мәліметтерді жариялады.",
          nine:     "Бюджет туралы ақпаратты egov.kz порталынан (ашық бюджеттер) , Статистика агенттігінің , Eсеп комитетінің  және Қаржы министрлігінің  веб-сайттарынан табуға болады. Статистика бойынша «Ашық үкімет» порталының «Ашық бюджеттер» бөлімінде 13 500-ден астам бюджеттік бағдарламалар жобасы жарияланған, алайда азаматтардың осы жобаларды және бюджет қаражатын іске асыру туралы есептерді талқылау процесіне тартылу дәрежесі туралы қорытынды жасау қиын, өйткені қатысу статистикасы жоқ. Сонымен қатар, ақпарат жүйесіз және жүйе жиі жаңартылмайды. Мәселен, Конституциялық Кеңестің бюджеті туралы ақпарат соңғы рет үш жыл бұрын жаңартылған болатын. Президент Әкімшілігі мен Тұңғыш Президент Кеңсесі бойынша деректер мүлдем жоқ. Бұл ретте осы ведомстволардың шығыстары туралы ақпаратты мемлекеттік сатып алу сайтынан табуға болады.",
          ten:      "Бірнеше оң өзгерістерді атап өткен жөн. Біріншіден, соңғы уақытта халыққа бюджеттік ақпаратты қарапайым және қолжетімді түрде ұсынатын қоғамдық бастамалар пайда болды: ашық бюджеттердің интерактивті картасы , ҚР мемлекеттік бюджеті туралы ашық деректер базасы , «ЖЕР ҚОЙНАУЫ.АҚША. АЙМАҚТАР» дата-проекті пайда болды .",
          eleven:   "Екіншіден, жыл соңында ақпаратқа қол жеткізу туралы заңға түзетулер енгізілді, бұл бірнеше тармаққа әсер етті. Түзетулерде ақпаратқа қол жеткізу саласындағы уәкілетті орган нақты белгіленді (ол ақпарат министрлігі болды), оның функциялары, өкілеттіктері мен жауапкершілігі сипатталды. Бұдан басқа, «ашық диалогтың интернет-порталы» және «Бірінші басшылардың блог-платформасы» ұғымдарына анықтамалар берілді, сондай-ақ Ақпарат және ақпараттандыру саласындағы үкімет пен уәкілетті органдардың құзыреті атап өтілді.",
          twelve:   "Үшіншіден, биыл ақпарат иеленушілердің интернет-ресурстарында, атап айтқанда соттардың, әкімдіктердің, министрліктердің, квазимемлекеттік органдардың сайттарында жариялануға тиіс ақпарат нақтыланды. Ақпарат иеленушілерге енді орталық және (немесе) жергілікті атқарушы органдардың функцияларын орындаушылар да жатқызылған.",
          thirteen: "2020 жылы Қазақстан Transparency International сыбайлас жемқорлықты қабылдау индексінде алғаш рет 38 балл жинап, 19 позицияға көтерілді. Сонымен қатар, сарапшылар «Қазақстан билігі Денсаулық сақтау шығындары туралы қаржылық ақпараттың ашықтығы мен қолжетімділігіне, журналистердің, құқық қорғаушылар мен азаматтық қоғам өкілдерінің қызметін негізсіз шектеуге байланысты демократиялық бостандықтардың бұзылуына жол бергенін» атап өтті .",

        },
        recommendations: {
          sideTitleLeft:    "Ұсынымдар",

          pageTitle1: "Ұсынымдар",
          listOne:    {
            one:        "«Қызмет бабында пайдалану үшін» деген белгісі бар «таратылуы шектелген қызметтік ақпарат» деген анықтама беру не Әкімшілік рәсімдер туралы заңда оған жататын мәліметтердің санаттары көрсетіле отырып, ақпаратқа қол жеткізу туралы заңда ҚБП деген белгісі бар қызметтік ақпаратқа жатқызу үшін негіз болып табылатын мәліметтердің толық тізбесін әзірлеу;",
            two:        "Ақпаратқа қол жеткізу туралы Заңға өзгерістер енгізу және мемлекеттік биліктің алқалы органдарының отырыстарына қол жеткізуді қамтамасыз ету үшін заңға тәуелді актілер әзірлеу;",
            three:      "ҚР ӘҚБтК-нің 456-1-бабын, оған ақпаратты ұсыну мерзімдерін бұзғаны үшін, толық емес ақпарат бергені үшін, ақпаратты орналастырмағаны не толық емес ақпаратты интернет-порталда, интернет-ресурстарда, ақпарат иеленушілер орналасқан үй-жайларда және т.б. орналастырғаны үшін жауапкершілікті қоса отырып, қайта қарау;",
            four:       "Заңда ақпаратқа қол жеткізу құқығын бұзу немесе шектеу фактілері бойынша ақпарат пайдаланушылардың шағымдары мен арыздарын алу және қарау мүмкіндігін қоса алғанда, Ақпаратқа қол жеткізу мәселелері жөніндегі комиссияның қосымша функцияларын көздеу. Комиссияның мәртебесін қайта қарау;",
            five:       "Ақпаратқа қол жеткізу туралы Заңнан анонимдік жазбаша сұрау салуларды беруге тыйым салуды алып тастау;",
            six:        "Жеке және заңды тұлғалардың өтініштерін қарау тәртібі туралы Заңнан «сұрау салу» терминін алып тастау;",
            seven:      "Коллизиялардың, нормалардың екіұштылығының, құқықтық белгісіздіктің болуына заңнамаға талдау жүргізу;",
            eight:      "Ашық деректерді жариялау стандарттарын әзірлеу;",
            nine:       "Мемлекеттік органдардың сайттарында ақпаратты жариялау стандарттарын әзірлеу;",
            ten:        "Шенеуніктер үшін тәрбиелік және білім беру іс-шараларын жалғастыру. Мемлекеттік қызметшілер үшін ақпаратқа қол жеткізу бойынша manual әзірлеу. Ақпарат беру жөніндегі және деректерді жариялау стандарттары жөніндегі ішкі нұсқаулықтарды әзірлеу және енгізу;",
            eleven:     "Ақпарат беруден бас тарту бойынша әкімшілік және сот практикасын стратегиялық пысықтауды бастау, осы практикаға талдау жүргізу;",
            twelve:     "Ақпаратқа қол жеткізу мәселелері бойынша заңгерлік көмек инфрақұрылымын құру және/немесе қолдау (шағымдар, сот істері, қызметтер);",
            thirteen:   "Ақпаратқа қол жеткізу үшін қолданыстағы құралдарды каталогтау.",

          },
          listTwo:    {
            one:        "Ақпаратқа қол жеткізу туралы Заңды халықаралық стандарттарға сәйкес келтіру, атап айтқанда: ақпаратқа қол жеткізуді шектеу үшін ақпараттың ашықтығы презумпциясын, «үш құрамды тест», ақпаратқа қол жеткізу туралы Заңның ақпарат мәселелерін реттейтін кез келген басқа заңдардан басымдығын бекіту, ақпараттың жекелеген санаттарына қол жеткізуді автоматты түрде шектеуді алып тастау;",
            two:        "Ақпаратқа қол жеткізу туралы заңның сақталуын бақылаудың тиімді тәуелсіз тетігін құру, сондай-ақ билік органдарында заңды іске асыруға жауапты лауазымды адамдарды (бөлімшелерді) тағайындау (айқындау), оларға жеткілікті өкілеттіктер мен ресурстар бере отырып. Ақпаратқа қол жеткізу туралы Заңды ілгерілету және танымал ету, пайдаланушыларды да, ақпарат иеленушілерді де оқыту бойынша шаралар қабылдау;",
            three:      "Ақпаратқа қол жеткізу құқығын бұзғаны үшін жауапкершілікті кеңейту және бұзушылықтар үшін пәрменді cанкциялар қолдануды қамтамасыз ету;",
            four:       "Ақпараттық сұраныстардың ведомстволық есебін, оларды қарау барысы мен нәтижелерін жүргізуді қамтамасыз ету, сондай-ақ деректерді жүйелі түрде онлайн жариялай отырып, тиісті орталықтандырылған статистикалық есепті енгізу. Ақпаратқа қол жеткізу және елде ақпаратқа қол жеткізу құқығын қамтамасыз ету туралы Заңды іске асырудың жай-күйі туралы жыл сайынғы ұлттық баяндаманы дайындауды қамтамасыз ету;",
            five:       "Мемлекеттік органдардың негізгі дерекқорларына (тізілімдеріне), атап айтқанда заңды тұлғалардың тізілімдеріне, жылжымайтын мүлікке және көлік құралдарына меншік құқықтарына, оның ішінде ашық деректер форматында ашық онлайн-қолжетімділікті қамтамасыз ету;",
            six:        "Жала жабу, қорлау және басқа да ұқсас әрекеттер үшін қылмыстық жауапкершілікті алып тастау. Мұндай жауапкершілік уақытша сақталған жағдайда, оны шектеуге немесе бас бостандығынан айыруға байланысты жазалау шараларын қолдану мүмкіндігін болдырмай, қылмыстық теріс қылықтар қатарына ауыстыру қажет. Ықтимал сыбайлас жемқорлық іс-әрекеттері туралы ақпаратты таратуға байланысты құқық бұзушылықтардың білікті құрамдарын жою;",
            seven:      "БАҚ пен журналистерге моральдық зиянды өтеу бойынша, оның ішінде осындай талап қоюлар бойынша талап қою мерзімін бір жылға дейін шектеу, билік органдарының лауазымды адамдары мен мемлекеттік мекемелердің өздеріне ар-намысы мен қадір-қасиетін қорғау туралы талап қоюға, қылмыстық процесте берілетін талап қою сомасына бара-бар баж мөлшерін таратуға тыйым салу, судьяларды тиісті істерді қараудың халықаралық стандарттары бойынша тұрақты оқытуды жүргізу арқылы шамадан тыс ақшалай талап қою талаптарының алдын алу жөніндегі шаралардың пәрменділігін қамтамасыз ету Қазақстан Республикасы Жоғарғы Сотының нормативтік қаулысында халықаралық стандарттар мен ұсынымдарға сәйкес келетін ар-намысы мен қадір-қасиетін қорғау туралы істерді қарау қағидаларын бекіту;",
            eight:      "«Ашық үкімет әріптестігі» (Open Government Partnership) халықаралық бастамасына қосылу.",

          },


          smallDesc1: "Төменде келтірілген ұсынымдарды мемлекеттік органдар, ҮЕҰ, медиа-сарапшылар, сондай-ақ ICNL халықаралық ұйымы (халықаралық коммерциялық емес құқық орталығы) ұсынды.",
          smallDesc2: "№ 13 ұсыныс ОECD (экономикалық ынтымақтастық және даму ұйымы) ұсынымдары:",

        },
      },
    },
  },
}
