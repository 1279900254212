import React, {useEffect, useState} from "react";
import menu1                        from "../../assets/icons/menu-page/1.svg";
import menu2                        from "../../assets/icons/menu-page/menu2.svg";
import menu3                        from "../../assets/icons/menu-page/1.svg";
import menu4                        from "../../assets/icons/menu-page/menu4.svg";
import menu5                        from "../../assets/icons/menu-page/menu5.svg";
import menu6                        from "../../assets/icons/menu-page/2.svg";
import {menu}                       from "../../utils/menu";
import {mainPage}                   from "../../utils/main-page";
import MenuTopNav                   from "../../components/menu-top-nav";
import TopBanner                    from "../../components/top-banner";
import {Link}                       from "react-router-dom";
import ArrowRightIcon               from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon                from "@material-ui/icons/ArrowLeft";
import DisclaimerButton             from "../../components/disclaimer";

function ElectronicParticipationMenu({goToPage, navData, truncateString, lang, selectLang}) {
  const [data, setData] = useState(menu.lang.ru)

  useEffect(() => {
    if (lang === "ru") {
      setData(menu.lang.ru)
    }
    if (lang === "eng") {
      setData(menu.lang.eng)
    }
    if (lang === "kaz") {
      setData(menu.lang.kaz)
    }
  }, [lang])
  return (
    <div className={"menu-page ep-menu-container"}>
      <TopBanner title={navData.electronicParticipation}/>
      <div className="menu-body">
        <MenuTopNav lang={lang} currentNav={"electronic-participation"} data={navData.electronicParticipation}/>
        <div  className="menu-title">
          <Link className="left side-icons" to={"/menu/info-access"}><ArrowLeftIcon/></Link>
          <h1 onClick={() => goToPage("/")}>digital rights matter</h1>
          <Link className="right side-icons" to={"/menu/rights-to-fair-trial"}> <ArrowRightIcon/> </Link>
        </div>

        <span className="left side-button" onClick={() => goToPage("/menu/info-access")}/>
        <span className="right side-button" onClick={() => goToPage("/menu/rights-to-fair-trial")}/>
        <div className="upper-part">
          <span className="side-word">DIGITA</span>
          <div className="text-boxes">
            <div className="left-box">
              <h3 className="sub-page-title">{data.pageTitle1}</h3>
              <p>
                {truncateString(data.dr.one)}
              </p>
            </div>
            <div className="right-box">
              <p>{truncateString(data.dr.two)}</p>
            </div>
            <span className="small-desc">{data.smallDesc}</span>
          </div>

        </div>
        <div className="lower-part">
          <nav className="five-navs">
            <span onClick={() => {
              goToPage("/electronic-participation/")
            }} className="smaller-text button-one menu-entrance">{data.nav.entrance}</span>
            <span onClick={() => {
              goToPage("/electronic-participation/international")
            }}
                  className="smaller-text button-same button-bigger what-is-it menu-international">{data.nav.international}</span>
            <span onClick={() => {
              goToPage("/electronic-participation/national")
            }} className="smaller-text button-same authors menu-national">{data.nav.national}</span>
            <span onClick={() => {
              goToPage("/electronic-participation/2020")
            }} className="smaller-text button-same menu-ep2020 ">{data.nav.ep2020}</span>
            <span onClick={() => {
              goToPage("/electronic-participation/recommendations")
            }} className="smaller-text button-same conclusion menu-recommendations">{data.nav.recommendations}</span>
          </nav>
          <DisclaimerButton/>
        </div>
      </div>

    </div>
  );
}

export default ElectronicParticipationMenu;
