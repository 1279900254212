import React, {useState, useEffect} from 'react';
import {Redirect, Route, Switch}    from 'react-router-dom';
import Ep2020                       from './ep-2020';
import EpInternational              from './ep-international';
import EpNational                   from './ep-national';
import EpEntrance                   from './ep-entrance';
import EpRecommendations            from './ep-recommendations';
import GoBack                       from '../../components/goBack';
import FiveNavs                     from '../../components/five-navs';
import {mainPage}                   from '../../utils/main-page';
import TopBanner                    from '../../components/top-banner';
import ThemeButton                  from '../../components/theme-button';


function ElectronicParticipation({lang, navData, toggleTheme}) {
    return (
        <div className={'container'}>
            <GoBack lang={lang}/>
            <TopBanner title={navData.electronicParticipation}/>
            <Switch>
                <Route exact path={'/electronic-participation'} render={props => <EpEntrance lang={lang}{...props} />}/>
                <Route exact path={'/electronic-participation/international'} render={props => <EpInternational lang={lang}  {...props} />}/>
                <Route exact path={'/electronic-participation/national'} render={props => <EpNational lang={lang} {...props} />}/>
                <Route exact path={'/electronic-participation/2020'} render={props => <Ep2020 lang={lang} {...props} />}/>
                <Route exact path={'/electronic-participation/recommendations'}
                       render={props => <EpRecommendations lang={lang} {...props} />}/>
                <Redirect to={'/not-found'}/>
            </Switch>
            <FiveNavs lang={lang} path={'electronic-participation'}/>
            <ThemeButton toggleTheme={toggleTheme}/>
        </div>
    );
}

export default ElectronicParticipation;
