import React, {useEffect, useState} from 'react';
import {Redirect, Route, Switch}    from 'react-router-dom';
import NcEntrance                   from './nc-entrance';
import Nc2020                       from './nc-2020';
import NcInternational              from './nc-international';
import NcNational                   from './nc-national';
import NcRecommendations            from './nc-recommendations';
import GoBack                       from '../../components/goBack';
import FiveNavs                     from '../../components/five-navs';
import {mainPage}                   from '../../utils/main-page';
import TopBanner                    from '../../components/top-banner';
import ThemeButton                  from '../../components/theme-button';


function NewCalls({lang, navData, toggleTheme}) {
    return (
        <div className={'container'}>
            <GoBack lang={lang}/>
            <TopBanner title={navData.newCalls}/>
            <Switch>
                <Route exact path={'/new-calls'} render={props => <NcEntrance lang={lang} {...props} />}/>
                <Route exact path={'/new-calls/international'} render={props => <NcInternational lang={lang} {...props} />}/>
                <Route exact path={'/new-calls/national'} render={props => <NcNational lang={lang} {...props} />}/>
                <Route exact path={'/new-calls/2020'} render={props => <Nc2020 lang={lang} {...props} />}/>
                <Route exact path={'/new-calls/recommendations'}
                       render={props => <NcRecommendations lang={lang} {...props} />}/>
                <Redirect to={'/not-found'}/>
            </Switch>
            <FiveNavs lang={lang} path={'new-calls'}/>
            <ThemeButton toggleTheme={toggleTheme}/>
        </div>
    );
}

export default NewCalls;
