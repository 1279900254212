import React, {useEffect, useState} from 'react';
import {appData}                    from '../utils/app-data';
import {useHistory}                 from 'react-router-dom';

function GoBack({lang}) {
    const [data, setData] = useState(appData.ru)
    const history = useHistory()
    const goBack = () => {
        history.goBack()
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
    useEffect(() => {
        if (lang === 'ru') {
            setData(appData.ru)
        }
        if (lang === 'eng') {
            setData(appData.eng)
        }
        if (lang === 'kaz') {
            setData(appData.kaz)
        }

    }, [lang])


    return (
        <div className="go-back" onClick={() => {
            goBack()
        }}>
            {data.goBack}
        </div>
    );
}

export default GoBack;
