import React, {useEffect, useState} from 'react';

import eyeImg  from '../../assets/images/right-to-fair-trial/eye icon.svg';
import {ia}    from '../../utils/info-access';
import FileImg from "../../components/file-img";

function Ia2020({lang}) {
  const [data, setData] = useState(ia.infoAccess.lang.ru.ep2020)

  useEffect(() => {
    window.scrollTo(0, 0);
    if (lang === 'ru') {
      setData(ia.infoAccess.lang.ru.ep2020)
    }
    if (lang === 'eng') {
      setData(ia.infoAccess.lang.eng.ep2020)
    }
    if (lang === 'kaz') {
      setData(ia.infoAccess.lang.kaz.ep2020)
    }
  }, [lang])
  return (
    <div className='ia-container ia-2020'>
      <div className="text-body">
        <span className={'side-title left first'}>2020</span>
        <span className={'side-title right second'}>2020</span>
        <span className={'side-title left third'}>2020</span>
        <span className={'side-title right fourth'}>2020</span>
        <span className={'side-title left fifth'}>2020</span>
        <span className={'side-title right sixth'}>2020</span>
        <span className={'side-title left seventh'}>2020</span>
        <div className="titles">
          <h1>{data.pageTitle1}</h1>
          <h2>{data.pageTitle2}</h2>
        </div>
        <div className={'box left-text first-text'}>
          <p><img className="right-side-img eyeImg" src={eyeImg} alt="person avatar"/>{data.one}</p>
        </div>
        <div className={'box right-text second-text'}>
          <FileImg number={65} link={"https://esquire.kz/potchemu-zasekretchena-informatsiya-o-rashodovanii-byudzheta/"}/>
          <p>{data.two}</p>
        </div>
        <div className={'box left-text third-text'}>
          <p>{data.three}</p>
          <FileImg number={66} link={"https://neonomad.kz/u-gosudarstva-sereznye-problemy-so-sluhom-kazahstanskie-zhurnalisty-obratilis-k-tokaevu/"}/>
        </div>
        <div className={'box right-text fourth-text'}>
          <p>{data.four}</p>
        </div>
        <div className={'box left-text fifth-text'}>
          <p>{data.five}
          </p>
        </div>
        <div className={'box right-text sixth-text'}>
          <FileImg number={67} link={"https://lmc.kz/kk/node/54077"}/>
          <p>{data.six}
          </p>
        </div>
        <div className={'box left-text seventh-text'}>
          <p>{data.seven}
          </p>
        </div>
        <div className={'box right-text text8'}>
          <FileImg number={68} link={"http://www.goszakup.gov.kz"}/>
          <p>{data.eight}
          </p>
        </div>
        <div className={'box left-text text9'}>
          <p>{data.nine}
          </p>
          <FileImg number={69} link={"https://budget.egov.kz"}/>
        </div>
        <div className={'box right-text text10'}>
          <FileImg number={70} link={"www.publicbudget.kz"}/>
          <p>{data.ten}
          </p>
        </div>
        <div className={'box left-text text11'}>
          <p>{data.eleven}
          </p>
        </div>
        <div className={'box right-text text12'}>
          <p>{data.twelve}
          </p>
        </div>
        <div className={'box left-text text13'}>
          <p>{data.thirteen}
          </p>
          <FileImg number={71} link={"http://tikazakhstan.org/v-indekse-vospriyatiya-korruptsii-kazahstan-podnyalsya-na-19-pozitsij-nabrav-vpervye-38-ballov/?fbclid=IwAR27ybkY3_MlFsDK0kZxDQmndAhTctv08i7VxyQD-HmehlLiwcNduj3iUHs"}/>
        </div>
      </div>
    </div>
  );
}

export default Ia2020;
