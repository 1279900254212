import React, {useEffect, useState} from 'react';
import {menu}                       from '../utils/menu';
import {useHistory}                 from 'react-router-dom';

function FiveNavs({lang, path}) {
    const [data, setData] = useState(menu.lang.ru)
    const history = useHistory()
    const goToPage = (_path) => {

            history.push(_path)


    }
    useEffect(() => {
        if (lang === 'ru') {
            setData(menu.lang.ru)
        }
        if (lang === 'eng') {
            setData(menu.lang.eng)
        }
        if (lang === 'kaz') {
            setData(menu.lang.kaz)
        }
    }, [lang])
    return (
        <nav className="five-navs">
            <span onClick={() => {
                goToPage(`/${path}/`)
            }} className="smaller-text button-one menu-entrance">{data.nav.entrance}</span>
            <span onClick={() => {
                goToPage(`/${path}/international`)
            }} className="smaller-text button-same button-bigger what-is-it menu-international">{data.nav.international}</span>
            <span onClick={() => {
                goToPage(`/${path}/national`)
            }} className="smaller-text button-same authors menu-national">{data.nav.national}</span>
            <span onClick={() => {
                goToPage(`/${path}/2020`)
            }} className="smaller-text button-same menu-ep2020 ">{data.nav.ep2020}</span>
            <span onClick={() => {
                goToPage(`/${path}/recommendations`)
            }} className="smaller-text button-same conclusion menu-recommendations">{data.nav.recommendations}</span>
        </nav>
    );
}

export default FiveNavs;
