import React, {useEffect, useState} from 'react';
import locationImg                  from '../../assets/images/right-to-fair-trial/point icon.svg';
import img4                         from '../../assets/images/freedom-expression/Group 43.svg'
import squareImg                    from '../../assets/images/freedom-expression/squareImg.svg'
import img5                         from '../../assets/images/freedom-expression/Group 41.svg'
import square1Img                   from '../../assets/images/freedom-expression/squareImg1.svg'
import unionImg                     from '../../assets/images/freedom-expression/Union.svg'
import {fe}                         from '../../utils/freedom-expression';
import FileImg                      from "../../components/file-img";

function FeNational({lang}) {
  const [data, setData] = useState(fe.freedomExpression.lang.ru.national)

  useEffect(() => {
    window.scrollTo(0, 0);
    if (lang === 'ru') {
      setData(fe.freedomExpression.lang.ru.national)
    }
    if (lang === 'eng') {
      setData(fe.freedomExpression.lang.eng.national)
    }
    if (lang === 'kaz') {
      setData(fe.freedomExpression.lang.kaz.national)
    }
  }, [lang])
  return (
    <div className="fe-container fe-national">
      <div className="text-body">
        <span className={'side-title left'}>{data.sideTitleLeft}</span>
        <span className={'side-title right'}>{data.sideTitleRight}</span>
        <div className="titles">
          <h1>{data.pageTitle1}</h1>
          <h2>{data.pageTitle2}</h2>
        </div>
        <div className={'box left-text first-text'}>
          <p>{data.one}</p>
        </div>
        <div className={'box right-text second-text'}>
          <FileImg number={27} link={"http://adilet.zan.kz/rus/docs/Z990000451"}/>

          <p>  <img className="left-side-img locationImg" src={locationImg} alt="person avatar"/>{data.two}</p>
        </div>
        <div className={'box left-text third-text'}>
          <p>{data.three}</p>
        </div>
        <div className={'box right-text fourth-text'}>
          <p>{data.four}</p>
        </div>
        <div className={'box left-text fifth-text'}>
          <p>{data.five}
          </p>
          <FileImg number={28} link={"http://adilet.zan.kz/rus/docs/Z2000000347"}/>
        </div>
        <div className={'box right-text text6'}>
          <p>{data.six}
          </p>
          <FileImg number={29} link={"http://adilet.zan.kz/rus/docs/K1400000235"}/>
          <img className="img4" src={img4} alt=""/>
        </div>
        <div className={'box left-text text7'}>
          <p>{data.seven}
          </p>
          <FileImg number={30} link={"http://adilet.zan.kz/rus/docs/P2000000183/info"}/>
        </div>
        <div className={'box right-text text8'}>
          <p>{data.eight}
          </p>
        </div>
        <div className={'box left-text text9'}>
          <p>{data.nine}
          </p>
        </div>
        <div className={'box right-text text10'}>
          <p>{data.ten}
          </p>
          <img className="squareImg" src={squareImg} alt=""/>
          <FileImg number={31} link={"http://adilet.zan.kz/rus/docs/Z1500000418"}/>
        </div>
        <div className={'box left-text text11'}>
          <p>{data.eleven}
          </p>
        </div>
        <div className={'box right-text text12'}>
          <p>{data.twelve}
          </p>
        </div>
        <div className={'box left-text text13'}>
          <p>{data.thirteen}
          </p>
          <img className="img5" src={img5} alt=""/>
          <FileImg number={32} link={"https://ratel.kz/scandal/politsija_zaderzhala_zhurnalistov_ratelkz_i_forbes_kazakhstan "}/>
        </div>
        <div className={'box right-text text14'}>
          <p>{data.fourteen}
          </p>
          <img className="square1Img" src={square1Img} alt="file"/>
          <FileImg number={33} link={"http://www.adilsoz.kz/news/show/id/2642"}/>
        </div>
        <div className={'box left-text text15'}>
          <p>{data.fifteen}
          </p>
          <img className="unionImg" src={unionImg} alt="file"/>
        </div>
        <div className={'box right-text text16'}>
          <p>{data.sixteen}
          </p>
          <FileImg number={34} link={"http://adilet.zan.kz/rus/docs/Z040000567_"}/>
        </div>

      </div>
    </div>
  );
}

export default FeNational;
