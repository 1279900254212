import React, {useEffect, useState} from "react";
import fileEmpty                    from "../../assets/images/file-img.svg"
import {ps}                         from "../../utils/personal-security";
import personImg                    from "../../assets/images/personal-security/person icon.svg"
import FileImg                      from "../../components/file-img";

function DaEntrance({lang}) {
  const [data, setData] = useState(ps.personalSecurity.lang.ru.entrance)

  useEffect(() => {
    window.scrollTo(0, 0);
    if (lang === "ru") {
      setData(ps.personalSecurity.lang.ru.entrance)
    }
    if (lang === "eng") {
      setData(ps.personalSecurity.lang.eng.entrance)
    }
    if (lang === "kaz") {
      setData(ps.personalSecurity.lang.kaz.entrance)
    }
  }, [lang])
  return (
    <div className={"da-container da-entrance"}>
      <div className="text-body">
        <span className={"side-title left"}>{data.sideTitleLeft}</span>
        <span className={"side-title right"}>{data.sideTitleRight}</span>
        <h1>{data.pageTitle1}</h1>
        <div className={"box left-text first-text"}>
          <p><img src={personImg} className={"right-side-img personImg"} alt=""/>{data.one}</p>
          <FileImg number={1} link={"https://web.archive.org/web/20131227074032/http:/www.coe.int/t/dghl/standardsetting/dataprotection/Data_protection_day_en.asp"}/>

        </div>
        <div className={"box right-text second-text"}>
          <p>{data.two}</p>
        </div>
        <div className={"box left-text third-text"}>
          <p>{data.three}</p>
        </div>
        <div className={"box right-text fourth-text"}>
          <FileImg number={2} link={"https://freedomhouse.org/report/freedom-net/2019/crisis-social-media"}/>
          <p>{data.four}</p>
        </div>
        <div className={"box left-text fifth-text"}>
          <p>{data.five}</p>
        </div>
        <div className={"box right-text sixth-text"}>
          <FileImg number={3} link={"https://www.wired.com/2006/05/the-eternal-value-of-privacy/"} />
          <p>{data.six}</p>
        </div>
        <div className={"box left-text seventh-text"}>
          <p>{data.seven}
          </p>
        </div>
      </div>
    </div>
  );
}

export default DaEntrance;
