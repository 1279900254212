import React, {useEffect, useState} from "react";
import menu1                        from "../assets/icons/menu-page/1.svg";
import menu2                        from "../assets/icons/menu-page/2.svg";
import menu3                        from "../assets/icons/menu-page/3.svg";
import menu4                        from "../assets/icons/menu-page/4.svg";
import menu5                        from "../assets/icons/menu-page/5.svg";
import menu6                        from "../assets/icons/menu-page/6.svg";
import menu7                        from "../assets/icons/menu-page/7.svg";
import menu8                        from "../assets/icons/menu-page/8.svg";
import {mainPage}                   from "../utils/main-page";
import {useHistory}                 from "react-router-dom";

function MenuTopNav({lang, currentNav}) {
  const [activeImg, setActiveImg] = useState("")
  const [navData, setNavData] = useState(mainPage.lang.ru)
  const history = useHistory()
  const goToPage = (path) => {
    history.push(path)
  }

  useEffect(() => {
    if (lang === "ru") {
      setNavData(mainPage.lang.ru)
    }
    if (lang === "eng") {
      setNavData(mainPage.lang.eng)
    }
    if (lang === "kaz") {
      setNavData(mainPage.lang.kaz)
    }

    // //
    // if (currentNav === "digital-rights") {
    //   setActiveImg("one")
    // }
    // if (currentNav === "digital-rights") {
    //   setActiveImg("two")
    // }
    // if (currentNav === "info-access") {
    //   setActiveImg("three")
    // }
    // if (currentNav === "electronic-participation") {
    //   setActiveImg("four")
    // }
    // if (currentNav === "rights-to-fair-trial") {
    //   setActiveImg("five")
    // }
    // if (currentNav === "new-calls") {
    //   setActiveImg("six")
    // }
  }, [lang])

  const showImg = (img) => {
    if (img === "one") {
      setActiveImg("one")
    }
    if (img === "two") {
      setActiveImg("two")
    }
    if (img === "three") {
      setActiveImg("three")
    }
    if (img === "four") {
      setActiveImg("four")
    }
    if (img === "five") {
      setActiveImg("five")
    }
    if (img === "six") {
      setActiveImg("six")
    }
    if (img === "seven") {
      setActiveImg("seven")
    }
    if (img === "eight") {
      setActiveImg("eight")
    }
  }
  const hideImg = () => {
    setActiveImg(false)
  }
  return (
    <div className="top-nav-wrapper">
      <div className={`menu-bar top-navs ${activeImg}`}>
        <div className="img-row img-one">
          <img onClick={() => {
            goToPage("/menu/about")
          }} onMouseLeave={() => hideImg()} onMouseEnter={() => showImg("one")} className="top-nav one"
               src={menu1} alt=""/>
          <span className={`nav-title left one ${activeImg}`}>{navData.about}</span>
        </div>
        <div className="img-row img-two">
          <img onClick={() => goToPage("/menu/digital-rights")} onMouseLeave={() => hideImg()}
               onMouseEnter={() => showImg("two")} className="top-nav two"
               src={menu2} alt=""/>
          <span className={`nav-title left two ${activeImg}`}>{navData.digitalRights}</span>
        </div>
        <div className="img-row img-three">
          <img onClick={() => goToPage("/menu/freedom-expression")} onMouseLeave={() => hideImg()}
               onMouseEnter={() => showImg("three")} className="top-nav three"
               src={menu3} alt=""/>
          <span className={`nav-title left three ${activeImg}`}>{navData.freedomExpression}</span>
        </div>
        <div className="img-row img-four">
          <img onClick={() => goToPage("/menu/limit-on-internet")} onMouseLeave={() => hideImg()}
               onMouseEnter={() => showImg("four")} className="top-nav four"
               src={menu4} alt=""/>
          <span className={`nav-title left four ${activeImg}`}>{navData.limitOnInternet}</span>
        </div>
        <div className="img-row img-five">
          <img onClick={() => goToPage("/menu/info-access")} onMouseLeave={() => hideImg()}
               onMouseEnter={() => showImg("five")} className="top-nav five"
               src={menu5} alt=""/>
          <span className={`nav-title right five ${activeImg}`}>{navData.infoAccess}</span>
        </div>
        <div className="img-row img-six">
          <img onClick={() => goToPage("/menu/electronic-participation")} onMouseLeave={() => hideImg()}
               onMouseEnter={() => showImg("six")} className="top-nav six" src={menu6}
               alt=""/>
          <span className={`nav-title right six ${activeImg}`}>{navData.electronicParticipation}</span>
        </div>
        <div className="img-row img-seven">
          <img onClick={() => goToPage("/menu/rights-to-fair-trial")} onMouseLeave={() => hideImg()}
               onMouseEnter={() => showImg("seven")} className="top-nav seven" src={menu7}
               alt=""/>
          <span className={`nav-title right seven ${activeImg}`}>{navData.rightsToFairTrial}</span>
        </div>
        <div className="img-row img-eight">
          <img onClick={() => goToPage("/menu/new-calls")} onMouseLeave={() => hideImg()}
               onMouseEnter={() => showImg("eight")} className="top-nav eight" src={menu8}
               alt=""/>
          <span className={`nav-title right eight ${activeImg}`}>{navData.newCalls}</span>
        </div>
      </div>
    </div>
  );
}

export default MenuTopNav;
