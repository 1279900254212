import React, {useEffect, useState} from 'react';
import {Redirect, Route, Switch}    from 'react-router-dom';
import RightEntrance                from './right-entrance';
import Right2020                    from './right-2020';
import RightNational                from './right-national';
import RightRecommendations         from './right-recommendations';
import GoBack                       from '../../components/goBack';
import FiveNavs                     from '../../components/five-navs';
import {mainPage}                   from '../../utils/main-page';
import TopBanner                    from '../../components/top-banner';
import RightInternational           from './international';
import ThemeButton                  from '../../components/theme-button';


function RightsToFairTrial({lang, navData, toggleTheme}) {
    return (
        <div className={'container'}>
            <GoBack lang={lang}/>
            <TopBanner title={navData.rightsToFairTrial}/>
            <Switch>
                <Route exact path={'/rights-to-fair-trial'} render={props => <RightEntrance lang={lang} {...props} />}/>
                <Route exact path={'/rights-to-fair-trial/international'} render={props => <RightInternational lang={lang} {...props} />}/>
                <Route exact path={'/rights-to-fair-trial/national'} render={props => <RightNational lang={lang} {...props} />}/>
                <Route exact path={'/rights-to-fair-trial/2020'} render={props => <Right2020 lang={lang} {...props} />}/>
                <Route exact path={'/rights-to-fair-trial/recommendations'}
                       render={props => <RightRecommendations lang={lang} {...props} />}/>
                <Redirect to={'/not-found'}/>
            </Switch>
            <FiveNavs lang={lang} path={'rights-to-fair-trial'}/>
            <ThemeButton toggleTheme={toggleTheme}/>
        </div>
    );
}

export default RightsToFairTrial;
