import React, {useEffect, useState} from "react";
import {dr}                         from "../../utils/digital-rights"
import lockIcon                     from '../../assets/images/digital-rights/lock icon.svg'
import virus                        from '../../assets/images/digital-rights/virus icon.svg'
import rombLong                     from '../../assets/images/digital-rights/romb-long.svg'
import TopBanner                    from "../../components/top-banner";

function DREntrance({lang}) {
  const [data, setData] = useState(dr.digitalRights.lang.ru.entrance)
  useEffect(() => {
    window.scrollTo(0, 0);
    if (lang === "ru") {
      setData(dr.digitalRights.lang.ru.entrance)
    }
    if (lang === "eng") {
      setData(dr.digitalRights.lang.eng.entrance)
    }
    if (lang === "kaz") {
      setData(dr.digitalRights.lang.kaz.entrance)
    }
  }, [lang])


  return (
    <div className={"dg-entrance dr-container"}>
      <div className={"text-body"}>
        <span className={"side-title left"}>DIGITAL</span>
        <span className={"side-title right"}>RIGHTS</span>
        <div className={"box first-text"}>
          <h1>{data.pageTitle}</h1>
          <p><img className={'right-side-img lockIcon'} src={lockIcon} alt=""/>{data.firstText}</p>
        </div>
        <div className={"box second-text"}><p>{data.secondText}</p></div>
        <span className="small-desc">
          {data.smallDesc}
        </span>
        <div className="box third-text">
          <h3>{data.thirdText.title}</h3>
          <p>{data.thirdText.text}</p>
        </div>
        <div className={"box fourth-text"}>
          <p><img className={'left-side-img lockIcon'} src={virus} alt=""/>{data.fourthText}</p>
        </div>
        <img src={rombLong} className={"long"} alt=""/>
      </div>
    </div>
  );
}

export default DREntrance;
