import React, {useEffect, useState} from 'react';
import listImg                      from '../../assets/images/electronic-participation/list icon.svg';
import square1Img                   from '../../assets/images/freedom-expression/squareImg1.svg';
import {limitOnInternet}            from '../../utils/limit-on-internet';

function LimitRecommendations({lang}) {
  const [data, setData] = useState(limitOnInternet.lang.ru.recommendations)

  useEffect(() => {
    window.scrollTo(0, 0);
    if (lang === 'ru') {
      setData(limitOnInternet.lang.ru.recommendations)
    }
    if (lang === 'eng') {
      setData(limitOnInternet.lang.eng.recommendations)
    }
    if (lang === 'kaz') {
      setData(limitOnInternet.lang.kaz.recommendations)
    }
  }, [lang])
  return (
    <div className="limit-container limit-recommendations">
      <div className="text-body">
        <span className={'side-title left'}>{data.sideTitleLeft}</span>
        <h1>{data.pageTitle1}</h1>
        <div className={'box right-text'}>
          <p>{data.one}</p>
        </div>
        <div className={'box left-text first-text'}>
          <span className="side-number">1</span>
          <p><img className="right-side-img listImg" src={listImg} alt="person avatar"/>{data.two}</p>
        </div>
        <div className={'box left-text second-text'}>
          <span className={'side-number'}>2</span>
          <p>{data.three}</p>
        </div>
        <div className={'box right-text third-text'}>
          <span className={'side-number'}>3</span>
          <p>{data.four}</p>
        </div>
        <div className={'box left-text fourth-text'}>
          <span className={'side-number'}>4</span>
          <p>{data.five}</p>
        </div>
        <div className={'box right-text fifth-text'}>
          <span className={'side-number'}>5</span>
          <p>{data.six}
          </p>
        </div>
        <div className={'box left-text sixth-text'}>
          <span className={'side-number'}>6</span>
          <p>{data.seven}
          </p>
          <img src={square1Img} className="square1Img" alt=""/>
        </div>
      </div>
    </div>
  );
}

export default LimitRecommendations;
