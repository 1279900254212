import React, {useEffect, useState} from 'react';
import groupPeopleImg               from '../../assets/images/freedom-expression/peopleGroup.svg';
import img3                         from '../../assets/images/freedom-expression/Group 41.svg';
import squareImg                    from '../../assets/images/freedom-expression/squareImg.svg';
import {ia}                         from '../../utils/info-access';
import FileImg                      from "../../components/file-img";

function IaInternational({lang}) {
  const [data, setData] = useState(ia.infoAccess.lang.ru.international)

  useEffect(() => {
    window.scrollTo(0, 0);
    if (lang === 'ru') {
      setData(ia.infoAccess.lang.ru.international)
    }
    if (lang === 'eng') {
      setData(ia.infoAccess.lang.eng.international)
    }
    if (lang === 'kaz') {
      setData(ia.infoAccess.lang.kaz.international)
    }
  }, [lang])
  return (
    <div className="ia-container ia-international">
      <div className="text-body">
        <span className={'side-title left'}>{data.sideTitleLeft}</span>
        <span className={'side-title right'}>{data.sideTitleRight}</span>
        <div className="titles">
          <h1>{data.pageTitle1}</h1>
          <h2>{data.pageTitle2}</h2>
        </div>
        <div className={'box left-text first-text'}>
          <p><img className="right-side-img groupPeopleImg" src={groupPeopleImg} alt="person avatar"/>{data.one}</p>
          <FileImg number={58} link={"https://www.un.org/ru/documents/decl_conv/declarations/declhr.shtml"}/>
        </div>
        <div className={'box right-text second-text'}>
          <p>{data.two}</p>
        </div>
        <div className={'box left-text third-text'}>
          <p>{data.three}</p>
        </div>
        <div className={'box right-text fourth-text'}>
          <p>{data.four}</p>
        </div>
        <div className={'box left-text fifth-text'}>
          <p>{data.five}
          </p>
        </div>
        <div className={'box right-text sixth-text'}>
          <p>{data.six}
          </p>
        </div>
        <div className={'box left-text seventh-text'}>
          <p>{data.seven}
          </p>
        </div>
        <div className={'box right-text eighth-text'}>
          <p>{data.eight}
          </p>
        </div>
        <div className={'box left-text ninth-text'}>
          <p>{data.nine}
          </p>
          <FileImg number={59 } link={"https://www.rti-rating.org/"}/>
        </div>
        <div className={'box right-text tenth-text'}>
          <img className="squareImg" src={squareImg} alt="file"/>
          <p>{data.ten}
          </p>
        </div>

      </div>
    </div>
  );
}

export default IaInternational;
