import React, {useEffect, useState} from 'react';
import personImg                    from '../../assets/images/electronic-participation/person icon.svg'
import {ep}                         from '../../utils/electronic-participation';
import FileImg                      from "../../components/file-img";

function EpEntrance({lang}) {
  const [data, setData] = useState(ep.electronicParticipation.lang.ru.entrance)

  useEffect(() => {
    window.scrollTo(0, 0);
    if (lang === 'ru') {
      setData(ep.electronicParticipation.lang.ru.entrance)
    }
    if (lang === 'eng') {
      setData(ep.electronicParticipation.lang.eng.entrance)
    }
    if (lang === 'kaz') {
      setData(ep.electronicParticipation.lang.kaz.entrance)
    }
  }, [lang])
  return (
    <div className={'ep-container ep-entrance'}>
      <div className="text-body">
        <span className={'side-title left'}>{data.sideTitleLeft}</span>
        <span className={'side-title right'}>{data.sideTitleRight}</span>
        <h1>{data.pageTitle1}</h1>
        <div className={'box left-text first-text'}>
          <p><img className='right-side-img' src={personImg} alt="person avatar"/>
            {data.one}</p>
          <FileImg number={71} link={"http://tikazakhstan.org/v-indekse-vospriyatiya-korruptsii-kazahstan-podnyalsya-na-19-pozitsij-nabrav-vpervye-38-ballov/?fbclid=IwAR27ybkY3_MlFsDK0kZxDQmndAhTctv08i7VxyQD-HmehlLiwcNduj3iUHs"}/>
        </div>
        <div className={'box right-text second-text'}>
          <FileImg number={72} link={"https://publicadministration.un.org/publications/content/pdfs/un%20e-government%20survey%202018%20russian.pdf"}/>
          <p>{data.two}</p>
        </div>
        <div className={'box left-text third-text'}>
          <p>{data.three}</p>
        </div>
        <div className={'box right-text fourth-text'}>
          <p>{data.four}></p>
        </div>
      </div>
    </div>
  );
}

export default EpEntrance;
