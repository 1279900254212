export const ep = {
  electronicParticipation: {
    lang: {
      ru:  {
        entrance:        {
          sideTitleLeft:    "ЭЛЕКТРОННОЕ",
          sideTitleRight:   "УЧАСТИЕ",
          pageTitle1:       "ВВЕДЕНИЕ",
          one:              "Под электронным участием понимают «процесс вовлечения граждан при помощи информационных и коммуникационных технологий (ИКТ) в политику, принятие решений, разработку и оказание услуг в целях обеспечения участия, инклюзивности и целенаправленности» . Реализация этого процесса требует как «права на доступ к сети Интернет», то есть доступности среды, в которой осуществляется электронное участие, так и «права на доступ к информации», которое подразумевает наличие в публичном доступе информации, позволяющей реализовать электронное участие. В то же время необходимо и наличие нормативно-правовой базы, которая делает электронное участие легитимным.",
          two:              "Если перенести эту концепцию в сферу электронной демократии, можно выделить следующие этапы:",
          three:            "Электронное информирование (предоставление информации в режиме онлайн): односторонняя связь, при которой правительство производит и поставляет информацию по разным онлайн-каналам для публичного использования гражданами. Это охватывает как «пассивный» доступ к информации по требованию граждан, так и «активные» меры правительства по распространению информации среди граждан;",
          four:             "Электронные консультации: двусторонняя связь, при которой граждане предоставляют обратную связь правительству с помощью онлайн-инструментов. Взаимодействие основано на предварительном определении правительством вопросов, по которым запрашиваются мнения граждан и которые требуют предоставления информации;",
        },
        international:   {
          sideTitleLeft:    "МЕЖДУНАРОДНЫЕ",
          sideTitleRight:   "СТАНДАРТЫ",
          sideTitleMidLeft: "",

          pageTitle1:       "МЕЖДУНАРОДНЫЕ",
          pageTitle2:       "СТАНДАРТЫ",

          one:              "В настоящее время нет общепринятых международных стандартов, которые могли бы однозначно указывать на необходимость всем странам придерживаться общих правил при внедрении «электронного (цифрового) государства». Однако из уже имеющихся документов можно извлечь основные принципы, на которых должно строиться электронное участие граждан в управлении государством.",
          two:              "Конвенция о защите прав человека и основных свобод , п.10, декларирует «Право каждого человека свободно выражать и придерживаться своего мнения, а также распространять информацию и идеи…»",
          three:            "МПГПП  в ст. 19 декларирует, что «Каждый человек имеет право беспрепятственно придерживаться своих мнений… имеет право на свободное выражение своего мнения; это право включает свободу искать, получать и распространять всякого рода информацию…»",
          four:             "МПГПП в ст. 25 декларирует, что «Каждый гражданин должен иметь… право и возможность… принимать участие в ведении государственных дел…»",
          five:             "МПЭСКП  в ст. 15 декларирует, что «Государства признают право каждого человека на пользование результатами научного прогресса и их практического применения»",
          six:              "Конвенция ООН об использовании электронных сообщений в международных договорах  в ст. 8 признает юридическую силу электронных сообщений. Поскольку Конституция РК в ст. 4 признает примат международного права, норма о юридической силе электронных сообщений распространяется и на казахстанскую юрисдикцию",
          seven:"В 2012 году Совет ООН по правам человека впервые принял резолюцию , посвященную свободе слова в интернете, в которой, в том числе, подтверждает, что те же права, которые человек имеет в офлайновой среде, должны также защищаться и в онлайновой среде, в частности право на свободу выражения мнений."
        },
        national:        {
          sideTitleLeft:    "НАЦИОНАЛЬНЫЕ",
          sideTitleRight:   "СТАНДАРТЫ",
          pageTitle1:       "НАЦИОНАЛЬНЫЕ",
          pageTitle2:       "СТАНДАРТЫ",

          one:              "Закон «О доступе к информации» от 16 ноября 2015 года, ст. 10, содержит одно из определений доступа к информации как «доступ к информации, размещенной на соответствующих компонентах веб-портала “электронного правительства;",
          two:              "Закон Республики Казахстан от 24 ноября 2015 года № 418-V «Об информатизации» (с изменениями и дополнениями по состоянию на 01.01.2020 г.), ст. 19, содержит определение видов услуг, оказываемых в электронной форме, а также регламентирует функционал «Электронного правительства» в ст. 21 – 31;",
          three:            "Государственная программа «Цифровой Казахстан», утвержденная постановлением правительства РК от 12.12.2017 № 827, с изменениями, принятыми постановлением правительства РК от 20.12.2019 № 949, описывает сервисы, которые планируется поддерживать при помощи функционала «электронного правительства», и отмечает проблемы, решение которых требует дополнительного внимания.\n",
        },
        ep2020:          {
          pageTitle1:       "СИТУАЦИЯ ЗА 2020 ГОД",
          pageTitle2:       "В КАЗАХСТАНЕ",

          one:              "В рейтинге Индекса электронного участия (E-Participation Index) , который составляется ООН, в 2020 году Казахстан занимает 29 место (в 2018 страна находилась на 42 позиции).  Индекс электронного участия отражает запросы граждан к национальной системе электронного правительства: степень информационной открытости государства и механизмы предоставления гражданам публичной информации, уровень вовлечения граждан в консультации с государственными органами и привлечения людей к обсуждению вопросов государственной политики, а также эффективные механизмы участия граждан в процессах принятия решений онлайн.",
          two:              "Государственная программа «Цифровой Казахстан» в качестве одного из направлений реализации ставит задачу «перехода на цифровое государство» – преобразования функций государства как инфраструктуры предоставления услуг населению и бизнесу. При этом в идеале должна быть реализована модель управления, когда не гражданин обращается в госорганы за услугами, а государство, понимая потребности граждан, связывается с ними для оказания государственных услуг без необходимости физического посещения учреждений.",
          three:            "В настоящее время в Казахстане работает портал электронного правительства eGov , который содержит вкладку «Электронное участие» .  На вкладке дается собственное, несколько отличное от принятого ООН, определение электронного участия: «Электронное участие – это процесс вовлечения граждан в политику, принятие решений, и разработку и предоставление услуг с помощью информационно-коммуникационных технологий».",
          four:             "Для пользования порталом и получения государственных услуг в электронном виде в Казахстане в 2003 году были внедрены электронные цифровые подписи – ЭЦП.",
          five:             "Инструментом электронного участия является портал Открытого правительства , являющийся, в свою очередь, агрегатором для нескольких порталов:",
          six:              "Портала Открытых данных , с помощью которого общественность может находить, загружать и использовать наборы данных, которые создаются и постоянно пополняются государственными органами;",
          seven:            "Портала Открытых НПА , предназначенного для вынесения на общественное обсуждение проектов концепций законопроектов и проектов нормативных правовых актов. Здесь следует заметить, что из-за малой информированности общественности о том, как комментарии влияют на конечный текст проекта нормативного акта, культура широкого участия в процедуре обсуждения отсутствует. Кроме того, как правило изменения вносятся в уже имеющиеся законодательные акты, и комментарии к поправкам в НПА часто остаются без рассмотрения, поскольку противоречат ранее принятой концепции, которая при внесении поправок не изменяется.",
          eight:             "Портала Открытого диалога , где пользователи могут напрямую подать обращение и отправлять предложения к конкретному государственному органу или местному акимату, сообщить о качестве сотовой сети, поучаствовать в социально значимых опросах. На портале принимаются только индивидуальные обращения, поскольку в казахстанском законодательстве отсутствует институт коллективных обращений, а также обязательных для рассмотрения по определенной установленной процедуре петиций. Не известно ни одного случая, когда петиция, опубликованная на сайтах-агрегаторах петиций, привела бы к изменению политики – государственные органы не рассматривают их как легитимные запросы от населения и не считают нужным делать официальные заявления по их поводу.\n",
          nine:"Портала Открытых бюджетов , где публикуются проекты бюджетных программ до их утверждения, чтобы граждане могли внести изменения в процесс расходования бюджетных средств, а также результаты государственного аудита и финансовых проверок. Как и в портале Открытые НПА, вопрос, насколько и каким образом учитываются комментарии граждан при обсуждении, реализации и оценке эффективности бюджетных программ, остается открытым;",
          ten:"Портала Государственных закупок , где граждане могут участвовать в государственных тендерах. Портал содержит данные об имеющихся лотах, заключенных договорах, о планах государственных закупок, а также сведения об участниках, налоговых отчислениях и недобросовестных поставщиках.",
          eleven:              "Позитивным можно признать тот факт, что для разовых обращений на порталы «Открытого правительства» не всегда нужно использовать ЭЦП, часть запросов можно подтверждать с помощью пароля, высылаемого на мобильный телефон. Согласно информации, размещенной на сайте АО «НИТ» – оператора информационно-коммуникационной инфраструктуры электронного правительства– на портале электронного правительства без электронной цифровой подписи посредством ввода одноразового пароля доступно 45 услуг.",
          twelve:           "Как и любой другой интернет-сервис, сервис «Электронного правительства» периодически испытывает проблемы с доступом. Это обуславливается (в некоторых случаях) большим количеством обращений. Так, в период вспышки коронавируса и объявления в стране чрезвычайного положения весной 2020 года правительство принимало меры для поддержки граждан, потерявших доходы в связи с объявленным карантином. В числе принимаемых мер была выплата пособия в размере минимальной заработной платы. Зарегистрироваться для получения пособия необходимо было на сайте eGov, и сайт не справился с количеством обращений. Заявления на выплату пособия подали более 8 миллионов граждан, причем одномоментно подавалось до 25 тысяч обращений . В этих условиях руководство НАО «ГК «Правительство для граждан» (оператор сайта eGov) было вынуждено организовать работу telegram-ботов и запустить специальный сайт для приема обращений. Проблемы с доступом к порталу продолжались больше недели.",
          thirteen:           "Операторы сайта eGov извлекли уроки из этой ситуации. На портале Электронного правительства в разделе «Трудоустройство и занятость» появилась вкладка «Назначение социальной выплаты 42500», пройдя по которой пользователь получает инструкции о действиях, которые он должен предпринять для подачи заявки . Когда в связи с объявлением повторного карантина 14 июля 2020 года премьер-министр дал поручение осуществить выплату в размере 42500 тенге гражданам, потерявшим доход из-за карантинных ограничений , заявление на назначение единовременной выплаты стало возможно подать как через веб-портал «Электронного правительства» и портал Enbek.kz, так и через telegram-бот электронного правительства, мобильные приложения и Интернет-ресурсы банков второго уровня.",
          fourteen:         "При безусловно позитивном подходе внедрения и широкой популяризации сервисов «Открытого правительства», пользователи могут испытывать опасения за свою электронную безопасность. При получении электронной цифровой подписи пользователи получают и сертификат безопасности, который влияет на весь электронный трафик. С другой стороны, не имея ЭЦП и сертификата безопасности на своем компьютере, пользователь лишается доступа со своего компьютера к некоторым сервисам «Открытого правительства» – например, к порталу Оценки эффективности деятельности государственных органов.",
          fifteen:         "Это не единичный спорный момент. Шестого декабря 2020 г. Министерство цифрового развития, инноваций и аэрокосмической промышленности (МЦРИАП) совместно с Комитетом национальной безопасности КНБ) провели учения «Кибербезопасность Нур-Султан-2020».  В ходе проведения этих учений многие пользователи сталкивались с проблемами с доступом к некоторым ресурсам – социальным сетям, мессенджерам, и другим сайтам.  Эксперты связывают эти проблемы с новой попыткой внедрения сертификата безопасности, который может оказаться средством наблюдения за трафиком со стороны государственных органов.\n ",
        },
        recommendations: {
          sideTitleLeft:    "рекомендации",
          pageTitle1:       "рекомендации",

          one:              "Предпринимать усилия по переводу максимально большого количества государственных услуг в электронный формат, как используя для этого имеющиеся инструменты, так и создавая новые.",
          two:              "Совершенствовать законодательную базу для более полной реализации гражданами своих прав в электронной среде, включая реализацию возможности коллективного обращения и подачи петиции.",
          three:            "Уважать и защищать право гражданского общества и отдельных активистов критически высказываться о работе электронного правительства и по другим вопросам, носящим характер общественного обсуждения.",
          four:             "Провести каталогизацию инструментов участия, анализ статистики их использования, эффективности и представленности онлайн. Определить с помощью широкого гражданского участия потребности в новых инструментах.",
          five:             "Формализовать процедуры рассмотрения комментариев к проектам закона и бюджетам, учитывая требования инклюзивности и прозрачности.",
          six:              "Упростить и визуализировать информацию о законодательных, бюджетных и иных инициативах, представленную на порталах электронного правительства.",
          seven:            "Провести анализ функционирования электронного правительства в условиях пандемии COVID-19 и использовать результаты анализа в дальнейшем.",
          eight:             "Широко использовать новые технологические решения, способствующие доступу к информации и ее защите; максимально использовать возможности ссылок через QR-коды, функционал социальных медиа и ботов в мессенджерах для обращения по стандартным услугам; рассмотреть варианты использования одноразовых паролей вместо ЭЦП и блокчейн-решений для защиты данных. ",
          nine:              "Инициировать широкую гражданскую адвокационную программу по реформированию и расширению электронного участия.",
          ten:           "Создавать пособия и информационные ресурсы для ОГО и активистов, для распространения позитивного опыта и успешных примеров работы механизмов участия.",
          eleven:           "Предпринимать усилия по наращиванию экспертного потенциала ОГО и активистов для работы в существующих механизмах электронного участия.\n ",

          smallDesc1:       "Правительству Республики Казахстан:",
          smallDesc2:       "НАО «ГК «Правительство для граждан»:",
          smallDesc3:       "Организациям Гражданского общества Республики Казахстан: ",
        },
      },
      eng: {
        entrance:        {
          sideTitleLeft:    "electronic",
          sideTitleRight:   "participation",

          pageTitle1:       "Introduction",

          one:              "E-participation refers to \"the process of engaging citizens through information and communication technologies (ICTs) in policy, decision-making, design and delivery of services to ensure participation, inclusion, and purposefulness\" . Of course, the realization of this process requires both the \"right of access to the Internet\",  that is, the accessibility of the environment in which e-participation takes place, and the \"right of access to information\",  which implies the public availability of information that allows e-participation to take place. At the same time, there also needs to be a legal and regulatory framework that makes e-participation legitimate. ",
          two:              "If we transfer this concept to the sphere of e-democracy, we can distinguish the following stages :",
          three:            "•\tE-notification (providing information online): a one-way communication in which the government produces and delivers information through various online channels for public use by citizens. This encompasses both \"reactive\" access to information - on demand from citizens, and \"proactive\" government measures to disseminate information to citizens;",
          four:             "•\tE-consultations: two-way communication in which citizens provide feedback to the government using online tools. The interaction is based on the government predetermining the issues on which citizens' opinions are sought and which require information;",
          five:             "•\tE-partnership: a relationship based on partnership with the government, in which citizens actively participate in the policy-making process through various online tools. This stage recognizes the role of citizens in proposing policy options and shaping policy dialogue, although the responsibility for the final decision or policy formulation lies with the government.",
        },
        international:   {
          sideTitleLeft:    "International",
          sideTitleRight:   "standards",

          pageTitle1:       "International",
          pageTitle2:       "standards",

          one:              "Currently, there are no generally accepted international standards that could clearly indicate the need for all countries to follow common rules in the implementation of the \"electronic (digital) state\". However, the basic principles on which citizen e-participation in government should be based can be gleaned from existing documents.",
          two:              "The Convention for the Protection of Human Rights and Fundamental Freedoms , paragraph 10, declares \"The right of everyone to freely express and hold opinions and to impart information and ideas...\"; ",
          three:            "The International Covenant on Civil and Political Rights (ICCPR)  declares in Article 19 that \"Everyone shall have the right to hold opinions without interference... shall have the right to freedom of expression; this right shall include freedom to seek, receive and impart information of all kinds...\";",
          four:             "The ICCPR declares in Article 25 that \"Every citizen shall have ... the right and opportunity ... to take part in the conduct of public affairs...; ",
          five:             "The International Covenant on Economic, Social and Cultural Rights (ICESCR)  declares in Article 15 that \"States recognize the right of everyone to enjoy the benefits of scientific progress and its applications.;",
          six:              "The UN Convention on the Use of Electronic Communications in International Contracts  in Article 8 recognizes the legal validity of digital messages;",
          seven:            "●\tIn 2012, the UN Human Rights Council for the first time adopted a resolution , on freedom of expression on the Internet, which, among other things, affirms that the same rights that one has offline should also be protected online, in particular the right to freedom of expression.",
        },
        national:        {
          sideTitleLeft:    "National",
          sideTitleRight:   "standards",
          pageTitle1:       "National",
          pageTitle2:       "standards",

          one:              "Law of the Republic of Kazakhstan № 401-V of November 16, 2015 \"On Access to Information\", Art. 10, contains one of the definitions of access to information as \"access to information posted on the relevant components of the e-government web portal\".",
          two:              "Law of the Republic of Kazakhstan № 418-V of November 24, 2015 \"On Informatization, Art. 19, defines the types of services provided in digital form, as well as regulates the functionality of \"e-government\" in Art. 21 - 31.",
          three:            "State program \"Digital Kazakhstan\", Approved by the Government of the Republic of Kazakhstan Decree of 12.12.2017 № 827, as amended by the Government of the Republic of Kazakhstan Decree of 20.12.2019 № 949. The program describes the services that are planned to be supported by the functionality of \"e-government\", and notes the problems that require additional attention.",
        },
        ep2020:          {
          pageTitle1:       "Situation in Kazakhstan",
          pageTitle2:       "as of 2020",

          one:              "In 2020 Kazakhstan ranked 29th out of 193 countries in the E-Participation Index , which is compiled by the UN. This shows significant progress, since Kazakhstan ranked 42nd in this ranking in 2018. The index of e-participation to a greater extent reflects the real requests of citizens to the national system of e-government: the degree of information openness of the state and mechanisms for providing citizens with public information, the level of citizen involvement in consultations with government agencies and involving people in discussions of public policy issues, as well as effective mechanisms for citizen participation in decision-making processes online.",
          two:              "The state program \"Digital Kazakhstan\" as one of the areas of implementation sets the task of \"transition to a digital state\" - the transformation of state functions as an infrastructure for providing services to the public and businesses. Ideally, the model should be implemented when it is not the citizen who applies to government agencies for services, but the state, understanding the needs of citizens, contacts them to provide public services without the need to physically visit institutions. ",
          three:            "Kazakhstan now has an e-Government portal, eGov , which contains the \"e-participation\" tab . The tab gives its own, somewhat different definition of e-participation from that adopted by the UN: \"E-participation is the process of engaging citizens in policy, decision-making, and service design and delivery through information and communications technology\". ",
          four:             "Electronic digital signatures - EDS were introduced in Kazakhstan in 2003, in order to use the portal and receive public services in digital form .",
          five:             "The Open Government Portal is an e-participation tool , which, in turn, is an aggregator for several portals:",
          six:              "Open Data portal , allows the public to find, download and use datasets that are created and continually updated by government agencies;",
          seven:            "Open LSI portal , designed to submit draft concepts of draft laws and draft normative legal acts for public discussion. Here it should be noted that due to the low level of public awareness of how comments affect the final text of a draft normative act, there is no culture of broad participation in the discussion procedure. In addition, as a rule, amendments are made to already existing legislative acts, and comments on amendments to normative legal acts are often left without consideration, because they contradict the previously adopted concept, which does not change when amendments are made.",
          eight:             "Open Dialogue , where users can directly submit an appeal and send suggestions to a specific state body or local akimat, report on the quality of the cellular network, participate in socially important surveys. Only individual appeals are accepted on the portal, as there is no institute of collective appeals in the legislation of Kazakhstan, as well as petitions mandatory for consideration according to a certain established procedure. There are no known cases when a petition published on petition-aggregator sites would lead to a change in policy - state authorities do not consider them as legitimate requests from the population and do not consider it necessary to make official statements about them.",
          nine:              "Open Budgets , where draft budget programs are published prior to their approval, so that citizens can make changes in the process of spending budgetary funds, as well as the results of state audits and financial audits. As in the portal Open NLAs, the question of how and to what extent citizens' comments are taken into account in the discussion, implementation and evaluation of the effectiveness of budget programs remains open;",
          ten:           "State procurement portal , where citizens can participate in state tenders. The portal contains data on available lots, concluded contracts, public procurement plans, as well as information on participants, tax deductions and unfair suppliers.",
          eleven:           "The fact that for one-time requests to the portals of \"Open Government\" it is not always necessary to use EDS; some requests can be confirmed with a password sent to a cell phone can be considered positive. According to the information posted on the website of NIT JSC, the operator of the information and communication infrastructure of e-government, 45 services are available on the portal of e-government without an electronic digital signature by entering a one-time password . ",
          twelve:         "Like any other Internet service, the \"e-Government\" service occasionally experiences problems with access. This is due (in some cases) to the large number of requests. For example, during the coronavirus outbreak and the declaration of a national emergency in the spring of 2020, the government took measures to support citizens who lost income due to the quarantine declaration. Among the measures taken was the payment of an allowance equal to the minimum wage for citizens who lost income. Registering for benefits was required on the eGov website, and the site couldn't handle the number of requests. More than 8 million citizens applied for the benefit, with up to 25,000 applications made at a time . Under these conditions, the leadership of the NC JSC \"State Corporation \"Government for Citizens\" (the operator of the eGov website) was forced to organize the work of telegram bots and launch a special website to receive appeals. Problems with access to the portal lasted more than a week.",
          thirteen:         "The operators of the eGov website have learned from this situation. On the eGov portal in the section \"Work and Employment\" there is a tab \"Appointment of social payment 42500\", by clicking on which the user receives instructions on the actions to be taken in order to submit an application . When in connection with the announcement of repeated quarantine, on July 14, 2020, the Prime Minister instructed to make a payment in the amount of 42500 tenge to citizens who lost income due to quarantine restrictions , it became possible to apply for a one-time payment both through the web portal of e-government and portal Enbek.kz, and through telegram-bot e-government, mobile applications and Internet resources of second-tier banks.",
          fourteen:          "With the undoubtedly positive approach of implementing and widely popularizing Open Government services, users may have concerns about their electronic security. When receiving an electronic digital signature, users also receive a security certificate, which affects all electronic traffic. On the other hand, not having EDS and a security certificate on their computer, users lose access from their computer to some of the \"Open Government\" services - for example, to the portal of performance evaluation of state agencies .",
          fifteen:          "On December 6, 2020, the Ministry of Digital Development, Innovation and Aerospace Industry (MDDIAI), together with the National Security Committee (NSC), held the Nur-Sultan 2020 cybersecurity exercises . During these exercises, many users encountered problems with access to some resources - social networks, messengers, and other sites . Experts attribute these problems to a new attempt to introduce a security certificate, which may turn out to be a means of monitoring traffic by government agencies.",
        },
        recommendations: {
          sideTitleLeft:    "Recommendations",
          sideTitleRight:   "",
          sideTitleMidLeft: "",

          pageTitle1:       "Recommendations",
          pageTitle2:       "",

          one:              "Undertake efforts to convert as many public services as possible into digital format, both using existing tools and creating new ones.",
          two:              "Improve the legal framework for citizens to more fully exercise their rights in the electronic environment, including the implementation of the possibility of collective appeal and petition.",
          three:            "Respect and protect the right of civil society and individual activists to speak critically about e-government and other issues of public debate.",
          four:             "Catalog participation tools, analyze statistics on their use, effectiveness, and online representation. Identify, through broad citizen participation, the need for new tools.",
          five:             "Formalize procedures for reviewing comments on draft laws and budgets, taking into account the requirements of inclusiveness and transparency.",
          six:              "Simplify and visualize information about legislative, budgetary and other initiatives presented on e-government portals.",
          seven:            "Analyze the functioning of e-government in the COVID-19 pandemic and use the results of the analysis further.",
          eight:             "Widely use new technological solutions that facilitate access to information and its protection; maximize the use of links through QR-codes, social media and bots in messengers to address standard services, the use of one-time passwords instead of EDS, blockchain solutions for data protection. ",
          nine:              "Initiate a broad citizen advocacy program to reform and expand e-participation.",
          ten:           "Create manuals and information resources for CSOs and activists to disseminate positive experiences and successful examples of participatory mechanisms.",
          eleven:           "Make efforts to build the expertise of CSOs and activists to work in existing e-participation mechanisms.",

          smallDesc1:       "To the Government of the Republic of Kazakhstan:",
          smallDesc2:       "NC JSC \"State Corporation Government for Citizens\":",
          smallDesc3:       "Civil Society Organizations of the Republic of Kazakhstan: ",
        },
      },
      kaz: {
        entrance:        {
          sideTitleLeft:    "Электронды ",
          sideTitleRight:   "қатысу",
          sideTitleMidLeft: "",

          pageTitle1:       "Кіріспе",
          pageTitle2:       "",

          one:              "Электронды қатысу деп «азаматтарды ақпараттық және коммуникациялық технологиялар (АКТ) көмегімен саясатқа, шешімдер қабылдауға, қатысуды, инклюзивтілікті және мақсаттылықты қамтамасыз ету мақсатында қызметтерді әзірлеуге және көрсетуге тарту процесі» түсініледі . Әрине, бұл процесті жүзеге асыру «интернетке кіру құқығын», яғни электрондық қатысу жүзеге асырылатын ортаның қол жетімділігін және «ақпаратқа қол жеткізу құқығын» талап етеді, Бұл электронды қатысуды жүзеге асыруға мүмкіндік беретін ақпараттың көпшілікке қол жетімділігін білдіреді. Сонымен қатар, электрондық қатысуды заңды ететін нормативтік-құқықтық базаның болуы қажет.",
          two:              "Егер біз осы тұжырымдаманы электрондық демократия саласына ауыстырсақ, келесі кезеңдерді бөліп көрсетуге болады :",
          three:            "Электрондық ақпараттандыру (онлайн режимінде ақпарат беру): үкімет азаматтардың көпшілік алдында пайдалануы үшін түрлі онлайн-арналар арқылы ақпарат өндіретін және жеткізетін біржақты байланыс. Бұл азаматтардың талабы бойынша ақпаратқа «реактивті» қолжетімділікті де, сондай-ақ азаматтардың арасында ақпаратты тарату бойынша үкіметтің «белсенді» шараларын да қамтиды;",
          four:             "Электрондық консультациялар: азаматтар онлайн-құралдар арқылы үкіметке кері байланыс ұсынатын екіжақты байланыс. Өзара іс-қимыл азаматтардың пікірлері сұратылатын және ақпарат беруді талап ететін мәселелерді үкіметтің алдын ала айқындауына негізделген;",
          five:             "Электрондық серіктестік: үкіметпен серіктестікке негізделген қатынастар, онда азаматтар әртүрлі онлайн-құралдар арқылы саясатты қалыптастыру процесіне белсенді қатысады. Бұл кезеңде азаматтардың саясат нұсқаларын ұсынудағы және саяси диалогты қалыптастырудағы рөлі мойындалады, дегенмен түпкілікті шешім немесе саясатты тұжырымдау үшін жауапкершілік үкіметке жүктеледі.",

        },
        international:   {
          sideTitleLeft:    "Халықаралық ",
          sideTitleRight:   "стандарттар",
          sideTitleMidLeft: "",

          pageTitle1:       "Халықаралық ",
          pageTitle2:       "стандарттар",

          one:              "Қазіргі уақытта «электронды (цифрлы) мемлекетті» енгізу кезінде барлық елдердің ортақ қағидаларды ұстану қажеттігін біржақты көрсетуі мүмкін жалпыға бірдей қабылданған халықаралық стандарттар жоқ. Алайда, қолда бар құжаттардан азаматтардың мемлекетті басқаруға электрондық қатысуы құрылуы тиіс негізгі қағидаттарды алуға болады",
          two:              "Адам құқықтары мен негізгі бостандықтарды қорғау  туралы конвенцияның 10-тармағы «Әр адамның өз пікірін еркін білдіруге және ұстауға, сондай-ақ ақпарат пен идеяларды таратуға құқығы ...» деп жариялайды;",
          three:            "АСҚХП  19-бапта: «Әр адам өз пікірін кедергісіз ұстануға құқылы... өз пікірін еркін білдіруге құқылы; бұл құқық кез-келген ақпаратты іздеу, алу және тарату бостандығын қамтиды…»;",
          four:             "25-бапта АСҚХП «Әрбір азаматтың мемлекеттік істерді жүргізуге қатысу құқығы мен мүмкіндігі болуы тиіс» деп мәлімдейді…;",
          five:             "15-баптағы ЭӘМХП  «мемлекеттер әрбір адамның ғылыми прогресс нәтижелерін пайдалану және оларды іс жүзінде қолдану құқығын таниды» деп мәлімдейді;",
          six:              "8-бапта халықаралық шарттарда электрондық хабарларды пайдалану туралы БҰҰ конвенциясы  электрондық хабарлардың заңды күшін таниды. 4-бапта ҚР Конституциясы халықаралық құқықтың басымдығын мойындайтындықтан, электрондық хабарламалардың заңды күші туралы норма қазақстандық юрисдикцияға да таралады;",
          seven:            "2012 жылы БҰҰ-ның Адам құқықтары жөніндегі кеңесі алғаш рет интернеттегі сөз бостандығына арналған қарар қабылдады , оның ішінде адамның офлайн ортадағы құқықтары да онлайн режимінде қорғалуы керек, атап айтқанда сөз бостандығы құқығы",
        },
        national:        {
          sideTitleLeft:    "Ұлттық ",
          sideTitleRight:   "стандарттар",
          sideTitleMidLeft: "",

          pageTitle1:       "Ұлттық ",
          pageTitle2:       "стандарттар",

          one:              "ҚР ұлттық заңнамасында электрондық қатысуды іске асыруға мүмкіндік беретін бірнеше негізгі нормативтік құқықтық актілер (НҚА) бар:",
          two:              "Қазақстан Республикасының 2015 жылғы 16 қарашадағы «Ақпаратқа қол жеткізу туралы» Заңының 10-бабында, «электрондық үкімет» веб-порталының тиісті компоненттерінде орналастырылған ақпаратқа қол жеткізу «ретінде ақпаратқа қол жеткізу анықтамаларының бірін қамтиды»",
          three:            "«Ақпараттандыру туралы» Қазақстан Республикасының 2015 жылғы 24 қарашадағы № 418-V Заңының 19 – бабы, электрондық нысанда көрсетілетін қызмет түрлерін айқындауды қамтиды, сондай-ақ 21-31-баптағы «электрондық үкіметтің» функционалын регламенттейді.",
          four:             "ҚР Үкіметінің 12.12.2017 № 827 қаулысымен бекітілген «цифрлық Қазақстан» мемлекеттік бағдарламасына өзгерістер енгізілді-ҚР үкіметінің 20.12.2019 № 949 қаулысымен. Бағдарламада «электрондық үкімет» функционалының көмегімен қолдау жоспарланатын сервистер сипатталған және оларды шешу қосымша назар аударуды талап ететін проблемалар атап көрсетілген.",
        },
        ep2020:          {
          pageTitle1:       "2020 жылы ",
          pageTitle2:       "Қазақстандағы жағдай",

          one:              "БҰҰ құрастыратын электрондық қатысу индексінің (E-Participation Index)  рейтингінде Қазақстан 2020 жылы 193 елдің ішінде 29-орынды иеленді. Бұл айтарлықтай прогресті көрсетеді, өйткені 2018 жылы Қазақстан бұл рейтингте 42-орынды иеленді. Электрондық қатысу индексі көбінесе азаматтардың электрондық үкіметтің ұлттық жүйесіне нақты сұраныстарын көрсетеді: мемлекеттің ақпараттық ашықтық дәрежесі және азаматтарға жария ақпарат беру тетіктері, азаматтарды мемлекеттік органдармен консультацияға тарту және адамдарды мемлекеттік саясат мәселелерін талқылауға тарту деңгейі, сондай-ақ азаматтардың онлайн шешімдер қабылдау процестеріне қатысуының тиімді тетіктері.",
          two:              "«Цифрлы Қазақстан» мемлекеттік бағдарламасы іске асыру бағыттарының бірі ретінде халыққа және бизнеске қызметтер көрсету инфрақұрылымы ретінде мемлекеттің функцияларын қайта құру –«Цифрлы мемлекетке көшу» міндетін қойып отыр. Бұл ретте, қызмет алу үшін азамат мемлекеттік органдарға жүгінбесе, ал мемлекет азаматтардың қажеттіліктерін түсіне отырып, мекемелерге нақты бару қажеттілігінсіз мемлекеттік қызмет көрсету үшін олармен байланысатын үлгі іске асырылуға тиіс.",
          three:            "Қазіргі уақытта Қазақстанда eGov  электронды үкімет порталы жұмыс істейді, онда «Электронды қатысу»  қойындысы бар. Қыстырма БҰҰ қабылдағаннан біршама өзгеше электронды қатысудың анықтамасын береді: «Электронды қатысу дегеніміз – бұл азаматтарды саясатқа тарту, шешім қабылдау, сондай-ақ ақпараттық-коммуникациялық технологияларды қолдану арқылы қызметтерді әзірлеу және ұсыну процесі».",
          four:             "2003 жылы Қазақстанда порталды пайдалану және мемлекеттік қызметтерді электронды түрде алу үшін электрондық цифрлық қолтаңба – ЭЦҚ енгізілді .",
          five:             "Электрондық қатысу құралы Ашық үкімет  порталы болып табылады, ол өз кезегінде бірнеше портал үшін агрегатор болып табылады:",
          six:              "Мемлекеттік органдар құратын және үнемі толтыратын деректер жиынтығын жұртшылық таба алатын, жүктей алатын және пайдалана алатын ашық деректер порталы ;",
          seven:            "Заң жобалары тұжырымдамаларының жобаларын және нормативтік құқықтық актілердің жобаларын қоғамдық талқылауға шығаруға арналған Ашық НҚА порталы. Бұл жерде қоғамның түсініктемелердің нормативтік акт жобасының соңғы мәтініне қалай әсер ететіні туралы аз хабардар болуына байланысты талқылау рәсіміне кеңінен қатысу мәдениеті жоқ екенін атап өткен жөн. Бұдан басқа, әдетте, қолданыстағы заңнамалық актілерге өзгерістер енгізіледі және НҚА-ға  түзетулерге түсініктемелер көбінесе қараусыз қалады, өйткені олар түзетулер енгізілген кезде өзгермейтін бұрын қабылданған тұжырымдамаға қайшы келеді.",
          eight:             "Ашық диалог порталы , онда пайдаланушылар тікелей өтініш бере алады және нақты мемлекеттік органға немесе жергілікті әкімдікке ұсыныстар жібере алады, ұялы желінің сапасы туралы хабарлайды, әлеуметтік маңызды сауалнамаларға қатыса алады. Порталда тек жеке өтініштер қабылданады, өйткені қазақстандық заңнамада ұжымдық өтініштер институты жоқ, сондай-ақ белгілі бір белгіленген петиция рәсімі бойынша қарау үшін міндетті. Петиция агрегаторларының сайттарында жарияланған петиция саясаттың өзгеруіне әкелетін бірде-бір жағдай белгісіз – мемлекеттік органдар оларды халықтан заңды сұрау ретінде қарастырмайды және олар туралы ресми мәлімдемелер жасау қажет деп санамайды.",
          nine:              "Азаматтар бюджет қаражатын жұмсау процесіне, сондай-ақ мемлекеттік аудит және қаржылық тексеру нәтижелеріне өзгерістер енгізе алуы үшін бюджеттік бағдарламалардың жобалары бекітілгенге дейін жарияланатын Ашық бюджеттер порталы . Ашық НҚА порталындағыдай, бюджеттік бағдарламаларды талқылау, іске асыру және тиімділігін бағалау кезінде азаматтардың түсініктемелері қаншалықты және қалай ескеріледі деген мәселе ашық күйінде қалып отыр;",
          ten:           "Мемлекеттік сатып алу порталы , онда азаматтар мемлекеттік тендерлерге қатыса алады. Портал қолда бар лоттар, жасалған шарттар, мемлекеттік сатып алу жоспарлары туралы деректерді, сондай-ақ қатысушылар, салық аударымдары және жосықсыз өнім берушілер туралы мәліметтерді қамтиды.",
          eleven:           "«Ашық үкімет» порталына бір реттік жүгіну үшін әрдайым ЭЦҚ-ны пайдалану қажет емес, сұраныстардың бір бөлігін ұялы телефонға жіберілетін пароль арқылы растауға болады. «ҰАТ» АҚ сайтында орналастырылған ақпаратқа сәйкес – электрондық үкіметтің ақпараттық-коммуникациялық инфрақұрылымының операторы– электрондық үкімет порталында электрондық цифрлы қолтаңбасыз бір реттік парольді енгізу арқылы 45 қызмет қолжетімді .",
          twelve:         "Кез келген басқа интернет-сервис сияқты, «электрондық үкімет» сервисі де жүйелі түрде қолжетімділікке байланысты проблемаларды бастан кешуде. Бұл (кейбір жағдайларда) көптеген қоңырауларға байланысты. Сонымен, коронавирустың өршуі және 2020 жылдың көктемінде елде төтенше жағдай жарияланған кезде Үкімет карантинге байланысты кірістерін жоғалтқан азаматтарды қолдау шараларын қабылдады. Қабылданып жатқан шаралардың ішінде табысынан айырылған азаматтар үшін ең төменгі жалақы мөлшерінде жәрдемақы төлеу болды. Жәрдемақы алу үшін eGov сайтында тіркелу қажет болды және сайт өтініштер санын жеңе алмады. Жәрдемақы төлеуге 8 миллионнан астам азамат өтініш берді, бір уақытта 25 мыңға дейін өтініш берілді . Осы жағдайларда «азаматтарға арналған үкімет», «МК» КЕАҚ басшылығы (eGov сайтының операторы) telegram-боттардың жұмысын ұйымдастыруға және өтініштерді қабылдау үшін арнайы сайтты іске қосуға мәжбүр болды. Порталға кіру проблемалары бір аптадан астам уақытқа созылды.",
          thirteen:         "EGov сайтының операторлары осы жағдайдан сабақ алды. Электрондық үкімет порталының «жұмысқа орналастыру және жұмыспен қамту» бөлімінде «42500 әлеуметтік төлемді тағайындау» қосымша беті пайда болды, ол бойынша пайдаланушы өтінім беру үшін қабылдауы тиіс іс-әрекеттер туралы нұсқаулық алады .Екінші карантинді жариялауға байланысты 2020 жылдың 14 шілдесінде Премьер-Министр карантиндік шектеулер  салдарынан кірістерін жоғалтқан азаматтарға 42500 теңге көлемінде төлем жасауды тапсырған кезде, біржолғы төлемді тағайындау үшін «Электрондық үкіметтің» веб-порталы арқылы және Enbek.kz порталы арқылы, сондай-ақ электронды үкіметтің жеделхат-боты, екінші деңгейлі банктердің мобильді қосымшалары мен интернет-ресурстары арқылы өтініш беру мүмкіндігі пайда болды. ",
          fourteen:          "«Ашық үкімет» сервистерін енгізудің және кеңінен танымал етудің сөзсіз оң көзқарасымен пайдаланушылар өздерінің электрондық қауіпсіздігі үшін қауіптенуі мүмкін. Электрондық цифрлық қолтаңбаны алған кезде пайдаланушылар барлық электрондық трафикке әсер ететін қауіпсіздік сертификатын алады. Екінші жағынан, өз компьютерінде ЭЦҚ және қауіпсіздік сертификаты болмаса, пайдаланушы өз компьютерінен «Ашық үкіметтің» кейбір сервистеріне – мысалы, мемлекеттік органдар қызметінің тиімділігін бағалау порталына қол жеткізуден айырылады .",
          fifteen:          "2020 жылғы 6 желтоқсанда цифрлық даму, инновациялар және аэроғарыш өнеркәсібі министрлігі (ЦДИАӨМ) Ұлттық қауіпсіздік комитетімен (ҰҚК) бірлесіп «Нұр-Сұлтан-2020 киберқауіпсіздік» оқу-жаттығуын өткізді . Осы жаттығулар барысында көптеген пайдаланушылар кейбір ресурстарға – әлеуметтік желілерге, мессенджерлерге және басқа сайттарға қол жеткізуде қиындықтарға тап болды . Сарапшылар бұл проблемаларды мемлекеттік органдар тарапынан трафикті бақылау құралы болуы мүмкін қауіпсіздік сертификатын енгізудің жаңа әрекетімен байланыстырады.",
        },
        recommendations: {
          sideTitleLeft:    "Ұсынымдар",
          sideTitleRight:   "",
          sideTitleMidLeft: "",

          pageTitle1:       "Ұсынымдар",
          pageTitle2:       "",

          one:              "Қолда бар құралдарды пайдалана отырып, сондай-ақ жаңаларын жасай отырып, мемлекеттік көрсетілетін қызметтердің барынша көп санын электрондық форматқа көшіру бойынша күш-жігер жұмсауға;",
          two:              "Азаматтардың электрондық ортада өз құқықтарын толық жүзеге асыруы үшін, соның ішінде ұжымдық жүгіну және петиция беру мүмкіндігін іске асыру үшін заңнамалық базаны жетілдіру;",
          three:            "Азаматтық қоғам мен жекелеген белсенділердің электрондық үкімет жұмысы туралы және қоғамдық талқылау сипатындағы басқа да мәселелер бойынша сыни пікір білдіру құқығын құрметтеу және қорғау.",
          four:             "Қатысу құралдарын каталогтау, оларды қолдану, тиімділігі және онлайн режимінде статистиканы талдау жүргізу. Кең азаматтық белсенділік арқылы жаңа құралдардың қажеттілігін анықтау;",
          five:             "Инклюзивтілік пен ашықтық талаптарын ескере отырып, заң жобалары мен бюджеттерге ескертулерді қарау рәсімдерін рәсімдеу;",
          six:              "Электрондық үкімет порталдарында ұсынылған заңнамалық, бюджеттік және басқа бастамалар туралы ақпаратты жеңілдету және визуалдау;",
          seven:            "COVID-19 пандемиясы аясында электрондық үкіметтің жұмысына талдау жүргізу және болашақта талдау нәтижелерін қолдану;",
          eight:             "Ақпаратқа қол жетімділікті және оны қорғауды жеңілдететін жаңа технологиялық шешімдерді; стандартты қызметтерге жүгіну үшін мессенджерлердегі QR кодтары, әлеуметтік медиа және боттар арқылы сілтемелердің мүмкіндіктерін пайдалану, ЭЦҚ орнына бір реттік парольдерді пайдалану, деректерді қорғауға арналған блокчейн шешімдерін кеңінен қолдану.",
          nine:              "Электрондық қатысуды реформалау және кеңейту бойынша кең азаматтық адвокаттық бағдарламаны бастаңыз;",
          ten:           "АҚҰ және белсенділер үшін, қатысу тетіктерінің жұмысының оң тәжірибесі мен табысты мысалдарын тарату үшін құралдар мен ақпараттық ресурстар жасау;",
          eleven:           "Электронды қатысу тетіктерінде жұмыс істеу үшін АҚҰ мен белсенділердің экспорттық әлеуетін арттыруға күш салу.",

          smallDesc1:       "Қазақстан Республикасының Үкіметіне:",
          smallDesc2:       "«Азаматтарға арналған үкімет» КЕАҚ:",
          smallDesc3:       "Қазақстан Республикасының Азаматтық қоғам ұйымдарына:",
        },
      },
    },
  },
}
