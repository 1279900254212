import React        from "react";
import fileEmpty    from "../assets/images/file-img.svg";

function FileImg({number, link}) {
  const goToPage = (_path) => {
    window.location.href = _path;
  }
  return (
    <div onClick={() => goToPage(`/resources#${number}`)} className="file">
      <img className="fileEmpty" alt="fileImg" src={fileEmpty}/>
      <span className="file-span">{number}</span>
    </div>
  );
}

export default FileImg;
