import React, {useEffect, useState} from 'react';
import {dr}                         from '../../utils/digital-rights';
import vectorIcon                   from '../../assets/images/digital-rights/vector icon.svg'
import TopBanner                    from "../../components/top-banner";

function DRResults({lang}) {
    const [data, setData] = useState(dr.digitalRights.lang.ru.results)

    useEffect(() => {
        window.scrollTo(0, 0);
        if (lang === 'ru') {
            setData(dr.digitalRights.lang.ru.results)
        }
        if (lang === 'eng') {
            setData(dr.digitalRights.lang.eng.results)
        }
        if (lang === 'kaz') {
            setData(dr.digitalRights.lang.kaz.results)
        }
    }, [lang])
    return (
        <div className={'dr-container dr-results'}>
            <div className={'text-body'}>
                <span className="side-title left">{data.sideTitleLeft}</span>
                <span className="side-title right-middle">{data.sideTitleRight}</span>
                <span className="side-title left-bottom">{data.sideTitleMidLeft}</span>
                <h1>{data.pageTitle1}</h1>
                <h2>{data.pageTitle2}</h2>
                <div className={'box left-text first-text'}>
                    <p><img src={vectorIcon} className={'right-side-img lockIcon'} alt=""/>{data.one}</p>
                </div>
                <div className={'box right-text second-text'}>
                    <p>{data.two}
                    </p>
                </div>
                <div className="box left-text third-text">
                    <p>{data.three}
                    </p>

                </div>
                <div className="box right-text fourth-text">
                    <p>{data.four}</p>
                </div>
                <div className="box left-text text-5"><p>
                        {data.five}
                    </p></div>
                <div className="box right-text text-6"><p>
                    {data.six}
                </p></div>
                <div className="box left-text text-7"><p>
                    {data.seven}
                </p></div>
                <div className="box right-text text-8"><p>
                    {data.eight}
                </p></div>
                <div className="box left-text text-9"><p>
                    {data.nine}
                </p></div>
                <div className="box right-text text-10"><p>
                    {data.ten}
                </p></div>
                <div className="box left-text text-11"><p>
                    {data.eleven}
                </p></div>
            </div>
        </div>
    );
}

export default DRResults;
