import React, {useEffect, useState} from 'react';
import img1                         from '../../assets/images/freedom-expression/Group 43.svg';
import personImg                    from '../../assets/images/electronic-participation/person icon.svg';
import {limitOnInternet}                         from '../../utils/limit-on-internet';

function LimitEntrance({lang}) {
  const [data, setData] = useState(limitOnInternet.lang.ru.entrance)

  useEffect(() => {
    window.scrollTo(0, 0);
    if (lang === 'ru') {
      setData(limitOnInternet.lang.ru.entrance)
    }
    if (lang === 'eng') {
      setData(limitOnInternet.lang.eng.entrance)
    }
    if (lang === 'kaz') {
      setData(limitOnInternet.lang.kaz.entrance)
    }
  }, [lang])
  return (
    <div className="limit-container limit-entrance">
      <div className="text-body">
        <span className={'side-title left'}>{data.sideTitleLeft}</span>
        <span className={'side-title right'}>{data.sideTitleRight}</span>
        <h1>{data.pageTitle1}</h1>
        <div className={'box left-text first-text'}>
          <p> <img className="personImg" src={personImg} alt=""/>{data.one}</p>
        </div>
        <div className={'box right-text second-text'}>
          <p>{data.two}
          </p>
        </div>
        <div className={'box left-text third-text'}>
          <p>{data.three}
          </p>
        </div>
        <div className={'box right-text fourth-text'}>
          <p>{data.four}
          </p>
        </div>
        <div className={'box left-text fifth-text'}>
          <p>{data.five}
          </p>
        </div>
        <div className={'box right-text sixth-text'}>
          <p>{data.six}
          </p>
        </div>
        <img className="img1" src={img1} alt=""/>
      </div>
    </div>
  );
}

export default LimitEntrance;
