import React, {useEffect, useState} from 'react';
import groupPeopleImg               from '../../assets/images/freedom-expression/peopleGroup.svg';
import {newCalls}                   from '../../utils/new-calls';
import FileImg                      from "../../components/file-img";

function NcInternational({lang}) {
    const [data, setData] = useState(newCalls.lang.ru.international)

    useEffect(() => {
        window.scrollTo(0, 0);
        if (lang === 'ru') {
            setData(newCalls.lang.ru.international)
        }
        if (lang === 'eng') {
            setData(newCalls.lang.eng.international)
        }
        if (lang === 'kaz') {
            setData(newCalls.lang.kaz.international)
        }
    }, [lang])
    return (
        <div className="nc-container nc-international">
            <div className="text-body">
                <span className={'side-title left'}>{data.sideTitleLeft}</span>
                <span className={'side-title right'}>{data.sideTitleRight}</span>
                <div className="titles">
                    <h1>{data.pageTitle1}</h1>
                    <h2>{data.pageTitle2}</h2>
                </div>
                <div className={'box left-text first-text'}>
                    <p><img className="right-side-img" src={groupPeopleImg} alt="person avatar"/>{data.one}</p>
                    <FileImg number={102} link={"https://www.unglobalcompact.org/what-is-gc/mission/principles"}/>
                </div>
                <div className={'box right-text second-text'}>
                    <FileImg number={103} link={"https://www.un.org/ru/events/pastevents/pdf/dec_wsis.pdf"}/>
                    <p>{data.two}</p>
                </div>
                <div className={'box left-text third-text'}>
                    <p>{data.three}</p>
                    <FileImg number={104} link={"http://www.undocs.org/ru/A/HRC/8/5"}/>
                </div>
                <div className="box-list">
                    <div className="box row">
                        <span className={'side-number'}>1</span>
                        <p>{data.four}</p>
                    </div>
                    <div className="box row">
                        <span className={'side-number'}>2</span>
                        <p>{data.five}</p></div>
                    <div className="box row">
                        <span className={'side-number'}>3</span>
                        <p>{data.six}.</p></div>

                </div>

                <div className={'box left-text fourth-text'}>

                    <p>{data.seven}</p>
                    <FileImg number={105} link={"https://undocs.org/ru/A/HRC/RES/17/4"}/>
                </div>
                <div className={'box right-text fifth-text'}>
                    <FileImg number={106} link={"https://undocs.org/ru/A/HRC/RES/44/15"}/>
                    <p>{data.eight}
                    </p>

                </div>
                <div className={'box left-text fifth-text'}>
                    <p>{data.nine}
                    </p>
                    <FileImg number={107} link={"https://www.ohchr.org/Documents/HRBodies/HRCouncil/WGTransCorp/Session6/OEIGWG_Chair-Rapporteur_second_revised_draft_LBI_on_TNCs_and_OBEs_with_respect_to_Human_Rights.pdf"}/>
                </div>
            </div>
        </div>
    );
}

export default NcInternational;
