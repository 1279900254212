import React, {useEffect, useState} from 'react';
import personImg                    from '../../assets/images/electronic-participation/person icon.svg';
import img1                         from '../../assets/images/right-to-fair-trial/Group 39.svg';
import FileImg                      from '../../components/file-img';
import groupPeopleImg               from '../../assets/images/right-to-fair-trial/inetrnational standarts icon.svg';
import {rightsToFairTrial}          from '../../utils/rights-to-fair-trial';

function RightInternational({lang}) {
    const [data, setData] = useState(rightsToFairTrial.lang.ru.entrance)

    useEffect(() => {
        window.scrollTo(0, 0);
        if (lang === 'ru') {
            setData(rightsToFairTrial.lang.ru.entrance)
        }
        if (lang === 'eng') {
            setData(rightsToFairTrial.lang.eng.entrance)
        }
        if (lang === 'kaz') {
            setData(rightsToFairTrial.lang.kaz.entrance)
        }
    }, [lang])
    return (
            <div className="right-container right-entrance">
                <div className="text-body">
                    <span className={'side-title left'}>{data.sideTitleLeft}</span>
                    <span className={'side-title right'}>{data.sideTitleRight}</span>
                    <div className={"titles"}>
                        <h1>{data.pageTitle2}</h1>
                        <h2>{data.pageTitle3}</h2>
                    </div>

                    <div className="second-title">
                        <div className='wrap-box'>

                            <div className={'box right-text second-text'}>
                                <FileImg number={93} link={"https://www.un.org/ru/documents/decl_conv/conventions/pactpol.shtml"}/>
                                <p><img className='groupPeopleImg' src={groupPeopleImg} alt=""/>{data.two}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    );
}

export default RightInternational;
