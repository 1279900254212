import React, {useEffect, useState} from 'react';
import eyeImg                       from '../../assets/images/right-to-fair-trial/eye icon.svg';
import {fe}                         from '../../utils/freedom-expression';
import FileImg                      from "../../components/file-img";

function Fe2020({lang}) {
  const [data, setData] = useState(fe.freedomExpression.lang.ru.ep2020)

  useEffect(() => {
    window.scrollTo(0, 0);
    if (lang === 'ru') {
      setData(fe.freedomExpression.lang.ru.ep2020)
    }
    if (lang === 'eng') {
      setData(fe.freedomExpression.lang.eng.ep2020)
    }
    if (lang === 'kaz') {
      setData(fe.freedomExpression.lang.kaz.ep2020)
    }
  }, [lang])
  return (
    <div className="fe-container fe-2020">
      <div className="text-body">
        <span className={'side-title left first'}>2020</span>
        <span className={'side-title right second'}>2020</span>
        <span className={'side-title left third'}>2020</span>
        <span className={'side-title right fourth'}>2020</span>
        <span className={'side-title left fifth'}>2020</span>
        <div className="titles">
          <h1>{data.pageTitle1}</h1>
          <h2>{data.pageTitle2}</h2>
        </div>
        <div className={'box left-text first-text'}>
          <p>{data.one}</p>
        </div>
        <div className={'box right-text second-text'}>
          <FileImg number={35} link={"https://vlast.kz/novosti/40475-v-kazahstane-dekriminalizirovali-klevetu.html"}/>
          <p><img className="left-side-img" src={eyeImg} alt="person avatar"/>{data.two}</p>
        </div>
        <div className={'box left-text third-text'}>
          <p>{data.three}</p>
        </div>
        <div className={'box right-text fourth-text'}>
          <FileImg number={36} link={"https://qamqor.gov.kz/portal/page/portal/POPageGroup/Services/Pravstat"}/>
          <p>{data.four}</p>
        </div>
        <div className={'box left-text fifth-text'}>
          <p>{data.five}
          </p>
        </div>
        <div className={'box right-text sixth-text'}>
          <FileImg number={37} link={"https://rus.azattyq.org/a/30685818.html"}/>
          <p>{data.six}
          </p>
        </div>
        <div className={'box left-text seventh-text'}>
          <FileImg number={38} link={"http://www.ratel.kz/scandal/zhurnalista_zastavili_molchat"}/>
          <p>{data.seven}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Fe2020;
