import React, {useEffect, useState} from 'react';
import {Redirect, Route, Switch}    from 'react-router-dom';
import IaEntrance                   from './ia-entrance';
import IaInternational              from './ia-international';
import IaNational                   from './ia-national';
import Ia2020                       from './ia-2020';
import IaRecommendations            from './ia-recommendations';
import GoBack                       from '../../components/goBack';
import FiveNavs                     from '../../components/five-navs';
import {mainPage}                   from '../../utils/main-page';
import TopBanner                    from '../../components/top-banner';
import ThemeButton                  from '../../components/theme-button';


function InfoAccess({lang, navData, toggleTheme}) {
    return (
        <div className={'container'}>
            <GoBack lang={lang}/>
            <TopBanner title={navData.infoAccess}/>
            <Switch>
                <Route exact path={'/info-access'} render={props => <IaEntrance lang={lang}{...props} />}/>
                <Route exact path={'/info-access/international'} render={props => <IaInternational lang={lang}{...props} />}/>
                <Route exact path={'/info-access/national'} render={props => <IaNational lang={lang}  {...props} />}/>
                <Route exact path={'/info-access/2020'} render={props => <Ia2020 lang={lang} {...props} />}/>
                <Route exact path={'/info-access/recommendations'}
                       render={props => <IaRecommendations lang={lang} {...props} />}/>
                <Redirect to={'/not-found'}/>
            </Switch>
            <FiveNavs lang={lang} path={'info-access'}/>
            <ThemeButton toggleTheme={toggleTheme}/>
        </div>
    );
}

export default InfoAccess;
