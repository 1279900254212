import React, {useEffect, useState} from 'react';

import eyeImg            from '../../assets/images/right-to-fair-trial/eye icon.svg';
import {limitOnInternet} from '../../utils/limit-on-internet';
import FileImg           from "../../components/file-img";

function Limit2020({lang}) {
  const [data, setData] = useState(limitOnInternet.lang.ru.ep2020)

  useEffect(() => {
    window.scrollTo(0, 0);
    if (lang === 'ru') {
      setData(limitOnInternet.lang.ru.ep2020)
    }
    if (lang === 'eng') {
      setData(limitOnInternet.lang.eng.ep2020)
    }
    if (lang === 'kaz') {
      setData(limitOnInternet.lang.kaz.ep2020)
    }
  }, [lang])
  return (
    <div className="limit-container limit-2020">
      <div className="text-body">
        <span className={'side-title left'}>2020</span>
        <div className="titles">
          <h1>{data.pageTitle1}</h1>
          <h2>{data.pageTitle2}</h2>
        </div>
        <div className={'box left-text first-text'}>
          <p><img className="right-side-img eyeImg" src={eyeImg} alt="person avatar"/>{data.one}</p>
        </div>
        <div className={'box left-text second-text'}>
          <p>{data.two}:</p>
        </div>
        <div className={'box left-text third-text'}>
          <span className={'side-number'}>8</span>
          <p>Э{data.listOne.one}</p>
        </div>
        <div className={'box right-text fourth-text'}>
          <span className={'side-number'}>7</span>
          <p>{data.listOne.two}</p>
        </div>
        <div className={'box left-text fifth-text'}>
          <span className={'side-number'}>5</span>
          <p>{data.listOne.three}
          </p>
        </div>
        <div className={'box right-text sixth-text'}>
          <span className={'side-number'}>5</span>
          <p>{data.listOne.four}
          </p>
        </div>
        <div className={'box left-text seventh-text'}>
          <span className={'side-number'}>4</span>
          <p>{data.listOne.five}
          </p>
        </div>
        <div className={'box right-text seventh-text'}>
          <span className={'side-number'}>1</span>
          <p>{data.listOne.six}
          </p>
        </div>
        <div className={'box left-text seventh-text'}>
          <span className={'side-number'}>2</span>
          <p>{data.listOne.seven}
          </p>
        </div>
        <div className={'box box-list'}>
          <p>{data.three}
          </p>
        </div>

        <div className="list">
          <div className='box box-list'>
            <span className={'side-number'}>2242</span>
            <p>{data.listTwo.one}</p>
          </div>
          <div className='box box-list'>
            <span className={'side-number'}>759</span>
            <p>{data.listTwo.two}</p>
          </div>
          <div className='box box-list'>
            <span className={'side-number'}>323</span>
            <p>{data.listTwo.three}</p>
          </div>
          <div className='box box-list'>
            <span className={'side-number'}>206</span>
            <p>{data.listTwo.four}</p>
          </div>
          <div className='box box-list'>
            <span className={'side-number'}>31</span>
            <p>{data.listTwo.five}</p>
          </div>
          <div className='box box-list'>
            <span className={'side-number'}>27</span>
            <p>{data.listTwo.six}</p>
          </div>
          <div className='box box-list'>
            <span className={'side-number'}>17</span>
            <p>{data.listTwo.seven}</p>
          </div>
          <div className='box box-list'>
            <span className={'side-number'}>14</span>
            <p>{data.listTwo.eight}</p>
          </div>
          <div className='box box-list'>
            <span className={'side-number'}>6</span>
            <p>{data.listTwo.nine}</p>
          </div>
          <div className='box box-list'>
            <span className={'side-number'}>6</span>
            <p>{data.listTwo.ten}</p>
          </div>
        </div>

        <div className={'box left-text text'}>
          <p>{data.four}</p>
          <FileImg number={54} link={"https://www.gov.kz/memleket/entities/mdai/press/news/details/132113?lang=ru"}/>
        </div>

        <div className={'box right-text text'}>
          <FileImg number={55} link={"https://www.businessinsider.com/apple-google-mozilla-block-kazakhstan-governments-browser-spying-tool-2019-8"}/>
          <p>{data.five}</p>
        </div>

        <div className={'box left-text text'}>
          <p>{data.six}</p>
          <FileImg number={56} link={"https://www.youtube.com/watch?v=8Nk8f0kHS1Q&feature=youtu.be"}/>
        </div>

        <div className={'box right-text'}>
          <p>{data.seven}</p>
        </div>

        <div className={'box left-text text'}>
          <p>{data.eight}</p>
        </div>

        <div className={'box right-text text'}>
          <p>{data.nine}</p>
        </div>

        <div className={'box left-text text'}>
          <p>{data.ten}.</p>
        </div>

        <div className={'box right-text text'}>
          <FileImg number={57} link={"https://uchet.kz/news/informatsionnye-sistemy-komiteta-gosdokhodov-vnov-vne-dostupa/"}/>
          <p>{data.eleven}</p>
        </div>

      </div>
    </div>
  );
}

export default Limit2020;
