import React, {useEffect, useState} from 'react';
import img1                         from '../../assets/images/freedom-expression/Group 41.svg';
import personImg                    from '../../assets/images/electronic-participation/person icon.svg';
import {ia}            from '../../utils/info-access';

function IaEntrance({lang}) {
  const [data, setData] = useState(ia.infoAccess.lang.ru.entrance)

  useEffect(() => {
    window.scrollTo(0, 0);
    if (lang === 'ru') {
      setData(ia.infoAccess.lang.ru.entrance)
    }
    if (lang === 'eng') {
      setData(ia.infoAccess.lang.eng.entrance)
    }
    if (lang === 'kaz') {
      setData(ia.infoAccess.lang.kaz.entrance)
    }
  }, [lang])
  return (
    <div className="ia-container ia-entrance">
      <div className="text-body">
        <span className={'side-title left'}>{data.sideTitleLeft}</span>
        <span className={'side-title right'}>{data.sideTitleRight}</span>
        <h1>{data.pageTitle1}</h1>
        <div className={'box left-text first-text'}>
          <p><img className="right-side-img personImg" src={personImg} alt=""/>{data.one}</p>
        </div>

        <div className={'box right-text second-text'}>
          <img src={img1} className='img1' alt=""/>
          <p>{data.two}
          </p>
        </div>
      </div>
    </div>
  );
}

export default IaEntrance;
