import React, {useEffect, useState} from 'react';
import {Redirect, Route, Switch}    from 'react-router-dom';
import AboutEntrance                from './entrance';
import AboutWhat                    from './what';
import AboutAuthors                 from './authors';
import AboutResults                 from './results';
import GoBack                       from '../../components/goBack';
import NavButtons                   from '../../components/nav-buttons';
import TopBanner                    from '../../components/top-banner';
import {mainPage}                   from '../../utils/main-page';
import ThemeButton                  from '../../components/theme-button';

function About({lang, navData, toggleTheme}) {
    return (
        <div className={'container'}>
            <GoBack lang={lang}/>
            <TopBanner title={navData.about}/>
            <Switch>
                <Route exact path={'/about'} render={props => <AboutEntrance lang={lang} {...props} />}/>
                <Route exact path={'/about/what'} render={props => <AboutWhat lang={lang} {...props} />}/>
                <Route exact path={'/about/authors'} render={props => <AboutAuthors lang={lang} {...props} />}/>
                <Route exact path={'/about/results'} render={props => <AboutResults lang={lang} {...props} />}/>
                <Redirect to={'/not-found'}/>
            </Switch>
            <NavButtons lang={lang}/>
            <ThemeButton toggleTheme={toggleTheme}/>
        </div>
    );
}

export default About;
