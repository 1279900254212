import React, {useEffect, useState} from 'react';
import eyeImg                       from '../../assets/images/personal-security/eye icon.svg';
import squareImg                    from '../../assets/images/personal-security/Group 18.svg';
import {ps}                         from '../../utils/personal-security';
import FileImg                      from "../../components/file-img";

function Da2020({lang}) {
    const [data, setData] = useState(ps.personalSecurity.lang.ru.ps2020)

    useEffect(() => {
        window.scrollTo(0, 0);
        if (lang === 'ru') {
            setData(ps.personalSecurity.lang.ru.ps2020)
        }
        if (lang === 'eng') {
            setData(ps.personalSecurity.lang.eng.ps2020)
        }
        if (lang === 'kaz') {
            setData(ps.personalSecurity.lang.kaz.ps2020)
        }
    }, [lang])
    return (
        <div className={'da-container da-2020'}>
            <div className="text-body">
                <span className={'side-title left first'}>2020</span>
                <span className={'side-title right second'}>2020</span>
                <span className={'side-title left third'}>2020</span>
                <span className={'side-title right fourth'}>2020</span>
                <span className={'side-title left fifth'}>2020</span>
                <span className={'side-title right sixth'}>2020</span>
                <span className={'side-title left seventh'}>2020</span>
                <h1>{data.pageTitle1}</h1>
                <h2>{data.pageTitle2}</h2>
                <div className={'box left-text first-text'}>
                    <p>
                        <img className={'eyeImg'} src={eyeImg} alt=""/>
                        {data.one}
                    </p>
                    <FileImg number={16} link={"https://www.soros.kz/wp-content/uploads/2020/04/Personal_data_report.pdf"} />
                </div>
                <div className={'box right-text second-text'}>
                    <FileImg number={17} link={"https://www.soros.kz/ru/personal-data-protection-in-kz/"}/>
                    <p>{data.two}</p>
                </div>
                <div className={'box left-text third-text'}>
                    <p>{data.three}
                    </p>
                    <FileImg number={18} link={"https://forbes.kz/process/technologies/bolshoy_brat_po-kazahski_1582187734."}/>
                </div>
                <div className={'table'}>
                    <div className="firstPart">
                        <span className="left">{data.table.firstPart.left}</span>
                        <span className="left">{data.table.firstPart.right}</span>
                    </div>
                    <div className="table-box secondPart">
                        <div className="title">{data.table.secondPart.title}</div>
                        {data.table.secondPart.list.map((row) => (
                            <div className={'row'}>
                                <span className="left">{row.left}</span>
                                <span className="right">{row.right}</span>
                            </div>
                        ))}
                    </div>
                    <div className="table-box thirdPart">
                        <div className="title">{data.table.thirdPart.title}</div>
                        {data.table.thirdPart.list.map((row) => (
                            <div className={'row'}>
                                <span className="left">{row.left}</span>
                                <span className="right">{row.right}</span>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="small-desc">{data.smallDesc1}</div>
                <div className={'box right-text fourth-text'}>
                    <FileImg number={19} linl={"https://www.soros.kz/wp-content/uploads/2020/04/Personal_data_report.pdf"}/>
                    <p>
                        {data.four}
                    </p>
                </div>
                <div className={'box left-text fifth-text'}>
                    <p>{data.five}</p>
                    <FileImg number={20 } link={"Составлено автором на основе данных Министерства цифрового развития, инноваций и аэрокосмической промышленности РК на обращение № ЗТ-А-653 от 6 декабря 2020 года"}/>
                </div>
                <div className={'box right-text sixth-text'}>
                    <p>{data.six}
                    </p>
                </div>
                <div className={'box left-text seventh-text-text'}>
                    <p>{data.seven}</p>
                </div>
                <div className={'box right-text eighth-text'}>
                    <p>{data.eight}
                    </p></div>
            </div>
        </div>
    );
}

export default Da2020;
