import React, {useEffect, useState} from 'react';
import groupPeopleImg               from '../../assets/images/freedom-expression/peopleGroup.svg';
import img3                         from '../../assets/images/freedom-expression/Group 41.svg';
import squareImg                    from '../../assets/images/freedom-expression/squareImg.svg';
import {limitOnInternet}            from '../../utils/limit-on-internet';
import FileImg                      from "../../components/file-img";

function LimitInternational({lang}) {
  const [data, setData] = useState(limitOnInternet.lang.ru.international)

  useEffect(() => {
    window.scrollTo(0, 0);
    if (lang === 'ru') {
      setData(limitOnInternet.lang.ru.international)
    }
    if (lang === 'eng') {
      setData(limitOnInternet.lang.eng.international)
    }
    if (lang === 'kaz') {
      setData(limitOnInternet.lang.kaz.international)
    }
  }, [lang])
  return (
    <div className="limit-container limit-international">
      <div className="text-body">
        <span className={'side-title left'}>{data.sideTitleLeft}</span>
        <span className={'side-title right'}>{data.sideTitleRight}</span>
        <div className="titles">
          <h1>{data.pageTitle1}</h1>
          <h2>{data.pageTitle2}</h2>
        </div>
        <div className={'box left-text first-text'}>
          <p>{data.one}</p>
          <FileImg number={44} link={"https://www.un.org/ru/documents/decl_conv/conventions/pactpol.shtml"}/>
        </div>
        <div className={'box right-text second-text'}>
          <FileImg number={45} link={"https://www.un.org/ru/documents/decl_conv/declarations/declhr.shtml"}/>
          <p>{data.two}</p>
        </div>
        <div className={'box left-text third-text'}>
          <p><img className="right-side-img groupPeopleImg" src={groupPeopleImg} alt="person avatar"/>{data.three}</p>
          <FileImg number={46} link={"http://www.itu.int/net/wsis/outcome/booklet/tunisru.html"}/>
        </div>
        <div className={'box right-text fourth-text'}>
          <FileImg number={47} link={"https://digitallibrary.un.org/record/706331/files/A_HRC_17_27-RU.pdf"}/>
          <p>{data.four}</p>
        </div>
        <div className={'box left-text fifth-text'}>
          <p>{data.five}
          </p>
        </div>
        <div className={'box right-text sixth-text'}>
          <p>{data.six}
          </p>
        </div>
        <div className={'box left-text seventh-text'}>
          <p>{data.seven}
          </p>
          <img className="img3" src={img3} alt="file"/>
        </div>
        <div className={'box right-text eighth-text'}>
          <p>{data.eight}
          </p>
        </div>
      </div>
    </div>
  );
}

export default LimitInternational;
