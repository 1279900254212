import React, {useEffect, useState} from 'react';
import personImg                    from '../../assets/images/electronic-participation/person icon.svg';


import {newCalls} from '../../utils/new-calls';
import FileImg    from "../../components/file-img";

function NcEntrance({lang}) {
  const [data, setData] = useState(newCalls.lang.ru.entrance)

  useEffect(() => {
    window.scrollTo(0, 0);
    if (lang === 'ru') {
      setData(newCalls.lang.ru.entrance)
    }
    if (lang === 'eng') {
      setData(newCalls.lang.eng.entrance)
    }
    if (lang === 'kaz') {
      setData(newCalls.lang.kaz.entrance)
    }
  }, [lang])
  return (
    <div className='nc-container nc-entrance'>
      <div className="text-body">
        <span className={'side-title left'}>{data.sideTitleLeft}</span>
        <span className={'side-title right'}>{data.sideTitleRight}</span>
        <h1>{data.pageTitle1}</h1>
        <div className={'box left-text first-text'}>
          <p><img className='right-side-img' src={personImg} alt="person avatar"/>{data.one}</p>
        </div>
        <div className={'box right-text second-text'}>
          <p>{data.two}</p>
        </div>
        <h3>{data.smallDesc1}</h3>
        <div className={'box left-text third-text'}>
          <p>{data.three}</p>
        </div>
        <div className={'box right-text fourth-text'}>
          <p>{data.four}</p>
        </div>
        <div className={'box left-text fifth-text'}>
          <p>{data.five}
          </p>
        </div>
        <div className={'box right-text sixth-text'}>
          <p>{data.six}
          </p>
        </div>
        <div className={'box left-text seventh-text'}>
          <FileImg number={101} link={"http://journals.rudn.ru/informatization-education/article/view/22165/ru_RU"}/>
          <p>{data.seven}
          </p>
        </div>
      </div>
    </div>
  );
}

export default NcEntrance;
