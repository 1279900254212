import React, {useEffect, useState} from 'react';
import eyeImg                       from '../../assets/images/right-to-fair-trial/eye icon.svg';
import {newCalls}                   from '../../utils/new-calls';
import FileImg                      from "../../components/file-img";

function Nc2020({lang}) {
  const [data, setData] = useState(newCalls.lang.ru.ep2020)

  useEffect(() => {
    window.scrollTo(0, 0);
    if (lang === 'ru') {
      setData(newCalls.lang.ru.ep2020)
    }
    if (lang === 'eng') {
      setData(newCalls.lang.eng.ep2020)
    }
    if (lang === 'kaz') {
      setData(newCalls.lang.kaz.ep2020)
    }
  }, [lang])
  return (
    <div className="nc-container nc-2020">
      <div className="text-body">
        <span className={'side-title left'}>2020</span>
        <span className={'side-title right'}>2020</span>
        <div className="titles">
          <h1>{data.pageTitle1}</h1>
          <h2>{data.pageTitle2}</h2>
        </div>
        <div className={'box left-text first-text'}>
          <p>{data.one}</p>
        </div>
        <div className={'box right-text second-text'}>
          <FileImg number={112} link={"https://kursiv.kz/news/otraslevye-temy/2020-04/v-kazakhstane-poschitali-polzovateley-interneta"}/>
          <p><img className="left-side-img eyeImg" src={eyeImg} alt="person avatar"/>{data.two}</p>
        </div>
        <div className={'box left-text third-text'}>
          <p>{data.three}</p>
          <FileImg number={113} link={"https://bluescreen.kz/news/v-nur-sultane-obsudili-voprosy-razvitija-cifrovizacii-v-kazahstane-s-jekspertami-mezhdunarodnogo-soveta/"}/>
        </div>
        <div className={'box right-text fourth-text'}>
          <FileImg number={114} link={"https://online.zakon.kz/Document/?doc_id=37609220#pos=6;-108"}/>
          <p>{data.four}</p>
        </div>
        <h3>{data.smallDesc1}</h3>
        <div className={'box right-text fifth-text'}>
          <FileImg number={115} link={"https://transparencyreport.google.com/user-data/overview?hl=ru&user_requests_report_period=series:requests,accounts;authority:KZ;time:&lu=user_requests_report_period"}/>
          <p>{data.five}
          </p>
        </div>
        <div className={'box left-text sixth-text'}>
          <p>{data.six}
          </p>
          <FileImg number={116} link={"https://transparencyreport.google.com/government-removals/by-country/KZ?hl=ru"}/>

        </div>
        <div className={'box right-text seventh-text'}>
          <FileImg number={117} link={"https://profit.kz/news/59092/Apple-Google-Microsoft-i-Mozilla-snova-zablokirovali-kazahstanskij-sertifikat-bezopasnosti/"}/>
          <p>{data.seven}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Nc2020;
