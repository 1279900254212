import React, {useEffect, useState} from 'react';

import eyeImg              from '../../assets/images/electronic-participation/eye icon.svg'
import {rightsToFairTrial} from '../../utils/rights-to-fair-trial';
import FileImg             from "../../components/file-img";

function Right2020({lang}) {
  const [data, setData] = useState(rightsToFairTrial.lang.ru.ep2020)

  useEffect(() => {
    window.scrollTo(0, 0);
    if (lang === 'ru') {
      setData(rightsToFairTrial.lang.ru.ep2020)
    }
    if (lang === 'eng') {
      setData(rightsToFairTrial.lang.eng.ep2020)
    }
    if (lang === 'kaz') {
      setData(rightsToFairTrial.lang.kaz.ep2020)
    }
  }, [lang])
  return (
    <div className="right-container right-2020">
      <div className="text-body">
        <span className={'side-title left first'}>2020</span>
        <span className={'side-title right second'}>2020</span>
        <span className={'side-title left third'}>2020</span>
        <span className={'side-title right fourth'}>2020</span>
        <span className={'side-title left fifth'}>2020</span>
        <h1>{data.pageTitle1}</h1>
        <div className={'box left-text first-text'}>
          <p>{data.one}</p>
          <FileImg number={98} link={"http://sud.gov.kz/rus/news/o-rezhime-raboty-sudov-respubliki-v-ramkah-chp"}/>
        </div>
        <div className={'box right-text second-text'}>
          <img className="left-side-img" src={eyeImg} alt="person avatar"/>
          <p>{data.two}</p>
        </div>
        <div className={'box left-text third-text'}>
          <p>{data.three}</p>
          <FileImg number={99} link={"http://adilet.zan.kz/rus/docs/V1800017124"}/>
        </div>
        <div className={'box right-text fourth-text'}>
          <FileImg number={100 } link={"https://www.zakon.kz/5038813-naezd-na-blokpost-v-almaty-nachalsya.html"}/>
          <p>{data.four}</p>
        </div>
        <div className={'box left-text fifth-text'}>
          <p>{data.five}
          </p>
        </div>
        <div className={'box right-text sixth-text'}>
          <p>{data.six}
          </p>
        </div>
      </div>
    </div>
  );
}

export default Right2020;
