import React, {useEffect, useState} from 'react';
import square2                      from '../../assets/images/personal-security/Group 16.svg'
import listImg                      from '../../assets/images/personal-security/list icon.svg'
import {ps}                         from '../../utils/personal-security';

function DaRecommendations({lang}) {
  const [data, setData] = useState(ps.personalSecurity.lang.ru.recommendations)

  useEffect(() => {
    window.scrollTo(0, 0);
    if (lang === 'ru') {
      setData(ps.personalSecurity.lang.ru.recommendations)
    }
    if (lang === 'eng') {
      setData(ps.personalSecurity.lang.eng.recommendations)
    }
    if (lang === 'kaz') {
      setData(ps.personalSecurity.lang.kaz.recommendations)
    }
  }, [lang])
  return (
    <div className={'da-container da-recommendations'}>
      <div className="text-body">
        <span className={'side-title left first'}>{data.sideTitleLeft}</span>
        <img className={'square2'} src={square2} alt=""/>
        <h1>{data.pageTitle1}</h1>
        <div className={'box left-text first-text'}>
          <p>
            <img className={'listImg'} src={listImg} alt=""/>
            {data.one}
          </p>
        </div>
        <div className={'box right-text second-text'}>
          <span className={'side-number'}>1</span>
          <p>{data.two}</p>
        </div>
        <div className={'box left-text third-text'}>
          <span className={'side-number'}>2</span>
          <p>{data.three}
          </p>
        </div>
        <div className={'box right-text fourth-text'}>
          <span className={'side-number'}>3</span>
          <p>{data.four}
          </p>
        </div>
        <div className={'box left-text fifth-text'}>
          <span className={'side-number'}>4</span>
          <p>{data.five}</p>
        </div>
        <div className={'box right-text sixth-text'}>
          <span className={'side-number'}>5</span>
          <p>{data.six}
          </p>
        </div>
        <div className={'box left-text seventh-text-text'}>
          <span className={'side-number'}>6</span>
          <p>{data.seven}</p>
        </div>
        <div className={'box right-text eighth-text'}>
          <span className={'side-number'}>7</span>
          <p> {data.eight}
          </p></div>
      </div>
    </div>
  );
}

export default DaRecommendations;
