import React, {useEffect, useState} from 'react';
import {dr}                         from '../../utils/digital-rights';
import bookIcon                     from '../../assets/images/digital-rights/book icon.svg'
import TopBanner                    from "../../components/top-banner";
function DRAuthors({lang}) {
    const [data, setData] = useState(dr.digitalRights.lang.ru.authors)

    useEffect(() => {
        window.scrollTo(0, 0);
        if (lang === 'ru') {
            setData(dr.digitalRights.lang.ru.authors)
        }
        if (lang === 'eng') {
            setData(dr.digitalRights.lang.eng.authors)
        }
        if (lang === 'kaz') {
            setData(dr.digitalRights.lang.kaz.authors)
        }
    }, [lang])
    return (
        <div className={'dr-container dr-authors'}>
            <div className={'text-body'}>
                <span className="side-title left">{data.sideTitleLeft}</span>
                <span className="side-title right">{data.sideTitleRight}</span>
                <h1>{data.pageTitle1}</h1>
                <h2>{data.pageTitle2}</h2>
                <div className={'box left-text first-text'}>
                    <p><img src={bookIcon} className={"right-side-img locationImg"} alt=""/>{data.firstText}</p>
                </div>
                <div className={'box right-text second-text'}>
                    <p>{data.secondText}</p>
                </div>
                <div className="table">
                    {data.table.map((row) => (
                        <div className={'table-row'}>
                            <span className={'left-col'}>{row.nameRow}</span>
                            <span className={'right-col'}>{row.description}</span>
                        </div>
                    ))}
                </div>
            </div>

        </div>
    );
}

export default DRAuthors;
