export const appData ={
  ru:{
    goBack:"Назад"
  },
  eng:{
    goBack: "Go Back"
  },
  kaz:{
    goBack: "Артқа"
  }
}
