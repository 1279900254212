import React, {useEffect, useState} from "react";
import menu1                        from "../../assets/icons/menu-page/1.svg";
import menu2                        from "../../assets/icons/menu-page/menu2.svg";
import menu3                        from "../../assets/icons/menu-page/1.svg";
import menu4                        from "../../assets/icons/menu-page/menu4.svg";
import menu5            from "../../assets/icons/menu-page/menu5.svg";
import menu6            from "../../assets/icons/menu-page/2.svg";
import leftArrow        from "../../assets/icons/menu-page/left-arrow.svg";
import rightArrow       from "../../assets/icons/menu-page/right-arrow.svg";
import {menu}           from "../../utils/menu"
import {mainPage}       from "../../utils/main-page"
import MenuTopNav       from "../../components/menu-top-nav";
import TopBanner        from "../../components/top-banner";
import ArrowRightIcon   from "@material-ui/icons/ArrowRight";
import ArrowLeftIcon    from "@material-ui/icons/ArrowLeft";
import {Link}           from "react-router-dom";
import DisclaimerButton from '../../components/disclaimer';

function AboutMenu({lang, navData, selectLang, truncateString, goToPage}) {
  const [data, setData] = useState(menu.lang.ru)


  useEffect(() => {
    window.scrollTo(0, 0);
    if (lang === "ru") {
      setData(menu.lang.ru)
    }
    if (lang === "eng") {
      setData(menu.lang.eng)
    }
    if (lang === "kaz") {
      setData(menu.lang.kaz)
    }
  }, [lang])


  return (
    <div className={"menu-page dr-menu-container"}>
      <TopBanner title={navData.about}/>
      <div className="menu-body">
        <MenuTopNav lang={lang} currentNav={"digital-rights"}/>
        <div className="menu-title">
          <Link className="right side-icons" to={"/menu/new-calls"}><ArrowLeftIcon /></Link>
          <h1 onClick={() => goToPage("/")} >digital rights matter</h1>
          <Link className="right side-icons" to={"/menu/digital-rights"}> <ArrowRightIcon /> </Link>
        </div>
        <span className="left side-button" onClick={() => goToPage("/menu/new-calls")}/>
        <span className="right side-button" onClick={() => goToPage("/menu/digital-rights")}/>
        <div className="upper-part">
          <span className="side-word">DIGITA</span>
          <div className="text-boxes">
            <div className="left-box">
              <h3 className="sub-page-title">{data.pageTitle1}</h3>
              <p>
                {truncateString(data.dr.one)}
              </p>
            </div>
            <div className="right-box">
              <p>{truncateString(data.dr.two)}</p>
            </div>
            <span className="small-desc">{data.smallDesc}</span>
          </div>

        </div>
        <div className="lower-part">

          <nav className="nav-buttons">
            <span onClick={() => {
              goToPage("/about/")
            }} className="button-one entrance">{data.digitalRightsNav.entrance}</span>
            <span onClick={() => {
              goToPage("/about/what")
            }} className="button-same what-is-it">{data.digitalRightsNav.what}</span>
            <span onClick={() => {
              goToPage("/about/authors")
            }} className="button-same authors">{data.digitalRightsNav.authors}</span>
            <span onClick={() => {
              goToPage("/about/results")
            }} className="button-same conclusion">{data.digitalRightsNav.results}</span>
          </nav>
          <DisclaimerButton/>
        </div>
      </div>
    </div>
  );
}

export default AboutMenu;
