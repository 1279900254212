import React, {useEffect} from 'react';
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import Brightness3Icon from '@material-ui/icons/Brightness3';

function ThemeButton({toggleTheme}) {
    return (
        <div onClick={()=>toggleTheme()} className="theme-button"><WbSunnyIcon className="sun"/><Brightness3Icon className="moon"/></div>
    );
}

export default ThemeButton;
