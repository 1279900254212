export const menu = {
  lang: {
    ru:  {
      pageTitle1:       "ВВЕДЕНИЕ",
      smallDesc:        "Цифровые права и свободы принадлежат вам ",
      digitalRightsNav: {
        entrance: "ВВЕДЕНИЕ",
        what:     "что это?",
        authors:  "авторы",
        results:  "выводы",
      },
      nav:              {
        entrance:        "ВВЕДЕНИЕ",
        international:   "меж. стандарты",
        national:        "нац. стандарты",
        ep2020:          "ситуация за 2020 год",
        recommendations: "рекомендации",
      },
      dr:               {
        one: "Ландшафт цифровых прав и свобод Казахстана 2020 – базовый отчет, анализирующий вызовы в теме прав человека в цифровую эпоху. Он был задуман как живой документ, который будет постоянно дорабатываться в зависимости от развития ситуации с этими правами.\n",
        two: "Данный отчет впервые составлен местными экспертами и содержит как необходимую информацию об исследуемом праве, так и описание ситуации с цифровыми правами в 2020 году. Последующие отчеты будут сконцентрированы на изменениях в течение отчетного года.",
      },
      ps:               {
        one: "С 2006 года 28 января ежегодно отмечается всемирный день приватности или защиты персональных данных . Главной задачей этой образовательной инициативы является продвижение лучших практик защиты приватности и персональных данных, в частности в интернете и социальных сетях, а также в повышении уровня осведомленности о рисках, связанных с незаконными сбором и обработкой их личных данных . Если говорить простыми словами – объяснять людям, какие личные данные собираются, обрабатываются и почему, а главное – какими правами они обладают в отношении этой обработки. Вместе с тем, помимо образовательного компонента речь также идет о разработке технологических решений и инструментов для поощрения индивидуального контроля над персональными данными, соблюдения правил и законов о приватности и конфиденциальности, а также диалога и сотрудничества между государством, бизнесом, академическим и экспертным сообществом, гражданским обществом и другими заинтересованными этими вопросами игроками.",
        two: "Защита права на неприкосновенность частной жизни (приватности) в целом и данных в частности сильно различается по всему миру. В отчете Freedom House-2018 говорится, что 15 стран рассмотрели законы о защите данных, а в 35 государствах уже приняты такие законы . В частности, отмечается, что законы о защите персональных данных, которые были предложены или приняты в Аргентине, Бразилии и Индонезии, очень напоминают европейский Общий регламент по защите данных ЕС (Регламент ЕС 2016/679) или т.н. GDPR, вступивший в силу в мае 2018 года. Вместе с тем вопросы защиты персональных данных в недемократических государствах, –в частности, в России, Китае и Казахстане, –также остро стоят на повестке дня в условиях масштабной цифровизации всех сфер деятельности.",
      },
      ep:               {
        one: "Под электронным участием понимают «процесс вовлечения граждан при помощи информационных и коммуникационных технологий (ИКТ) в политику, принятие решений, разработку и оказание услуг в целях обеспечения участия, инклюзивности и целенаправленности» . Реализация этого процесса требует как «права на доступ к сети Интернет», то есть доступности среды, в которой осуществляется электронное участие, так и «права на доступ к информации», которое подразумевает наличие в публичном доступе информации, позволяющей реализовать электронное участие. В то же время необходимо и наличие нормативно-правовой базы, которая делает электронное участие легитимным.",
        two: "Если перенести эту концепцию в сферу электронной демократии, можно выделить следующие этапы:",
      },
      rights:           {
        one: "Право на справедливое судебное разбирательство – одно из базовых прав человека. Оно подразумевает, что любое дело должно рассматриваться независимым и беспристрастным компетентным судом, и во время процесса сторонам должно быть обеспечено равенство.",
        two: "",
      },
      nc:               {
        one: "Классическая концепция прав человека рассматривает вертикальные отношения, возникающие только между человеком и государством. В то же время бизнес значительно увеличил свое влияние во многих сферах жизни и частично берет на себя государственные функции, такие как обеспечение функционирования экономики, обслуживание государственного сектора, создание продуктов и платформ, которые объединяют людей по всему миру. Именно поэтому на международном уровне по линии Организации объединенных наций (ООН) был поднят вопрос о том, что обязательства по правам человека должны распространяться и на бизнес.",
        two: "Особенность новых вызовов в сфере цифровых прав состоит в том, что в Интернете превалируют крупные международные IT-корпорации, которые формируют собственные правила для пользователей своих услуг. Власть государства в общемировой цифровой среде существенно ограничена, учитывая, что страны и их официальные представители сами являются пользователями, и на них чаще всего распространяются те же общие правила.  ",
      },
      fe:               {
        one: "Свобода выражения мнения – одна из основ свободного Интернета. Права человека в онлайн-сфере пользуются такой же защитой,\n" +
               "как и права человека в офлайн.",
        two: "Однако, несмотря на универсальный характер этого права, в разных странах мы видим разные подходы к регулированию. Сравнивая национальное законодательство и практику с международными стандартами в этой сфере, можно определить проблемные точки и возможные шаги по улучшению ситуации.\n",
      },
      limit:            {
        one: "В мире все больше стран применяют блокировки контента, чтобы предотвратить распространение незаконных материалов в Интернете. В частности, вспоминаются нижеприведенные кейсы:\n",
        two: "ограничение видеохостинга YouTube в 2008-2010 гг. в Турции по причине того, что некоторые видео оскорбляли основателя страны Кемаля Ататюрка;",
      },
      ia:               {
        one: "Первый закон о праве на доступ к информации появился в 1766 году в Швеции и почти двести лет был единственным подобным документом в мире. Он позволял национальному парламенту получать доступ к информации от короля. В 1951 году закон о доступе к информации стал действовать в Финляндии. Сегодня более сотни стран приняли нормы национального права, регулирующие процессы в этой сфере.",
        two: "Право на информацию (Right of Access to information, RTI) является одним из базовых прав человека. Оно описано в международном законодательстве как право, которое относится в свободе самовыражения, необходимое для укрепления демократии и общественного участия; как антикоррупционный инструмент, а также как важный инструмент защиты окружающей среды. Основными преимуществами реализации этого права являются улучшение качества государственного управления и инвестиционного климата, снижение коррупции, активизация гражданского общества и усиление его роли в принятии решений. \n",
      },
    },
    eng: {
      pageTitle1:       "Introduction",
      one:              "Kazakhstan's Digital Rights and Freedoms Landscape 2020 is a basic report analyzing the challenges in the topic of human rights in the digital age. It was conceived as a living document that will be continually refined depending on developments in these rights.",
      two:              "This report is the first to be compiled by local experts and contains both information for the year 2020 and information about the law as a whole. Subsequent reports will focus on the state of the law during the reporting year.",
      smallDesc:        "Digital rights and freedoms belong to you",
      digitalRightsNav: {
        entrance: "introduction",
        what:     "what are",
        authors:  "authors",
        results:  "findings",
      },
      nav:              {
        entrance:        "Introduction",
        international:   "inter. standards",
        national:        "Nat. standards",
        ep2020:          "situation of 2020",
        recommendations: "Recommendations",
      },
      dr:               {
        one: "Kazakhstan's Digital Rights and Freedoms Landscape 2020 is a basic report analyzing the challenges in the topic of human rights in the digital age. It was conceived as a living document that will be continually refined depending on developments in these rights.",
        two: "This report is the first to be compiled by local experts and contains both information for the year 2020 and information about the law as a whole. Subsequent reports will focus on the state of the law during the reporting year.",
      },
      ps:               {
        one: "Every year since 2006, January 28 is celebrated as World Privacy or Data Protection Day. The main objective of this educational initiative is to promote best practices in the protection of privacy and personal data, in particular on the Internet and social networks, as well as to raise awareness of the risks associated with the illegal collection and processing of their personal data. In simple terms - to explain to people what personal data is collected, processed and why, and most importantly - what rights they have with respect to this processing. At the same time, in addition to the educational component, it is also about developing technological solutions and tools to encourage individual control over personal data, compliance with privacy and confidentiality rules and laws, as well as dialogue and cooperation between the state, business, academic and expert community, civil society and other players interested in these issues",
        two: "Protection of the right to privacy in general and data in particular varies widely around the world. The 2018 Freedom House report states that 15 countries have considered data protection laws, and 35 states have already adopted such laws. In particular, it notes that laws on personal data protection that have been proposed or passed in Argentina, Brazil and Indonesia are very similar to the European General Data Protection Regulation of the EU (EU Regulation 2016/679) or the so-called GDPR, which came into force in May 2018. At the same time, issues of personal data protection in non-democratic countries, such as Russia, China and Kazakhstan, are also on the agenda amid the massive digitalization of all spheres of activity",
      },
      ep:               {
        one: "E-participation refers to \"the process of engaging citizens through information and communication technologies (ICTs) in policy, decision-making, design and delivery of services to ensure participation, inclusion, and purposefulness\" . Of course, the realization of this process requires both the \"right of access to the Internet\",  that is, the accessibility of the environment in which e-participation takes place, and the \"right of access to information\",  which implies the public availability of information that allows e-participation to take place. At the same time, there also needs to be a legal and regulatory framework that makes e-participation legitimate. ",
        two: "If we transfer this concept to the sphere of e-democracy, we can distinguish the following stages :",
      },
      rights:           {
        one: "The right to a fair trial – by a competent, independent and impartial court established by law, ensuring guarantees of due process and equality of sides is an integral part of human rights.",
        two: "",
      },
      nc:               {
        one: "The classical concept of human rights considers the vertical relationship arising only between the individual and the state. At the same time, business has greatly increased its influence in many areas of life and is taking on a pioneering role in making economies work, serving the public sector, and creating products and platforms that connect people around the world. That is why a discourse has been initiated at the international level through the UN on extending human rights obligations to business.",
        two: "In the field of digital rights, the new challenges are due to the fact that the Internet is dominated by large international IT-corporations that form their own rules for the users of their services. The power of the state in the global digital environment is significantly limited, given that countries and their official representatives are often the users of virtual platforms themselves, and they are subject to common rules. ",
      },
      fe:               {
        one: "Freedom of expression – is one of the foundations of a free Internet. Human rights in the online sphere are protected in the same way as human rights in the offline sphere.",
        two: "However, despite the universal nature of this right, we see different approaches to regulation in different countries. Comparing Kazakhstan legislation and practice with international standards in this area, we can identify problem points and possible steps to improve the situation.",
      },
      limit:            {
        one: "More and more countries around the world are using content blockers to prevent the distribution of illegal content to users on the Internet. In particular, the following cases are recalled:",
        two: "Limitation of YouTube video hosting in 2008-2010 in Turkey due to the fact that some videos insulted the founder of the country, Kemal Ataturk;",
      },
      ia:               {
        one: "The first law on the right to access information appeared in 1766 in Sweden and was the only such document in the world for almost 200 years. It allowed the national Parliament to access information from the King. In 1951, the law on access to information became effective in Finland. Today, more than 100 countries have adopted the norms of national law regulating the processes in this area.",
        two: "The right to information (or RTI) is one of the basic human rights. It is described in international law as a right that relates to freedom of expression, necessary to strengthen democracy and public participation; as an anti-corruption tool, and as an important tool for protecting the environment. The main advantages of exercising this right are improving the quality of public administration, improving the investment climate, reducing corruption, activating civil society and strengthening its role in decision-making. ",
      },
    },
    kaz: {
      pageTitle1:       "Кіріспе",
      smallDesc:        "Цифрлы құқықтар мен бостандықтар сізге тиесілі",
      digitalRightsNav: {
        entrance: "Кіріспе",
        what:     "Бұл не",
        authors:  "авторлар",
        results:  "қорытынды",
      },
      dr:               {
        one: "Қазақстанның цифрлы құқықтары мен бостандықтары 2020-цифрлы дәуірдегі адам құқықтары тақырыбындағы сын-тегеуріндерді талдайтын базалық есеп. Ол осы құқықтармен жағдайдың дамуына байланысты үнемі жетілдірілетін арнайы құжат ретінде ойластырылған.",
        two: "Бұл есепті бірінші рет жергілікті сарапшылар құрастырды және 2020 жылғы ақпараттардан, жалпы зерттеліп жатқан заң туралы мәліметтерден тұрады. Кейінгі есептерде жылдық есеп беру құқықтық жағдайға назар аударылады.",
      },
      ps:               {
        one: "2006 жылдан бастап 28 қаңтарда жыл сайын дүниежүзілік құпиялылық немесе дербес деректерді қорғау күні атап өтіледі . Бұл білім беру бастамасының басты міндеті құпиялылық пен дербес деректерді қорғаудың, атап айтқанда интернетте және әлеуметтік желілерде ең үздік практикаларды ілгерілету, сондай-ақ олардың жеке деректерін заңсыз жинау мен өңдеуге байланысты тәуекелдер туралы хабардар болу деңгейін арттыру болып табылады . Қарапайым сөзбен айтқанда – адамдарға қандай жеке деректер жиналатынын, өңделетінін және не үшін екенін, ең бастысы-осы өңдеуге қатысты олардың қандай құқықтары бар екенін түсіндіру керек. Сонымен бірге, білім беру компонентінен басқа, біз жеке деректерді жеке бақылауды, жекешелеп пен құпиялылық туралы ережелер мен заңдарды сақтауды, мемлекет, бизнес, академиялық және сараптамалық қоғамдастық, азаматтық қоғам және басқа да мүдделі ойыншылар арасындағы диалог пен ынтымақтастықты ынталандыратын технологиялық шешімдер мен құралдарды әзірлеу туралы айтып отырмыз.",
        two: "Жеке өмірге (жекешілдік) қол сұғылмаушылық құқығын және атап айтқанда деректерді қорғау бүкіл әлемде айтарлықтай ерекшеленеді. Freedom House-2018 есебінде 15 ел деректерді қорғау туралы заңдарды қарады, ал 35 мемлекет осындай заңдарды әлде қайда қабылдап қойған . Атап айтқанда, Аргентина, Бразилия және Индонезияда ұсынылған немесе қабылданған жеке деректерді қорғау туралы заңдар ЕО деректерін қорғаудың еуропалық жалпы регламентін (ЕО 2016/679 регламенті) немесе 2018 жылдың мамыр айында күшіне енген GDPR д.а еске түсіреді. Сонымен қатар, демократиялық емес мемлекеттерде, атап айтқанда Ресейде, Қытайда және Қазақстанда дербес деректерді қорғау мәселелері қызметтің барлық салаларын ауқымды цифрландыру жағдайында күн тәртібінде аса маңызды орында тұр.",
      },
      ep:               {
        one: "Электронды қатысу деп «азаматтарды ақпараттық және коммуникациялық технологиялар (АКТ) көмегімен саясатқа, шешімдер қабылдауға, қатысуды, инклюзивтілікті және мақсаттылықты қамтамасыз ету мақсатында қызметтерді әзірлеуге және көрсетуге тарту процесі» түсініледі . Әрине, бұл процесті жүзеге асыру «интернетке кіру құқығын», яғни электрондық қатысу жүзеге асырылатын ортаның қол жетімділігін және «ақпаратқа қол жеткізу құқығын» талап етеді, Бұл электронды қатысуды жүзеге асыруға мүмкіндік беретін ақпараттың көпшілікке қол жетімділігін білдіреді. Сонымен қатар, электрондық қатысуды заңды ететін нормативтік-құқықтық базаның болуы қажет.",
        two: "Егер біз осы тұжырымдаманы электрондық демократия саласына ауыстырсақ, келесі кезеңдерді бөліп көрсетуге болады :",
      },
      rights:           {
        one: "Әділ сот ісін жүргізу құқығы-адамның негізгі құқықтарының бірі. Бұл кез-келген істі тәуелсіз және бейтарап құзыретті сот қарауы керек және процесс барысында тараптарға теңдік қамтамасыз етілуі керек дегенді білдіреді.",
        two: "",
      },
      nc:               {
        one: "Адам құқықтарының классикалық тұжырымдамасы тек адам мен мемлекет арасында туындайтын тік қатынастарды қарастырады. Сонымен бірге, бизнес өмірдің көптеген салаларына әсерін едәуір арттырды және экономиканың жұмыс істеуін қамтамасыз ету, мемлекеттік секторға қызмет көрсету, бүкіл әлемдегі адамдарды біріктіретін өнімдер мен платформалар құру сияқты мемлекеттік функцияларды ішінара қабылдайды. Сондықтан халықаралық деңгейде Біріккен Ұлттар Ұйымы (бұдан әрі – БҰҰ) желісі бойынша адам құқықтары жөніндегі міндеттемелер бизнеске де қолданылуы тиіс деген мәселе көтерілді.",
        two: "Цифрлы құқықтар саласындағы жаңа сын қатерлер ерекшелігі Интернетте өз қызметтерін пайдаланушылар үшін өз ережелерін қалыптастыратын ірі халықаралық IT-корпорациялар басым. Әлемдік цифрлы ортадағы мемлекеттің күші айтарлықтай шектеулі, өйткені елдер мен олардың ресми өкілдері өздері пайдаланушылар болып табылады және олар көбінесе бірдей жалпы ережелерге бағынады.",
      },
      fe:               {
        one: "Пікір білдіру бостандығы – еркін Интернеттің негіздерінің бірі. Онлайн-саладағы адам құқықтары офлайндағы адам құқықтары сияқты қорғауды пайдаланады.",
        two: "Алайда, бұл құқықтың әмбебап сипатына қарамастан, әртүрлі елдерде біз реттеудің әртүрлі тәсілдерін көреміз. Ұлттық заңнама мен практиканы осы саладағы халықаралық стандарттармен салыстыра отырып, проблемалық нүктелерді және жағдайды жақсартудың ықтимал қадамдарын анықтауға болады.",
      },
      limit:            {
        one: "Әлемде заңсыз материалдардың интернетте қолданушыларға таралуын болдырмау үшін көптеген елдер мазмұнды бұғаттауды қолданады. Атап айтқанда, келесі істер еске түседі:",
        two: "Кейбір бейнелерде елдің негізін қалаушы Кемал Ататүрікке сөз тигізгендіктен, Түркияда 2008-2010 жылдары YouTube видео хостингін шектеу ;",
      },
      ia:               {
        one: "Ақпаратқа қол жеткізу құқығы туралы алғашқы заң 1766 жылы Швецияда пайда болды және 200 жылға жуық уақыт ішінде әлемдегі осындай жалғыз құжат болды. Ол ұлттық Парламентке патшадан ақпарат алуға мүмкіндік берді. 1951 жылы Ақпаратқа қол жеткізу туралы заң Финляндияда қолданыла бастады. Бүгінде 100-ден астам ел осы саладағы процестерді реттейтін ұлттық құқық нормаларын қабылдады.",
        two: "Ақпаратқа құқық (Right of Access to information, RTI) – адамның негізгі құқықтарының бірі. Ол халықаралық стандарттарда демократия мен қоғамдық қатысуды нығайту үшін қажетті өзін-өзі көрсету еркіндігін білдіретін құқық ретінде; сыбайлас жемқорлыққа қарсы, сондай-ақ қоршаған ортаны қорғаудың маңызды құралы ретінде сипатталған. Бұл құқықты іске асырудың негізгі артықшылықтары мемлекеттік басқару сапасын жақсарту, инвестициялық ахуалды жақсарту, сыбайлас жемқорлықты азайту, азаматтық қоғамды жандандыру және оның шешімдер қабылдаудағы рөлін күшейту болып табылады.",
      },
      nav:              {
        entrance:        "Кіріспе",
        international:   "Халық. стандарттар",
        national:        "Ұлттық стандарттар",
        ep2020:          "2020 жылғы жағдай",
        recommendations: "Ұсыныстар",
      },
    },
  },
}
