import React, {useEffect, useState} from 'react';

import locationImg       from '../../assets/images/right-to-fair-trial/point icon.svg';
import square1Img        from '../../assets/images/freedom-expression/squareImg1.svg'
import img2              from '../../assets/images/freedom-expression/Group 43.svg'
import {limitOnInternet} from '../../utils/limit-on-internet';
import FileImg           from "../../components/file-img";

function LimitNational({lang}) {
  const [data, setData] = useState(limitOnInternet.lang.ru.national)

  useEffect(() => {
    window.scrollTo(0, 0);
    if (lang === 'ru') {
      setData(limitOnInternet.lang.ru.national)
    }
    if (lang === 'eng') {
      setData(limitOnInternet.lang.eng.national)
    }
    if (lang === 'kaz') {
      setData(limitOnInternet.lang.kaz.national)
    }
  }, [lang])
  return (
    <div className="limit-container limit-national">
      <div className="text-body">
        <span className={'side-title left'}>{data.sideTitleLeft}</span>
        <span className={'side-title right'}>{data.sideTitleRight}</span>
        <div className="titles">
          <h1>{data.pageTitle1}</h1>
          <h2>{data.pageTitle2}</h2>
        </div>
        <div className={'box left-text first-text'}>
          <div className="table">
            <p><img className="right-side-img locationImg" src={locationImg} alt="person avatar"/>{data.one}
            </p>
            <div className="table-body">
              {data.table.map((text)=>(
                  <span className={'table-row'}>{text.text}</span>
                  )
              )}
            </div>

          </div>
          <FileImg number={48} link={"http://www.akorda.kz/ru/official_documents/constitution"}/>
        </div>
        <div className={'box right-text second-text'}>
          <FileImg number={49} link={"http://adilet.zan.kz/rus/docs/V1800016782"}/>
          <p>{data.two}</p>
        </div>
        <div className={'box left-text third-text'}>
          <p>{data.three}</p>
          <FileImg number={50} link={"http://adilet.zan.kz/rus/docs/V1800016781"}/>
        </div>
        <div className={'box right-text fourth-text'}>
          <FileImg number={51} link={"http://adilet.zan.kz/rus/docs/V1800016654"}/>
          <p>{data.four}</p>
        </div>
        <div className={'box left-text fifth-text'}>
          <p>{data.five}
          </p>
          <FileImg number={52} link={"http://adilet.zan.kz/rus/docs/Z1500000401"}/>
        </div>
        <div className={'box right-text text6'}>
          <p>{data.six}
          </p>
          <FileImg number={53} link={"http://adilet.zan.kz/rus/docs/P1800000679"}/>
        </div>
        <div className={'box left-text text7'}>
          <p>{data.seven}
          </p>
          <img src={square1Img}  className='square1Img' alt=""/>
        </div>
        <div className={'box right-text text8'}>
          <p>{data.eight}
          </p>
        </div>
        <img src={img2}  className='img2' alt=""/>
      </div>
    </div>
  );
}

export default LimitNational;
