export const mainPage = {
  lang: {
    ru:  {
      smallDesc:               "Ландшафт цифровых прав и свобод Казахстана 2020",
      digitalRights:           "Цифровая приватность",
      about:                   "Об отчете",
      electronicParticipation: "Электронное участие",
      rightsToFairTrial:       "Судебное разбирательство",
      newCalls:                "Новые вызовы",
      limitOnInternet:         "Ограничение интернет контента",
      freedomExpression:       "Свобода выражения",
      infoAccess:              "Доступ к информации",
      langFile:                "Скачать документ в PDF",
    },
    eng: {
      smallDesc:               "Landscape of digital rights and freedoms of Kazakhstan 2020",
      digitalRights:           "Digital privacy",
      about:                   "About the report",
      electronicParticipation: "Electronic participation",
      rightsToFairTrial:       "Rights to fair trial",
      newCalls:                "New calls",
      freedomExpression:       "Freedom of expression online",
      infoAccess:              "Access to the information",
      limitOnInternet:         "Restriction of internet content",
      langFile:                "Download document in PDF",
    },
    kaz: {
      smallDesc:               "Қазақстанның цифрлық құқықтары мен бостандықтарының ландшафты 2020",
      digitalRights:           "Цифрлы құпиялылық",
      about:                   "Есеп туралы",
      electronicParticipation: "Электронды қатысу",
      rightsToFairTrial:       "Соттық сынақ",
      newCalls:                "Жаңа міндеттер",
      freedomExpression:       "Интернетте сөз бостандығы",
      infoAccess:              "Ақпаратқа қол жеткізу",
      limitOnInternet:         "Интернет мазмұнын шектеу",
      langFile:                "PDF құжатты жүктеу",
    },
  },
}
