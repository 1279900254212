import React, {useEffect, useState} from 'react';
import groupPeopleImg               from '../../assets/images/freedom-expression/peopleGroup.svg';
import locationImg                  from '../../assets/images/electronic-participation/point icon.svg';
import {ep}                         from '../../utils/electronic-participation';

function EpNational({lang}) {
  const [data, setData] = useState(ep.electronicParticipation.lang.ru.national)

  useEffect(() => {
    window.scrollTo(0, 0);
    if (lang === 'ru') {
      setData(ep.electronicParticipation.lang.ru.national)
    }
    if (lang === 'eng') {
      setData(ep.electronicParticipation.lang.eng.national)
    }
    if (lang === 'kaz') {
      setData(ep.electronicParticipation.lang.kaz.national)
    }
  }, [lang])
  return (
    <div className="ep-container ep-national">
      <div className="text-body">
        <span className={'side-title left'}>{data.sideTitleLeft}</span>
        <span className={'side-title right'}>{data.sideTitleRight}</span>
        <div className="titles">
          <h1>{data.pageTitle1}</h1>
          <h2>{data.pageTitle2}</h2>
        </div>

        <div className={'box left-text first-text'}>
          <p>{data.one}</p>
        </div>
        <div className={'box right-text second-text'}>
          <p>{data.two}</p>
        </div>
        <div className={'box left-text third-text'}>
          <p>{data.three}
          </p>
          <img className="locationImg"  src={locationImg} alt="location point"/>
        </div>
      </div>
    </div>
  );
}

export default EpNational;
