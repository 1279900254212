import React, {useEffect, useState} from 'react';
import personImg                    from '../../assets/images/electronic-participation/person icon.svg';
import groupPeopleImg               from '../../assets/images/right-to-fair-trial/inetrnational standarts icon.svg';

import img1                from '../../assets/images/right-to-fair-trial/Group 39.svg'
import {rightsToFairTrial} from '../../utils/rights-to-fair-trial';
import FileImg             from "../../components/file-img";

function RightEntrance({lang}) {
  const [data, setData] = useState(rightsToFairTrial.lang.ru.entrance)

  useEffect(() => {
    window.scrollTo(0, 0);
    if (lang === 'ru') {
      setData(rightsToFairTrial.lang.ru.entrance)
    }
    if (lang === 'eng') {
      setData(rightsToFairTrial.lang.eng.entrance)
    }
    if (lang === 'kaz') {
      setData(rightsToFairTrial.lang.kaz.entrance)
    }
  }, [lang])
  return (
    <div className="right-container right-entrance">
      <div className="text-body">
        <span className={'side-title left'}>{data.sideTitleLeft}</span>
        <span className={'side-title right'}>{data.sideTitleRight}</span>
        <h1>{data.pageTitle1}</h1>
        <div className={'box left-text first-text'}>
          <p><img className="right-side-img" src={personImg} alt="person avatar"/>{data.one}</p>
        </div>
      </div>
    </div>
  );
}

export default RightEntrance;
