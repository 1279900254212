import React              from "react";
import {Link, useHistory} from "react-router-dom";

function TopBanner({rightLink, leftLink, title}) {
  const history = useHistory()
  const goToPage = (path) => {
    history.push(path)
  }
  return (
      <div className={"banner"}>
        <div className="title">
          {title}
        </div>
        <Link to={'/'} className="go-to-main-page">
          DIGITAL RIGHTS MATTER
        </Link>
      </div>

  );
}

export default TopBanner;
