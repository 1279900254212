import React, {useEffect, useState} from 'react';
import arrowRight                   from '../../assets/icons/arrow-right.svg';
import {Link}                       from 'react-router-dom';
import {mainPage}                   from '../../utils/main-page'
import iaRectangle                  from '../../assets/images/home-page/links-boxes/ia-rectangle.svg'
import drRectangle                  from '../../assets/images/home-page/links-boxes/dr-rectangle.svg'
import psRectangle                  from '../../assets/images/home-page/links-boxes/ps-rectangle.svg'
import ncRectangle                  from '../../assets/images/home-page/links-boxes/nc-rectangle.svg'
import limitRectangle               from '../../assets/images/home-page/links-boxes/limit-rectangle.svg'
import feRectangle                  from '../../assets/images/home-page/links-boxes/fe-rectangle.svg'
import epRectangle                  from '../../assets/images/home-page/links-boxes/ep-rectangle.svg'
import fileEmpty                    from '../../assets/images/electronic-participation/papka.svg'
import InsertDriveFileIcon          from '@material-ui/icons/InsertDriveFile';

function HomePage({lang}) {
    const [data, setData] = useState(mainPage.lang.ru)

    useEffect(() => {
        if (lang === 'ru') {
            setData(mainPage.lang.ru)
        }
        if (lang === 'eng') {
            setData(mainPage.lang.eng)
        }
        if (lang === 'kaz') {
            setData(mainPage.lang.kaz)
        }
    }, [lang])

    return (
        <div className={'container main-page'}>
            <div className="main-page__title">
                <h1>Digital Rights Matter</h1>
                <p>Digital Rights Matter</p>
                <span className="small-desc"><img src={arrowRight} alt="arrow right"/>{data.smallDesc}</span>
                <div className="main-page__links">
                    <Link to="/menu/about" className="link personal-security">
                        <span>{data.about}<img src={psRectangle} className="rectangle right" alt=""/></span>
                    </Link>
                    <Link to="/menu/digital-rights" className="link digital-privacy">
                        <span>{data.digitalRights}<img src={drRectangle} className="rectangle right" alt=""/></span>
                    </Link>
                    <Link to="/menu/freedom-expression" className="link freedom-expression">
                        <span><img src={limitRectangle} className="rectangle left" alt=""/>{data.freedomExpression}</span>
                    </Link>
                    <Link to="/menu/limit-on-internet" className="link limitation-on-internet-content">
                        <span><img src={epRectangle} className="rectangle left" alt=""/>{data.limitOnInternet}</span>
                    </Link>
                    <Link to="/menu/info-access" className="link info-access">
                        <span>{data.infoAccess}<img src={iaRectangle} className="rectangle right" alt=""/></span>
                    </Link>
                    <Link to="/menu/electronic-participation" className="link electronic-participation">
                        <span><img src={epRectangle} className="rectangle left" alt=""/>{data.electronicParticipation}</span>
                    </Link>
                    <Link to="/menu/rights-to-fair-trial" className="link rights-to-fair-trial">
                        <span><img src={limitRectangle} className="rectangle right" alt=""/>{data.rightsToFairTrial}          </span>
                    </Link>
                    <Link to="/menu/new-calls" className="link new-calls">
                        <span><img src={ncRectangle} className="rectangle left" alt=""/>{data.newCalls}</span>
                    </Link>
                </div>
            </div>
            <Link to="/resources" className={'resources'}><img className={'fileEmpty'} src={fileEmpty} alt=""/></Link>
            <a href={`/files/${lang}-drm.pdf`} className={'lang-file'}>{data.langFile}</a>

        </div>
    );
}

export default HomePage;
