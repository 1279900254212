import React        from 'react';
import {Link}       from 'react-router-dom';
import fontSorosImg from '../assets/images/disclaimer/fond.png'
import drflImg      from '../assets/images/disclaimer/DRFL.png'

function DisclaimerButton() {
    return (
        <div className={'disclaimer__links'}>
            <Link to={'/disclaimer'} className={'disclaimer__button'}>
                Disclaimer
            </Link>
            <a className="ext-img-link" href="https://www.soros.kz">
                <img src={fontSorosImg}  alt=""/>
            </a>
            <a className="ext-img-link" href="https://drfl.kz">
                <img src={drflImg} alt=""/>
            </a>
        </div>
    )
}

export default DisclaimerButton;
