import React, {useEffect, useState} from 'react';
import personImg                    from '../../assets/images/electronic-participation/person icon.svg';
import img2                         from '../../assets/images/freedom-expression/Group 40.svg';
import {fe}          from '../../utils/freedom-expression';

function FeEntrance({lang}) {
    const [data, setData] = useState(fe.freedomExpression.lang.ru.entrance)

    useEffect(() => {
        window.scrollTo(0, 0);
        if (lang === 'ru') {
            setData(fe.freedomExpression.lang.ru.entrance)
        }
        if (lang === 'eng') {
            setData(fe.freedomExpression.lang.eng.entrance)
        }
        if (lang === 'kaz') {
            setData(fe.freedomExpression.lang.kaz.entrance)
        }
    }, [lang])
    return (
        <div className="fe-container fe-entrance">
            <div className="text-body">
                <span className={'side-title left'}>{data.sideTitleLeft}</span>
                <span className={'side-title right'}>{data.sideTitleRight}</span>
                <h1>{data.pageTitle1}</h1>
                <div className={'box left-text first-text'}>
                    <p>{data.one}</p>
                </div>
                <img className="right-side-img" src={img2} alt=""/>
                <div className={'box right-text second-text'}>

                    <p><img className="left-side-img" src={personImg} alt=""/>{data.two}
                    </p>
                </div>
            </div>
        </div>
    );
}

export default FeEntrance;
