import React, {useEffect, useState} from "react";
import eyeImg                       from "../../assets/images/electronic-participation/eye icon.svg";
import {ep}                         from "../../utils/electronic-participation"
import FileImg                      from "../../components/file-img";

function Ep2020({lang}) {
  const [data, setData] = useState(ep.electronicParticipation.lang.ru.ep2020)

  useEffect(() => {
    window.scrollTo(0, 0);
    if (lang === "ru") {
      setData(ep.electronicParticipation.lang.ru.ep2020)
    }
    if (lang === "eng") {
      setData(ep.electronicParticipation.lang.eng.ep2020)
    }
    if (lang === "kaz") {
      setData(ep.electronicParticipation.lang.kaz.ep2020)
    }
  }, [lang])
  return (
    <div className="ep-container ep-2020">

      <div className="text-body">
        <span className={"side-title left first"}>2020</span>
        <span className={"side-title right second"}>2020</span>
        <span className={"side-title left third"}>2020</span>
        <span className={"side-title right fourth"}>2020</span>
        <span className={"side-title left fifth"}>2020</span>
        <span className={"side-title right sixth"}>2020</span>
        <span className={"side-title left seventh"}>2020</span>
        <span className={"side-title right eighth"}>2020</span>
        <span className={"side-title left ninth"}>2020</span>
        <span className={"side-title right tenth"}>2020</span>
        <div className="titles">
          <h1>{data.pageTitle1}</h1>
          <h2>{data.pageTitle2}</h2>
        </div>
        <div className={"box left-text first-text"}>
          <p><img className="right-side-img" src={eyeImg} alt="person avatar"/>
            {data.one}</p>
          <FileImg number={79}
                   link={"blicadministration.un.org/egovkb/Portals/egovkb/Documents/un/2020-Survey/2020%20UN%20E-Government%20Survey%20-%20Russian.pdf"}/>
        </div>

        <div className={"box right-text "}>
          <p>
            {data.two}</p>

        </div>
        <div className={"box left-text "}>
          <p>
            {data.three}</p>
          <FileImg number={80} link={"https://egov.kz/cms/ru"}/>
        </div>
        <div className={"box right-text "}>
          <FileImg number={81} link={"https://articlekz.com/article/13436"}/>
          <p>
            {data.four}</p>

        </div>

        <div className={"box left-text "}>
          <p>
            {data.five}</p>
          <FileImg number={82} link={"https://open.egov.kz"}/>

        </div>
        <div className={"box right-text "}>
          <FileImg number={83} link={"https://data.egov.kz/#3"}/>

          <p>
            {data.six}</p>

        </div>
        <div className={"box left-text "}>
          <p>
            {data.seven}</p>
          <FileImg number={84} link={"https://legalacts.egov.kz"}/>

        </div>
        <div className={"box right-text "}>
          <FileImg number={85} link={"https://dialog.egov.kz"}/>

          <p>
            {data.eight}</p>

        </div>
        <div className={"box left-text "}>
          <p>
            {data.nine}</p>
          <FileImg number={86} link={"https://budget.egov.kz"}/>

        </div>
        <div className={"box right-text "}>
          <FileImg number={87} link={"https://goszakup.gov.kz/ru/"}/>

          <p>
            {data.ten}</p>

        </div>
        <div className={"box left-text "}>
          <p>
            {data.eleven}</p>
          <FileImg number={88} link={"https://www.nitec.kz/index.php/post/portal-elektronnogo-pravitelstva"}/>

        </div>
        <div className={"box right-text "}>
          <FileImg number={89} link={"https://forbes.kz/news/2020/04/04/newsid_222566"}/>

          <p>
            {data.twelve}</p>

        </div>
        <div className={"box left-text "}>
          <p>
            {data.thirteen}</p>
          <FileImg number={90} link={"https://egov.kz/cms/ru/online-services/for_citizen/vyplata_42500"}/>

        </div>
        <div className={"box right-text "}>
          <FileImg number={91} link={"https://evaluation.egov.kz"}/>

          <p>
            {data.fourteen}</p>

        </div>
        <div className={"box left-text "}>
          <p>
            {data.fifteen}</p>
          <FileImg number={92}
                   link={"https://www.gov.kz/memleket/entities/mdai/press/news/details/132113?lang=ru&fbclid=IwAR3pMxA-pKP87HQAmpW4yJPGjybmEVAPlRANrJSpUD3_fw5LztwKU-QCbwc"}/>

        </div>
      </div>
    </div>
  );
}

export default Ep2020;
